import * as React from 'react'
import {SpriteIcon,ModalProps,StickyModal,Button} from 'components'
import styles from './styles.module.scss'


const GuideToApp: React.FC<ModalProps> = ({onCancel,...props}) => {

  return (
    <StickyModal {...props} onCancel={onCancel}>
      <div className={styles['guide-to-app']}>
        <div className={styles['to-app-logo']}>
          <SpriteIcon size={36} ariaLabel="打开应用" backgroundSize="510px 477px" backgroundPosition="-379px -320px" />
        </div>
        <h4 className={styles['to-app-title']}>使用应用，收发消息更方便</h4>
        <div className={styles['to-app-text']}>切换为应用，享受更好的照片、即阅消息等发送体验。</div>
        <Button type="PRIMARY" style={{marginBottom:12}}>使用此应用</Button>
        <Button onClick={onCancel} type="TEXT" style={{marginBottom:12,color:'#0095f6'}}>以后再说</Button>
      </div>
    </StickyModal>
  );
}

export default GuideToApp;