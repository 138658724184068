import React, { useState, useEffect, useRef, createContext, useContext,useMemo,useCallback } from 'react'
import styles from './styles.module.scss';
import {SpriteIcon,SpriteIconProps,Button} from 'components'
import { useSwipeable } from "react-swipeable";
import  useViewport from 'hooks/useViewport'


const CarouselContext = createContext(null);


export const CarouselItem: React.FC = ({ children }) => {
  const { carouselElWidth, imgSzieRatio, itemEl, setCarouselItemNum,carouselType } = useContext(CarouselContext);
  const style = { width: carouselElWidth, height: carouselElWidth * imgSzieRatio };

  useEffect(() => {
    setCarouselItemNum(i => i + 1);
    return () => {
      setCarouselItemNum(i => i - 1);
    }
  }, [])

  return (
    <li className={styles['carousel-item']} ref={itemEl} style={carouselType == 'IMG' ? style : {}}>
      {children}
    </li>
  )
}



interface  CarouselProps {
  nestNum?: number;
  duration?: number;
  hasPagination?: boolean;
  pageChange?: (e: number, t: number) => void;
  navigationType?: 'DEFAULT' | 'GRAY';
  hasNavigation?: boolean;
  imgWidth?: number;
  imgHeight?: number;
  carouselType?: 'IMG' | 'ITEM';
  navigationStep?: number;
  style?: React.CSSProperties;
  children?: React.ReactNode
}


const Carousel: React.FC<CarouselProps> = ({ hasNavigation = true, children, style, navigationStep = 1, carouselType = 'IMG', imgWidth = 0, imgHeight = 0, nestNum = 20, hasPagination = true, duration = 230, navigationType = 'DEFAULT', pageChange = () => { } }) => {

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [carouselElWidth, setCarouselElWidth] = useState<number>(0);
  const [carouselItemNum, setCarouselItemNum] = useState<number>(0);
  const [maxPage, setMaxPage] = useState<number>(0);
  const {viewportWidth} = useViewport();


  const carouselEl = useRef<HTMLUListElement>(null);
  const translateX = useRef<number>(0);
  const maxTransitionX = useRef<number>(0);

  const isImg = carouselType == 'IMG';
  const imgSzieRatio = imgHeight / imgWidth;

  const navigationProps = {
    backgroundSize: '440px 411px',
    type :'880'
  }

  const navigationLeftMap = useMemo(() => {
    return {
      'DEFAULT' : <SpriteIcon backgroundPosition="-129px -97px" size={30} ariaLabel="向左" {...navigationProps as SpriteIconProps} />,
      'GRAY' : <SpriteIcon  backgroundPosition="-402px -207px" size={24} ariaLabel="向左" {...navigationProps as SpriteIconProps} />,
    }
  },[])

  const navigationRightMap = useMemo(() => {
    return {
      'DEFAULT' : <SpriteIcon backgroundPosition="-160px -97px" style={{ transform: `scale(1)` }} size={30} ariaLabel="向右" {...navigationProps as SpriteIconProps} />,
      'GRAY' : <SpriteIcon backgroundPosition="-402px -207px" style={{ transform: `scale(-1)` }} size={24} ariaLabel="向右" {...navigationProps as SpriteIconProps} />
    }
  },[])


  const setElTranslateX = useCallback((x: number, hasTransition: boolean = false) => {
    if (x <= 0) x = 0;
    if (x >= maxTransitionX.current) x = maxTransitionX.current;
    if (carouselEl.current) {
      carouselEl.current.style.transition = hasTransition ? `transform ${duration}ms cubic-bezier(0.215,0.61,0.355,1) 0s` : '';
      carouselEl.current.style.transform = `translateX(-${x}px)`;
    }
    translateX.current = x;
  },[maxTransitionX,translateX])

  useEffect(() => {
    requestAnimationFrame(() => {
      const carouselElWidth = carouselEl?.current?.getBoundingClientRect().width
      const width = isImg ? carouselElWidth : carouselEl?.current?.firstElementChild?.getBoundingClientRect().width;
      maxTransitionX.current = Math.max(carouselItemNum * width - carouselElWidth, 0)

      setElTranslateX(width * currentPage);
      setCarouselElWidth(width);
      setMaxPage(Math.ceil(maxTransitionX.current / width));

    })
  }, [viewportWidth,carouselItemNum])

  useEffect(() => {
    onTransitionEnd(null);
  }, [maxPage]);


  let x = 0;
  const handlers = useSwipeable({
    onSwipeStart() {
      x = translateX.current;
    },
    onSwiping({ deltaX }) {
      setElTranslateX(x - deltaX, false);
    },
    onSwiped({ absX, deltaX }) {
      if (absX > nestNum) {
        setElTranslateX((deltaX > 0 ? Math.floor : Math.ceil)(translateX.current / carouselElWidth) * carouselElWidth, true);
      } else {
        setElTranslateX(x, true);
      }
      onTransitionEnd(null);
    }
  })

  const slideTo = useCallback((page: number) => {

    if (page < 0) page = 0;
    if (page > maxPage) page = maxPage;
    let x = page * carouselElWidth;
    setElTranslateX(x, true);
  },[maxPage,carouselElWidth,maxTransitionX])

  const onTransitionEnd = useCallback((e) => {
    e && e.stopPropagation();
    const page = Math.ceil(translateX.current / carouselElWidth) || 0;
    setCurrentPage(page);
    pageChange(page, maxPage);
  },[maxPage,carouselElWidth,translateX])

  return (
    <CarouselContext.Provider value={{ carouselElWidth, imgSzieRatio, setCarouselItemNum,carouselType }}>
      <div {...handlers} className={styles['carousel']} style={{paddingTop : isImg ?( !carouselElWidth ? `${imgSzieRatio * 100}%` : 0 ): 0,...style}} >
        <ul ref={carouselEl} onTransitionEnd={onTransitionEnd} className={styles['carousel-content']}>{children}</ul>
        {currentPage != 0 && hasNavigation && <Button type="TEXT" className={styles['carousel-btn-left']} onClick={() => { slideTo(currentPage - navigationStep) }}>{navigationLeftMap[navigationType]}</Button>}
        {currentPage != maxPage && hasNavigation && <Button type="TEXT" className={styles['carousel-btn-right']} onClick={() =>{ slideTo(currentPage + navigationStep) }}>{navigationRightMap[navigationType]}</Button>}
      </div>
      { hasPagination && carouselItemNum > 1 &&
        <div className={styles['carousel-dots']}>
          {[...new Array(carouselItemNum)].map((v,i) => {
            return <div className={`${styles['carousel-dot']} ${i == currentPage ? styles['carousel-dot-active'] : ''}`} />
          })}
        </div>}
    </CarouselContext.Provider>
  )
}

export default React.memo(Carousel);

