import React,{useState,useEffect,useRef,useImperativeHandle, useCallback} from 'react'
import {Tabs, TabPanel,ImageData,NavBar,Button, } from 'components'
import CreationCropping,{CroppedImage} from './CreationCropping'
import CreationFiltering from './CreationFiltering'
import {PhotoContextProvider} from './PhotoContext'
import getImageCanvas from './getImageCanvas'
import {getDefaultCrop} from './CreationCropping'

import styles from './styles.module.scss'


interface PhotoCreateProps  {
  isForAvatar?: boolean;
  title?: string;
  onNextClick?: (blob?:any,filterName?:string,croppedImage?:CroppedImage,imageData?:ImageData) => void;
  onCloseClick?: () => void;
  nextText?: string;
  imageData?: ImageData;
  filterName?: string;
  croppedImage?: CroppedImage
}

const PhotoCreate = ({isForAvatar,title,filterName="normal",onNextClick=() =>{},onCloseClick=() => {},nextText,imageData,croppedImage}: PhotoCreateProps, ref: React.RefObject<any>) => {


  const [canvasImage,setCanvasImage] = useState<HTMLCanvasElement>(null);
  const [isActivatedFiltering,setIsActivatedFiltering] = useState<boolean>(false);

  const [currentFilterName,setCurrentFilterName] = useState<string>(filterName);
  
  const croppingRef = useRef(null);
  const filterRef = useRef(null);

  
  const {image,width,height,dataURL} = imageData || {};

  useEffect(() => {//初始化 防止没有点击滤镜直接到下一步
    if(image) {
      setCanvasImage(getImageCanvas({
        sourceImg : image,
        ...getDefaultCrop(width,height),
        imageWidth:width,
        imageHeight:height,
        rotationAngle:0,
      }))
    }

  },[])

  useImperativeHandle(ref, () => {
    return {
      getBlob() {
        return filterRef.current.getBlob();
      }
    }
  })


  const setCroppedImageCanvas = useCallback(() => {
    const croppedImage = croppingRef.current.state.croppedImage;

    setCanvasImage(getImageCanvas({
      sourceImg : image,
      ...croppedImage,
      imageHeight: height,
      imageWidth:width,
      rotationAngle : croppedImage.rotationAngle
    }))

  },[setCanvasImage,croppingRef,image])


  const onTabBarClick = useCallback((isActive) => {
    setIsActivatedFiltering(true);
    !isActive && setCroppedImageCanvas();

  },[setCroppedImageCanvas])

  const _onNextClick = useCallback(() => {
    !isActivatedFiltering && setCroppedImageCanvas();
    setTimeout(() => {
      filterRef.current.getBlob().then(blob => onNextClick(blob,currentFilterName,croppingRef.current.state.croppedImage,imageData))
    }) 
  },[isActivatedFiltering,currentFilterName])

  return (
    <>
      <NavBar fixedType="TOP">
        <NavBar.Navigation type="THINCLOSE" onClick={onCloseClick} />
        <NavBar.Title>{title}</NavBar.Title>
        <NavBar.Navigation >
          <Button type="TEXT" onClick={_onNextClick} style={{ color: '#0095f6' }}>{nextText}</Button>
        </NavBar.Navigation>
      </NavBar>
      <PhotoContextProvider canvasImage={canvasImage} >
        <div className={styles['photo-create']}>
          {isForAvatar && <div className={styles['photo-create-mask']} />}
          <Tabs hasActiveStyle={true} borderType="TOP" hideStyleType="DISPLAY" defaultActiveKey="edit" >
            <TabPanel tab="滤镜" className={styles['photo-create-tab']} keys="filter" onTabBarClick={onTabBarClick} tabBarClassName={styles['photo-create-btn']}>
              <CreationFiltering onFilterChange={(filterName) => { setCurrentFilterName(filterName) }} filterName={filterName} ref={filterRef} viewportWidth={window.innerWidth} pixelRatio={window.devicePixelRatio} filterParams={{filterStrength :1}} />
            </TabPanel>
            <TabPanel tab="编辑" className={styles['photo-create-tab']} keys="edit" tabBarClassName={styles['photo-create-btn']}>
              <CreationCropping croppedImage={croppedImage} ref={croppingRef} imageWidth={width} imageHeight={height} imageDataURL={dataURL} />
            </TabPanel>
          </Tabs>
        </div>
      </PhotoContextProvider>
    </>
  )
}


export default React.forwardRef(PhotoCreate);