import React,{useEffect} from 'react'
import {NavBar,loading} from 'components';
import styles from './styles.module.scss'


interface SharingProps {
  text?: string;
  visible?: boolean;
}

const Sharing:React.FC<SharingProps> = ({text,visible}) => {

  useEffect(() => {
    visible ? loading.start({height:3}) : loading.done();
    return () => {
      loading.done();
    };
  },[visible])

  return (
    <>
      {visible && <div className={styles['sharing']}>
        <NavBar fixedType="TOP">
          <NavBar.Title>{text}</NavBar.Title>
        </NavBar>
      </div>}
    </>
  )
}

export default Sharing;