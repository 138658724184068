import shaderMap from './shaderMap'


let u, c, F = null, T = new Map;
const $ = [1, -1, 0, 1, 1, 0, -1, 1, 0, 1, -1, 0, -1, -1, 0, -1, 1, 0];

function t(t) {
	if (t !== F) {
		F = t;
		T = new Map;
		u = null;
		c = null;
	}
}
function o(o) {
	t(o)
	if (null === u) {
		const t = o.createBuffer();
		o.bindBuffer(o.ARRAY_BUFFER, t);
		o.bufferData(o.ARRAY_BUFFER, new Float32Array($), o.STATIC_DRAW);
		u = t;
	}
	return u
}
function s(t, o, s) {
	t.shaderSource(o, s);
	t.compileShader(o);
	if (!t.getShaderParameter(o, t.COMPILE_STATUS)) {
		const s = t.getShaderInfoLog(o) || 'no logs';
		console.log(`[filters] Could not compile shader: ${s}`)
	}
}
function n(o) {
	return t(o),
		null === c && s(o, c = o.createShader(o.VERTEX_SHADER), shaderMap['d1']),
		c
}
function l(t, o, s) {
	const n = t.createTexture();
	try{
		return t.activeTexture(t.TEXTURE0 + s),
		t.bindTexture(t.TEXTURE_2D, n),
		t.texImage2D(t.TEXTURE_2D, 0, t.RGBA, t.RGBA, t.UNSIGNED_BYTE, o),
		t.texParameteri(t.TEXTURE_2D, t.TEXTURE_MIN_FILTER, t.LINEAR),
		t.texParameteri(t.TEXTURE_2D, t.TEXTURE_WRAP_S, t.CLAMP_TO_EDGE),
		t.texParameteri(t.TEXTURE_2D, t.TEXTURE_WRAP_T, t.CLAMP_TO_EDGE),
		n
	} catch (e) {
		console.log(e);
	}
}
function P(o, s, n) {
	return t(o),
		new Promise((t, P) => {
			const h = T.get(s);
			if (null != h) {
				const [s, l] = h;
				o.activeTexture(o.TEXTURE0 + n);
				o.bindTexture(o.TEXTURE_2D, s);
				t(h);
			} else if ('string' == typeof s) {
				const h = s.slice(0, 50), u = new Image;
				u.onload = () => {
					const P = [l(o, u, n), u];
					T.set(s, P);
					t(P);
				}
				u.onerror = t => {
					P(new Error(`Cannot load texture ${h}`))
				}
				u.src = s
			} else {
				const P = [l(o, s, n), s];
				T.set(s, P);
				t(P);
			}
		}
		)
}
function h(t) {
	return shaderMap[1] + t + shaderMap[2]
}


export default class FilterProgram {
	$FilterProgram1: boolean;
	$FilterProgram2: any[];
	$FilterProgram3: boolean;
	$FilterProgram4: any;
	$FilterProgram5: any;
	$FilterProgram6: any;
	$FilterProgram7: any;
	$FilterProgram8: any;
	$FilterProgram9: Promise<unknown>;

	constructor(t, o, s, n) {
		this.$FilterProgram1 = !1;
		this.$FilterProgram2 = [];
		this.$FilterProgram3 = !1;
		this.$FilterProgram4 = t;
		this.$FilterProgram5 = o;
		this.$FilterProgram6 = s;
		this.$FilterProgram2 = n;
	}
	getFragmentSource() {
		return this.$FilterProgram6
	}
	getTextures() {
		return this.$FilterProgram2
	}
	getName() {
		return this.$FilterProgram5
	}
	init() {
		if (this.$FilterProgram1)
			return;
		this.$FilterProgram1 = !0;
		const t = this.$FilterProgram4;
		this.$FilterProgram7 = t.createProgram();

		const l = n(t);
		t.attachShader(this.$FilterProgram7, l);
		const P = t.createShader(t.FRAGMENT_SHADER);
		if (s(t, P, h(this.getFragmentSource())),
			t.attachShader(this.$FilterProgram7, P),
			t.linkProgram(this.$FilterProgram7),
			!t.getProgramParameter(this.$FilterProgram7, t.LINK_STATUS)) {
			const o = t.getProgramInfoLog(this.$FilterProgram7) || 'no logs';
			console.log(`[filters] program failed to link: ${o}`)
		}
		o(t);
		const u = t.getAttribLocation(this.$FilterProgram7, 'position');
		t.vertexAttribPointer(u, 3, t.FLOAT, !1, 0, 0);
		t.enableVertexAttribArray(u);
		this.loadAssets();
	}
	loadAssets() {
		const t = this.$FilterProgram4
			, o = this.getTextures().map(([o, s], n) => {
				const l = n + 1;
				return P(t, s, l).then(() => {
					if (this.$FilterProgram3) {
						const s = t.getUniformLocation(this.$FilterProgram7, o);
						t.uniform1i(s, l)
					}
				}
				)
			}
			);
		return this.$FilterProgram8 = Promise.all(o),
			this.$FilterProgram8
	}
	setImage(t) {
		const o = this.$FilterProgram4;
		return this.$FilterProgram9 = P(o, t, 0).then(t => {
			if (this.$FilterProgram3) {
				const t = this.$FilterProgram4.getUniformLocation(this.$FilterProgram7, 'image');
				this.$FilterProgram4.uniform1i(t, 0)
			}
			return t
		}
		),
			this.$FilterProgram9
	}
	setParam(t, o) {
		const s = this.$FilterProgram4.getUniformLocation(this.$FilterProgram7, t);
		s || console.log(`unknown uniform ${t}`);
		this.$FilterProgram4.uniform1f(s, o);
	}
	waitForAssetLoad() {
		return Promise.all([this.$FilterProgram9, this.$FilterProgram8])
	}
	use() {
		this.init();
		this.$FilterProgram4.useProgram(this.$FilterProgram7);
		this.setParam('filterStrength', 1);
		this.setParam('TOOL_ON_EPSILON', 0.009);
		this.$FilterProgram3 = !0;
	}
	unuse() {
		this.$FilterProgram3 = !1
	}
}

export const invalidateCacheIfNeeded = t;