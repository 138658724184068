import React,{useMemo} from 'react'
import {timeDiff,normal} from 'utils/time'
import styles from './styles.module.scss'

export interface TimeProps {
  hasWeek?: boolean;
  date?: number | string;
  style?: React.CSSProperties;
  displayType?: 'DIFF' | 'NORMAL';
  className?: string;
  onClick?: () => void;
}

const Time:React.FC<TimeProps> = ({date,className,hasWeek,style,displayType="DIFF",onClick}) => {
  const time = useMemo(() => {
    switch(displayType) {
      case 'DIFF':
        return timeDiff(date,hasWeek);
      case 'NORMAL':
        return normal(date);
    }
  },[date,displayType])

  return(
    <time className={`${styles['time']} ${className}`} style={style} onClick={onClick}>{time}</time>
  )
}

export default Time;
