import React,{useMemo} from 'react'
import { StickyModal,StickyModalProps,ObserverLoad,VirtualList } from 'components'
import {UserCell,User} from '../index'

interface StickyDilogWithUsersProps extends StickyModalProps {
  users?: User[];
  avatarSize?: number;
  hasLoadMore?: boolean;
  onLoad?: () => void;
}


const StickyDilogWithUsers: React.FC<StickyDilogWithUsersProps> = ({avatarSize=30,users=[],hasLoadMore,onLoad,...props}) => {

  const _users = useMemo(() => {

    return users.map((user,) => <UserCell size={avatarSize} user={user} gap={12} style={{ padding: '8px 16px', flex: 0 }} flexDirection="row"  usernameStyle={{ fontWeight: 600 }} descriptionStyle={{ marginTop: -2 }} key={user.id} />)
  },[users])

  return (
    <StickyModal contentHeightType="AUTO" canStickTop={true} {...props}>
      <VirtualList
        renderer={({ index }) => {
          return _users[index];
        }}
        containerSize="100%"
        initialRenderCount={9}
        skipChildMeasurement={true}
        direction="vertical"
        itemCount={_users.length}
        estimatedItemSize={50}
        overscanCount={6} 
      />
      <ObserverLoad visible={hasLoadMore} onLoad={onLoad} height={25} size={20} />
    </StickyModal>
  )
}


export default StickyDilogWithUsers;