import React,{useState} from 'react'
import {Button,Avatar,StickyModal} from 'components'
import {User,UserCell} from '../index'
import styles from './messageLiked.module.scss'

export interface MessageLikedProps  {
  likedUsers?: User[];  
  currentUser?: User;
  onRemoveLike?: (id:string) => void;
}

const MessageLiked:React.FC<MessageLikedProps> = ({likedUsers=[],currentUser,onRemoveLike=() => {}}) => {
  const [visibel,setVisible] = useState<boolean>(false);

  return (
    <>
      <div className={styles['message-liked']} role="LIKEDUSERS">
        <Button onClick={() => { setVisible(true) }} type="TEXT" className={styles['message-liked-btn']}>
          <div className={styles['liked-btn-heart']}>❤️</div>
          <div className={styles['liked-btn-user']}>
            {likedUsers.map(user => {
              return <Avatar size={12} src={user.avatar} alt={`${user.username}的头像`} className={styles['btn-user-avatar']}/>
            })}
          </div>
        </Button>
      </div>
      <StickyModal title="心情" visible={visibel} onCancel={() => { setVisible(false) }}>
        {likedUsers.map(user => {
          const isOwn = currentUser.id == user.id;
          return (
            <div className={styles['message-liked-user']}>
              <UserCell user={user} size={40} description={isOwn ? '轻触即可移除' : null} gap={12} hasLink={false} style={{flex:1}} onClick={() => { if(isOwn){onRemoveLike(user.id);setVisible(false)} }} />
              <span className={styles['liked-user-heart']}>❤️</span>
            </div>
          )
        })}
      </StickyModal>
    </>
  )
}

export default MessageLiked;