import { EmptyFeedActionType } from 'constants/emptyFeedActionType'

const initialState:any = {
  isFetching: true,
  suggested: [],
}


const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case EmptyFeedActionType.EMPTY_FEED_SET_SUGGESTED:
      return {
        ...state,
        suggested: action.suggested
      }
    case EmptyFeedActionType.EMPTY_FEED_CHANGE_FETCH_STATUS:
      return {
        ...state,
        isFetching: action.isFetching
      }
    case EmptyFeedActionType.EMPTY_FEED_DELETE_SUGGESTED:
      return {
        ...state,
        suggested: state.suggested.filter(item => item.id != action.id)
      }
    default:
      return state;
  }
}



export default authReducer;