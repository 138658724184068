import { PostDetailActionType } from 'constants/postDetailActionType'
import {getPostDetail} from 'services/commons/postDetail'

export const changeLikeStatus = (id,liked) => {
  return {
    type: PostDetailActionType.POST_DETAIL_CHANGE_LIKED,
    liked
  }
}

export const changeAvatarLoadingStatus = (id,isAvatarLoading) => {
  return {
    type: PostDetailActionType.POST_DETAIL_CHANGE_AVATAR_LOADING_STATUS,
    isAvatarLoading
  }
}

export const changeViewerSeenStory = (id,viewerSeenStory) => {
  return {
    type : PostDetailActionType.POST_DETAIL_CHANGE_VIEWER_SEEN_STORY,
    viewerSeenStory
  }
}

export const changeCommentLikeStatus = (postId,commentId,liked) => {
  return {
    type: PostDetailActionType.POST_DETAIL_CHANGE_COMMENT_LIKED,
    commentId,
    liked
  }
}


export const addComment = (comment) => {
  return {
    type: PostDetailActionType.POST_DETAIL_ADD_COMMENT,
    comment
  }
}

export const changeSaveStatus = (id,saved) => {
  return {
    type: PostDetailActionType.POST_DETAIL_CHANGE_SAVE_STATUS,
    saved
  }
}

export const changeFollowStatus = (id,viewerFollowStatus) => {
  return {
    type: PostDetailActionType.POST_DETAIL_CHANGE_FOLLOW_STATUS,
    viewerFollowStatus
  }
}



export const setPostDetail = (post) => {
  return {
    type: PostDetailActionType.POST_DETAIL_SET,
    post
  } 
}


export const fetchPostDetail = () => {
  return function(dispatch) {
    return new Promise<void>(async (resolve) => {
      const postDetail = await getPostDetail();
      dispatch(setPostDetail(postDetail));
      resolve();
    })
  }
}