import { SuggestedActionType } from 'constants/suggestedActionType'
import {getSuggested} from 'services/containers/suggested'

export const changFetchStatus = (isFetching) => {
  return {
    type: SuggestedActionType.SUGGESTED_CHANGE_FETCH_STATUS,
    isFetching
  }
}

export const changeViewerSeenStory = (id,viewerSeenStory) => {
  return {
    type: SuggestedActionType.SUGGESTED_CHANGE_VIEWER_SEEN_STORY,
    id,viewerSeenStory
  }
}

export const changeAvatarLoadingStatus = (id,isAvatarLoading) => {
  return {
    type : SuggestedActionType.SUGGESTED_CHANGE_AVATAR_LOADING_STATUS,
    id,isAvatarLoading
  }
}


export const changeFollowStatus = (id,viewerFollowStatus) => {
  return {
    type: SuggestedActionType.SUGGESTED_CHANGE_FOLLOW_STATUS,
    id,
    viewerFollowStatus
  }
}



export const deleteSuggestedUser = (id) => {
  return {
    type: SuggestedActionType.SUGGESTED_DELETE,
    id
  }
}

export const setSuggested = (suggested) => {
  return {
    type: SuggestedActionType.SUGGESTED_SET,
    suggested
  } 
}

export const appendSuggested = (suggested) => {
  return {
    type: SuggestedActionType.SUGGESTED_APPEND,
    suggested    
  }
}


export const fetchSuggested = (isAppend,effectFetchStatus=false) => {
  
  return function(dispatch) {
    return new Promise<void>(async (resolve) => {
      effectFetchStatus && dispatch(changFetchStatus(true));
      const suggested:any = await getSuggested();
      dispatch((isAppend ? appendSuggested : setSuggested)(suggested));
      effectFetchStatus && dispatch(changFetchStatus(false));
      resolve();
    })
  }
}