import React from 'react';
import mutate from './mutate';
import scrollTo from './scroll';
import Estimate from './estimate';
import DomListener from './DomListener'

interface VirtualListState {
	renderEnd?: number;
	renderStart?: number;
	visibleEnd?: number;
	visibleStart?: number;

}

interface VirtualListProps {
	containerSize?: string
	className?: string;
	direction?: string;
	estimatedItemSize?: number;
	initialRenderCount?: number;
	itemCount?: number;
	onInitialize?: () => void
	onScroll?: (o: any) => void
	overscanCount?: number
	renderer?: ({ index, isVisible, visibleIndex }) => React.ReactNode
	sizeCache?: any
	skipChildMeasurement?: boolean;
	style?: React.CSSProperties
	listRef?: any;
}


class VirtualList extends React.Component<VirtualListProps, VirtualListState> {
	getItemOffset: (t: any) => any;
	scrollToItem: (t: any, s?: {}) => void;
	$IGVirtualList6: any;
	scrollBy: (t: any, s?: {}) => void;
	$IGVirtualList2: () => any;
	$IGVirtualList10: Estimate;
	$IGVirtualList11: (t: any) => any;
	$IGVirtualList12: any;
	$IGVirtualList4: () => void;
	$IGVirtualList1: () => void;
	$IGVirtualList3: () => void;
	$IGVirtualList13: (t: any) => void;
	scrollTo: (t: any, s?: {}) => void;
	$IGVirtualList7: () => Promise<any>
	$IGVirtualList5: () => any;
	$IGVirtualList8: (t: any) => void;
	$IGVirtualList9: (t: any) => { visibleStart: any; visibleEnd: any; renderEnd: any; renderStart: number; };

	constructor(props) {
		super(props);

		this.state = {
			renderEnd: this.props.initialRenderCount || 0,
			renderStart: 0,
			visibleEnd: 0,
			visibleStart: 0
		};
		this.getItemOffset = (t => t >= this.props.itemCount || t < 0 ? -1 : this.$IGVirtualList5().getOffset(t));

		this.scrollToItem = ((t, s = {}) => {
			if (null == this.$IGVirtualList6)
				return;
			const n = this.getItemOffset(t);
			n < 0 || this.scrollTo(n, s)
		});

		this.scrollTo = (t, s = {}) => {
			mutate.mutate(() => {
				const n = this.$IGVirtualList6;
				null != n && ('vertical' === this.props.direction ? scrollTo(n, {
					top: t,
					...s
				}) : scrollTo(n, {
					left: t,
					...s
				}))
			})
		}

		this.scrollBy = (t, s = {}) => {
			this.$IGVirtualList7().then(n => {
				this.scrollTo(n.scrollStart + t, s)
			})
		};

		this.$IGVirtualList2 = () => this.$IGVirtualList7().then(t => {
			this.$IGVirtualList8(t);
			const {
				scrollSize: s,
				scrollStart: n,
				viewportSize: l
			} = t;
			return {
				numScreensFromEnd: (s - n) / l - 1,
				numScreensFromStart: n / l
			}
		});

		this.$IGVirtualList8 = t => {
			const s = this.$IGVirtualList9(t);
			s.renderEnd === this.state.renderEnd && s.renderStart === this.state.renderStart && s.visibleEnd === this.state.visibleEnd && s.visibleStart === this.state.visibleStart || this.setState(s)
		}

		this.$IGVirtualList9 = t => {
			const {
				scrollStart: s,
				viewportSize: n
			} = t, {
				itemCount: l,
				overscanCount: o
			} = this.props, h = s + n, u = this.$IGVirtualList5().getIndex(s, l), c = this.$IGVirtualList5().getIndex(h, l) + 1;
			return {
				visibleStart: u,
				visibleEnd: c,
				renderEnd: c + o,
				renderStart: Math.max(0, u - o)
			}
		};

		this.$IGVirtualList7 = () => new Promise((t, s) => {
			mutate.measure(() => {
				const s = this.$IGVirtualList6;
				if (!s)
					return;
				const {
					containerSize: n,
					direction: l
				} = this.props;
				let o = 0,
					h = 0,
					u = 0;
				if (n != null) {
					if('vertical' === l) {
						o = s.scrollTop;
						h = 'number' == typeof n ? n : s.offsetHeight;
						u = s.scrollHeight;
					} else {
						o = s.scrollLeft;
						h = 'number' == typeof n ? n : s.offsetWidth;
						u = s.scrollWidth;
					}
				} else {
					o = -s.getBoundingClientRect().top;
					h = window.innerHeight;
					u = s.scrollHeight;
				}
				o = Math.max(0, o);
				t({
					scrollStart: o,
					scrollSize: u,
					viewportSize: h
				})
			})
		})

		this.$IGVirtualList5 = () => {
			const {
				estimatedItemSize: t,
				sizeCache: s
			} = this.props;
			return s || (this.$IGVirtualList10 || (this.$IGVirtualList10 = new Estimate({
				estimatedSize: t
			})),
				this.$IGVirtualList10)
		};

		this.$IGVirtualList11 = t => {
			const s = t.getBoundingClientRect(),
				n = t.nextElementSibling,
				l = this.$IGVirtualList12;
			let o = 0;
			return o = 'horizontal' === this.props.direction ? 0 === s.width ? 0 : n ? n.getBoundingClientRect().left - s.left : l && l.style ? l.getBoundingClientRect().right - parseFloat(l.style.paddingRight) - s.left : s.width : 0 === s.height ? 0 : n ? n.getBoundingClientRect().top - s.top : l && l.style ? l.getBoundingClientRect().bottom - parseFloat(l.style.paddingBottom) - s.top : s.height
		}

		this.$IGVirtualList4 = () => {
			!0 !== this.props.skipChildMeasurement && mutate.measure(() => {
				const t = this.$IGVirtualList12;
				if (null == t)
					return;
				const {
					renderStart: s,
					visibleStart: n
				} = this.state;
				let l = 0;
				for (let o = 0; o < t.children.length; o++) {
					const h = t.children[o],
						u = this.$IGVirtualList11(h),
						c = this.$IGVirtualList5().getSize(s + o);
					if (c !== u) {
						this.$IGVirtualList5().setSize(s + o, u);
						s + o <= n && (l += u - c);
					}
				}
				'vertical' === this.props.direction && null != this.props.containerSize && 0 !== l && mutate.mutate(() => {
					window.scrollTo(0, window.scrollY + l)
				})
			})
		}

		this.$IGVirtualList1 = () => {
			this.$IGVirtualList2().then(this.props.onInitialize)
		}

		this.$IGVirtualList3 = () => {
			this.$IGVirtualList2().then(this.props.onScroll)
		}

		this.$IGVirtualList13 = t => {
			this.$IGVirtualList6 = t;
			const {
				listRef: s
			} = this.props;
			null != s && ('function' != typeof s ? s.current = t : s(t))
		}
	}

	componentDidMount() {
		'number' != typeof this.props.initialRenderCount ? this.$IGVirtualList1() : (window['requestIdleCallback'] ? window['requestIdleCallback'] : requestAnimationFrame)(this.$IGVirtualList1)
	}
	componentDidUpdate(t) {
		this.props.itemCount !== t.itemCount && this.$IGVirtualList2();
		this.props.containerSize !== t.containerSize && this.$IGVirtualList3();
		this.$IGVirtualList4();
	}
	$IGVirtualList14() {
		const {
			containerSize: t,
			direction: s,
			style: n
		} = this.props;
		return null == t ? n : 'vertical' === s ? {
			height: t,
			overflowY: 'auto',
			overflowX: 'hidden',
			...n
		} : {
			width: t,
			overflowX: 'auto',
			overflowY: 'hidden',
			...n
		}
	}
	$IGVirtualList15() {
		const {
			direction: t,
			itemCount: s
		} = this.props, {
			renderEnd: n,
			renderStart: l
		} = this.state, o = this.$IGVirtualList5().getDistance(0, l), h = this.$IGVirtualList5().getDistance(n, s);
		return 'vertical' === t ? {
			flexDirection: 'column',
			paddingBottom: h,
			paddingTop: o
		} : {
			flexDirection: 'row',
			paddingLeft: o,
			paddingRight: h
		}
	}
	$IGVirtualList16() {
		const {
			itemCount: t,
			renderer
		} = this.props, {
			renderEnd: n,
			renderStart: l,
			visibleEnd: o,
			visibleStart: h
		} = this.state, u = [];
		let c = 0;
		const p = Math.min(n, t);
		for (let t = l; t < p; t++) {
			const n = h <= t && t < o,
				l = n ? c : null;
			u.push(renderer({
				isVisible: n,
				index: t,
				visibleIndex: l
			}));
			n && c++
		}
		return u
	}
	render() {
		const {
			className: t,
			containerSize: s
		} = this.props;

		return React.createElement("div", {
			className: t,
			onScroll: this.$IGVirtualList3,
			ref: this.$IGVirtualList13,
			style: this.$IGVirtualList14()
		}, null == s && React.createElement(DomListener, {
			event: "scroll",
			handler: this.$IGVirtualList3,
			passive: !0,
			target: window
		}), React.createElement("div", {
			ref: t => this.$IGVirtualList12 = t,
			style: this.$IGVirtualList15()
		}, this.$IGVirtualList16()))
	}
}

export default VirtualList;