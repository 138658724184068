import React from 'react';
import UserWithAction from '../UserWithAction'
import {FollowUser,FollowEvent,User} from '../index'
import styles from './styles.module.scss'


interface SuggestedInRowProps extends FollowEvent{
  title?: React.ReactNode;
  users?: FollowUser[];
  onAvatarClick?: (user:User) => void;
}

const SuggestedInRow: React.FC<SuggestedInRowProps> = ({title,users=[],...props}) => {
  return (
    <div className={styles['suggested-in-row']}>
      <h4 className={styles['in-row-title']}>{title}</h4>
      {users.map(({user,viewerSeenStory,viewerFollowStatus}) => {
        return <UserWithAction 
            hasRing={user.hasNewStory}
            isLoading={user.isAvatarLoading} 
            seenStory={viewerSeenStory}
            actionType="FOLLOW" 
            user={user} 
            containerStyle={{padding:'8px 16px'}}
            viewerFollowStatus={viewerFollowStatus} 
            description={user.fullname}  
            avatarSize={44} 
            gap={12} 
            {...props}  
            />;
      })}
    </div>
  )
}

export default SuggestedInRow;