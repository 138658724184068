import { UserActionType } from 'constants/userActionType'
import {getUser} from 'services/commons/appNavigation'


export const setUser = (user) => {
  return {
    type: UserActionType.USER_SET,
    user
  }  
}


export const changeFetchStatus = (isFetching) =>{
  return {
    type: UserActionType.USER_CHANGE_FETCH_STATUS,
    isFetching 
  }  
}

export const changeAvatar = (avatar) => {
  return {
    type : UserActionType.USER_SET_AVATAR,
    avatar,
  }
}

export const removeAvatar = () => {
  return {
    type : UserActionType.USER_SET_AVATAR,
    avatar: require('assets/images/default_avatar.jpg')
  }
}


export const fetchUser = () => {
  return async function(dispatch) {
    dispatch(changeFetchStatus(true));
    const user = await getUser();
    dispatch(setUser(user));
    dispatch(changeFetchStatus(false));
  }
}