import { LikedByActionType } from 'constants/likedByActionType'


const initialState = {
  likedBy: [],
  isFetching: true
}

const followerReducer = (state=initialState,action) => {
  switch(action.type) {
    case LikedByActionType.LIKED_BY_SET:
      return {
        ...state,
        likedBy: action.likedBy
      }
    case LikedByActionType.LIKED_BY_CHANGE_AVATAR_LOADING_STATUS:
      return {
        ...state,
        likedBy: state.likedBy.map(item => {
          if(item.user.id == action.id) {
            item.user.isAvatarLoading = action.isAvatarLoading;
          }
          return item;
        })
      }
    case LikedByActionType.LIKED_BY_CHANGE_VIEWER_SEEN_STORY:
      return{
        ...state,
        likedBy: state.likedBy.map(item => {
          if(item.user.id == action.id) {
            item.viewerSeenStory = action.viewerSeenStory;
          }
          return item;
        })
      }
    case LikedByActionType.LIKED_BY_CHANGE_FETCH_STATUS :
      return {
        ...state,
        isFetching: action.isFetching
      }
    case LikedByActionType.LIKED_BY_APPEND : 
      return {
        ...state,
        likedBy : [...state.likedBy,...action.likedBy]
      }
    case LikedByActionType.LIKED_BY_CHANGE_FOLLOW_STATUS :
      return {
        ...state,
        likedBy : state.likedBy.map(item => {
          if(item.user.id == action.id) {
            item.viewerFollowStatus = action.viewerFollowStatus;
          }
          return item;
        })
      }
    default:
      return state;
  }
}



export default followerReducer;