import React from 'react'
import {Spin} from 'components'
import styles from './styles.module.scss'

export type ButtonType = 'PRIMARY' | 'TEXT' | 'DEFAULT' | 'WARNING'

export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement>{
  loading?: boolean;
  style?: React.CSSProperties;
  className?: string;
  disabled?: boolean;
  children?: React.ReactNode;
  type?: ButtonType;
  htmlType?: 'submit' | 'button' | 'reset';
  size?: 'LARGE' | 'MIDDLE' | 'SMALL';
  danger?: boolean;
}

const Button:React.FC<ButtonProps> = ({className,size='MIDDLE',danger,type = 'PRIMARY',htmlType,children,loading=false,...props}) => {
  return(
    <button className={`${styles['button']} ${styles[`button-${type}`]} ${styles[`button-${size}`]} ${danger ? styles['button-dangerous'] : ''} ${className}`} type={htmlType} {...props}>
      {loading ? <Spin visible={loading} size={18} type="THINSPIN" fill="#fafafa" animationDuration="0.8s"/> : children}
    </button>
  )
}

export default Button;
