import { StoryActionType } from 'constants/storyActionType'


const initialState = {
  story : [],
  isFetching: false,
}

const storyReducer = (state=initialState,action) => {
  switch(action.type) {
    case StoryActionType.STORY_SET :
      return {
        ...state,
        story:action.story
      }
    case StoryActionType.STORY_CHANGE_FETCH_STATUS :
      return {
        ...state,
        isFetching: action.isFetching
      }
    case StoryActionType.STORY_CHANGE_FOLLOW_STATUS:
      return {
        ...state,
        story: state.story.map(item => {
          if(item.owner.id == action.id) {
            item.viewerFollowStatus = action.viewerFollowStatus;
          }
          return item;
        })        
      }
    case StoryActionType.STORY_CHANGE_ACTIVE_CAROUSEL:
      return {
        ...state,
        story: state.story.map(item => {
          if(item.id == action.id) {
            item.activeCarousel = action.activeCarousel;
          }
          return item;
        })
      }
    case StoryActionType.STORY_DELETE_MEDIA:
      const story = state.story.filter(item => item.id == action.id)[0];
      return {
        ...state,
        story: story.carousel.length == 1 ? state.story.filter(item => item.id != action.id) : state.story.map(item => {
          if(item.id == action.id) {
            item.carousel = item.carousel.filter(it => it.id != action.mediaId);
            if(item.activeCarousel > 0) {
              item.activeCarousel = item.activeCarousel - 1
            }
          }
          return item;
        })
      }
    default:
      return state;
  }
}



export default storyReducer;