import React from 'react'
import {SpriteIcon,Button} from 'components'
import {Dimensions} from '../index'
import styles from './styles.module.scss'

export interface UserTagItem {
  position?: any[];
  username?: string;
  userId?: string;
}


interface UserTagProps {
  hasDelete?: boolean;
  onDelete?: (userId?: string) => void;
  className?: string;
  tag?: UserTagItem;
  imgDimensions?: Dimensions;
  onPositionChange?: (tag?:UserTagItem) => void;
  canMove?:boolean;
}

interface UserTagState {
  isPointerUp?: boolean;
  isDeleting?: boolean;
  position?: any[];
}

const TAG_ORIENTATION_CHANGE_POINT = 0.85;
const TAG_POINTER_HEIGHT = 6;
const EDGE_THRESHOLD = 0.04

const getHorizontalOffset = (t,s)=>{
  const o = s + .02
    , h = o > 1 - t;
  return o > t || h ? h ? -54 * (1 - (1 - t) / o) - 50 : -50 - -54 * (1 - t / o) : -50
}

const trans = t => t >= EDGE_THRESHOLD ? t <= 1 - EDGE_THRESHOLD ? t : 1 - EDGE_THRESHOLD : EDGE_THRESHOLD;


class UserTag extends React.Component<UserTagProps, UserTagState> {
  $CreationUsertag1: number[];
  $CreationUsertag2: (t: any) => void
  $CreationUsertag3: (t: any) => void;
  $CreationUsertag4: (t: any) => void;
  $CreationUsertag5: () => void;
  $CreationUsertag6: any;

  constructor(props) {
    super(props);
    this.$CreationUsertag1 = [0, 0];
    const {tag} = props;
    const {position} = tag
    this.state = {
      isPointerUp: true,
      isDeleting: false,
      position :[trans(position[0]),trans(position[1])]
    }

    this.$CreationUsertag2 = t => {
      const { tag: s, onDelete: n } = this.props;
      t.stopPropagation();
      t.target instanceof HTMLSpanElement && 'deleteButton' === t.target.id ? n(s.userId) : this.setState(t => ({
        isDeleting: !t.isDeleting
      }))
    }

    this.$CreationUsertag3 = t => {
      this.$CreationUsertag1 = [t.currentTarget.offsetLeft - t.touches[0].clientX, t.currentTarget.offsetTop - t.touches[0].clientY]
    }

    this.$CreationUsertag4 = t => {
      t.preventDefault();
      const { imgDimensions: { height: s, width: n } = {}, tag: o,onPositionChange:l } = this.props;
      if (null == s || null == n)
        return;
      const c = (t.touches[0].clientX + this.$CreationUsertag1[0]) / n
        , h = (t.touches[0].clientY + this.$CreationUsertag1[1]) / s;
      l && l({
        userId: o.userId,
        username: o.username,
        position: [c, h]
      });
      
      this.setState({
        isDeleting: !1,
        position:[trans(c),trans(h)]
      })
    }

    this.$CreationUsertag5 = ()=>{
      this.$CreationUsertag1 = [0, 0]
    }

  }

  componentDidMount() {
    this.forceUpdate();
  }

  render() {
    const {imgDimensions: {width: t}={}, tag: { username: o}, hasDelete, className} = this.props
    , {isDeleting: l,position: [s,n]} = this.state
    , c = n <= TAG_ORIENTATION_CHANGE_POINT
    , h = this.$CreationUsertag6 && null != t && t > 0 ? this.$CreationUsertag6.getBoundingClientRect().width / t / 2 : 0
    , u = {
      left: `${100 * s}%`,
      paddingBottom: `${c ? 0 : TAG_POINTER_HEIGHT}px`,
      paddingTop: `${c ? TAG_POINTER_HEIGHT : 0}px`,
      top: `${100 * n}%`,
      transform: `translate(${getHorizontalOffset(s, h)}%,${c ? 0 : -100}%)`
  }
    , p = {
      left: `${-getHorizontalOffset(s, h)}%`
    };

    return (
      <div className={`${styles['user-tag']} ${className}`} onTouchEnd={hasDelete ? this.$CreationUsertag5 : null} onTouchMove={hasDelete ? this.$CreationUsertag4 : null } onTouchStart={hasDelete? this.$CreationUsertag3 : null} ref={(t) => this.$CreationUsertag6 = t} style={u} >
        <div className={styles['user-tag-content']}>
          <Button type="TEXT" className={styles['tag-content-btn']} onClick={this.$CreationUsertag2}>
            <div className={`${styles['content-btn-arrow']} ${c ? styles['btn-arrow-top'] : styles['btn-arrow-bottom']}`} style={p} />
            <span className={styles['content-btn-username']}>{o}</span>
            {hasDelete && l && <SpriteIcon type="880" size={20} style={{ marginRight: 10 }} backgroundPosition="-380px -96px" backgroundSize="440px 411px" id="deleteButton" />}
          </Button>
        </div>
      </div>
    )
  }
}

export default UserTag;