import React from 'react'


const Typing:React.FC = () => {
  return (
    <div className="typing">正在输入...</div>
  )
}


export default Typing;