import request from 'utils/request'


//获取举报
export async function getReport() {
  return request({
    url: '/report'
  })
}

export async function getReportFeedback(type) {
  return request({
    url: `/reportFeedback?type=${type}`
  })  
}

export async function getReportFollowUp(type) {
  return request({
    url: `/reportFollowUp?type=${type}`
  })  
}