import React, { useRef } from 'react'
import styles from './styles.module.scss'
import {NavBar,Badge} from 'components'
import UploadFileForStory from '../UploadFileForStory'

const AppBanner: React.FC = ({ }) => {
  const uploadFileForStoryRef = useRef(null);


  return (
    <NavBar style={{padding:'0 4px'}} fixedType="TOP" >
      <NavBar.Navigation type="CAMERA" onClick={() => { uploadFileForStoryRef.current.click(); }}/>
      <div className={styles['app-banner']}>
        <a href="/">
          <img src={require('assets/images/logo.png')} alt="instagram" />
        </a>
      </div>

      <NavBar.Navigation type="DIRECT" linkTo="/direct" >
        <Badge count={1} className={styles['badge']} />
      </NavBar.Navigation>
      <UploadFileForStory ref={uploadFileForStoryRef} />
    </NavBar>
  );
}

export default AppBanner;
