import React from 'react'
import { getOrientation } from "get-orientation/browser";
import { loading } from 'components'

export interface ImageData { 
  dataURL: string; 
  width: number; 
  height: number;
  image: HTMLImageElement;
  orientation?: number;
}


interface ImageUploadProps  {
  onChange?: (file: any, iamgeData:ImageData) => void;
  hasLoading?: boolean;
}


const ImageUpload = ({ onChange = () => { },hasLoading=true}: ImageUploadProps, ref: React.RefObject<any>) => {


  const fileChange = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    const orientation = await getOrientation(file);

    reader.readAsDataURL(file);
    hasLoading && loading.start({ height: 3 });
    reader.onloadend = function (e) {
      const dataURL = e.target.result as string;
      const image = new Image();
      image.src = dataURL;
      image.onload = function () {
        hasLoading && loading.done();
        onChange(file, {
          image,
          dataURL,
          width: image.width,
          height: image.height,
          orientation
        });
        ref.current.value = '';
      }
    }
  }

  return (
    <>
      <input onChange={fileChange} ref={ref} type="file" accept="image/*" style={{ display: 'none' }} />
    </>
  )
}


export default React.forwardRef(ImageUpload);