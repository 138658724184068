import React,{useState,useEffect,useMemo} from 'react'
import {Select} from 'components'
import dayjs from 'dayjs'
import styles from './styles.module.scss'

import  isLeapYear  from 'dayjs/plugin/isLeapYear'
dayjs.extend(isLeapYear)

interface BirthDateProps {
  defaultDate?: any;
  onChange?: (v?:string) => void;
  yearFrom?: number;
  yearTo?: number;
  placeholderVisible?: boolean;
}

const BirthDate:React.FC<BirthDateProps> = ({defaultDate=new Date(),onChange = (v) => {},yearFrom=1919,yearTo=dayjs(new Date()).year(),placeholderVisible = true}) => {

  const _date = dayjs(defaultDate);

  const [date,setDate] = useState({
    year : _date.year(),
    month:_date.month() + 1,
    day:_date.date()
  });
  const days:number[] = useMemo(() => {
    return [ 31, dayjs(date.year).isLeapYear() ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ]
  },[date.year])


  useEffect(() => {
    const {year,month,day} = date;
    onChange(`${year}/${month}/${day}`);
  },[date])

  const dateOptions = useMemo(() => {
    const {month,year,day} = date;

    return [
      {
        defaultValue: month,
        options : [...new Array(12)].map((e,i) => {
          const m = i + 1;
          return {
            label : `${m}月`,
            value : m
          }
        }),
        onChange(month) {
          setDate(prev => {
            return {
              ...prev,
              month
            }
          })
        }
      },
      {
        defaultValue: day,
        options : [...new Array(days[month as number - 1])].map((e,i) => {
          const d = i + 1;
          return {
            label : d,
            value : d,
          }
        }),
        onChange(day) {
          setDate(prev => {
            return {
              ...prev,
              day
            }
          })
        }
      },
      {
        defaultValue: year,
        options : [...new Array(yearTo - yearFrom + 1)].map((e,i) => {
          const y = yearTo - i;
          return {
            label : y,
            value : y,
          }
        }),
        onChange(year) {
          setDate(prev => {
            return {
              ...prev,
              year
            }
          })
        }
      }
    ]
  },[date,yearTo,yearFrom])

  return (
    <div className={styles['birth-date']}>
      <div className={styles['birth-date-content']}>
        {dateOptions.map(item => {
          return (
            <Select  className={styles['date-conent-select']} defaultValue={item.defaultValue} onChange={item.onChange}>
              {item.options.map(({value,label}) => {
                return <Select.Option value={value}>{label}</Select.Option>
              })}
            </Select>
          )
        })}
      </div>
      {placeholderVisible && <div className={styles['birth-date-placehoder']}>请输入你的出生日期</div>}
    </div>
  )
}

export default BirthDate;