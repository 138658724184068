import React,{useState} from 'react'
import ReportTags from './ReportTags'
import {Report} from './ReportTags'
import {Button} from 'components'
import PolicyEducation,{PolicyEducationProps} from './PolicyEducation'

export interface FollowUp {
  policyEducation?: PolicyEducationProps;
  report?:Report;
  buttonText?: string;
  type?: string;
}

interface ReportFollowUpProps {
  reportFollowUp?: FollowUp;
  isFetchFeedback?: boolean;
  onFollowUpBtnClick?: (type:string) => void;
}

const ReportFollowUp:React.FC<ReportFollowUpProps> = ({reportFollowUp,isFetchFeedback,onFollowUpBtnClick}) => {
  const [seletcTag,setSelectTag] = useState<string>('');
  const {report,policyEducation,buttonText,type} = reportFollowUp;
  const {reportTags=[]} = report;
  return (
    <>
      <ReportTags report={report} onRadioChange={(e) => { setSelectTag(e.target.value) }}/>
      <PolicyEducation {...policyEducation}/>
      <Button 
        type="PRIMARY" 
        onClick={() => { onFollowUpBtnClick(type) }}  
        disabled={reportTags.length && !seletcTag} 
        loading={isFetchFeedback} 
        style={{margin:'0 16px 16px 16px'}}
        >
          {buttonText}
        </Button>
    </>
  )
}

export default ReportFollowUp;