import { CommentsActionType } from 'constants/commentsActionType'

const initialState = {
  caption:{
    owner: {}
  },
  count:0,
  comments:[],
  hasNextPage:false,
  isFetching: false
}

const commentsReducer = (state=initialState,action) => {
  switch(action.type) {
    case CommentsActionType.COMMENTS_SET :
      return {
        ...action.comments
      }
    case CommentsActionType.COMMENTS_ADD_CHILD_COMMENT:
      state.comments.filter(item => item.id == action.id).forEach(item => {
        item.comments = [action.comment,...item.comments];
      })      
      return {
        ...state,
        comments: [...state.comments]
      }
    case CommentsActionType.COMMENTS_ADD:
      state.comments.unshift(action.comment);
      return {
        ...state,
        comments:[...state.comments]
      }
    case CommentsActionType.COMMENTS_CHANGE_EXPANDED_STATUS:
      state.comments.filter(item => item.id == action.id).forEach(item => {
        item.expanded = action.expanded;
      })
      return {
        ...state,
        comments:[...state.comments]
      }      
    case CommentsActionType.COMMENTS_CHANGE_FETCH_STATUS:
      return {
        ...state,
        isFetching:action.isFetching
      }
    case CommentsActionType.COMMENTS_APPEND_CHILD_COMMENT:
      state.comments.filter(item => item.id == action.id).forEach(item => {
        item.comments=[...item.comments,...action.comments]
      })
      return {
        ...state,
        comments:[...state.comments]
      }
    case CommentsActionType.COMMENTS_APPEND:
      return {
        ...state,
        comments: [...state.comments,...action.comments.comments]
      }
    case CommentsActionType.COMMENTS_DELETE_CHILD_COMMENT:
      state.comments.filter(item => item.id == action.id).forEach(item => {
        item.comments = item.comments.filter(it => it.id != action.childId);
      })
      return {
        ...state,
        comments: [...state.comments ]
      }
    case CommentsActionType.COMMENTS_DELETE_COMMENT:
      return {
        ...state,
        comments: state.comments.filter(item => item.id != action.id)
      }
    case CommentsActionType.COMMENTS_CHANGE_CHILD_LIKE_STATUS:
      state.comments.filter(item => item.id == action.id).forEach(item => {
        item.comments.filter(item => item.id == action.childId).forEach(item => {
          item.viewerHasLiked = action.liked;
          action.liked ? item.likeByCount++ : item.likeByCount--;
        })
      })
      return {
        ...state,
        comments: [...state.comments]        
      }
    case CommentsActionType.COMMENTS_CHANGE_LIKE_STATUS:
      state.comments.filter(item => item.id == action.id).forEach(item => {
        item.viewerHasLiked = action.liked
        action.liked ? item.likeByCount++ : item.likeByCount--;
      })
      return {
        ...state,
        comments: [...state.comments]        
      }
    case CommentsActionType.COMMENTS_CHANGE_CHILD_FETCH_STATUS:
      state.comments.filter(item => item.id == action.id).forEach(item => item.isFetchChildComments = action.isFetchChildComments)
      return {
        ...state,
        comments: [...state.comments]
      }
    case CommentsActionType.COMMENTS_CHANGE_AVATAR_LOADING_STATUS:
      return {
        ...state,
        caption : {
          ...state.caption,
          owner: {
            ...state.caption.owner,
            isAvatarLoading: action.isAvatarLoading
          }
        }
      }
    case CommentsActionType.COMMENTS_CHANGE_VIEWER_SEEN_STORY:
      return {
        ...state,
        caption : {
          ...state.caption,
          viewerSeenStory: action.viewerSeenStory
        }
      }
    default:
      return state;
  }
}

export default commentsReducer;