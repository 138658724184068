import React,{useMemo} from 'react'
import {Link} from 'react-router-dom'
import Username from '../Username'
import Autolinker from 'autolinker';
import styles from './styles.module.scss'


interface LinkifyTextProps {
  text?: string;
  className?: string;
  style?: React.CSSProperties
}

const LinkifyText:React.FC<LinkifyTextProps> = ({text='',...props}) =>{
  const brReg = /(\r\n|[\r\n])/;

  const content = useMemo(() => {
    const hastags = [];
    const urls = [];
    const emails = [];
    const mentions = [];
    const hrefMap = {};
    const matches:any[] = Autolinker.parse(text,{urls:true,email:true,mention:'instagram',hashtag:'instagram'});

    matches.forEach(match => {
      const type = match.getType();
      const matchedText = match.matchedText;
      switch(type) {
        case 'url' :
          const url = matchedText;
          hrefMap[url] = match.getAnchorHref();
          urls.push(url);
        break;
        case 'email' :
          const email = matchedText;
          hrefMap[email] = match.getAnchorHref();
          emails.push(email);
        break;
        case 'hashtag':
          hastags.push(matchedText);
        break;
        case 'mention':
          mentions.push(matchedText);
        break;
      }
    })
    
    

    const strs = matches.length ? text.split(new RegExp(`(${[...hastags,...urls,...mentions,...emails].join('|')})`,'g')) : [text];

    return strs.map(str => {
      //tag
      if(hastags.includes(str)) {
        return <Link to={`/explore/tags/${str.replace('#','')}`} className={styles['text']}>{str}</Link>
      }
      //mention
      if(mentions.includes(str)) {
        return <Username username={str} fullname={str.replace('@','')} className={styles['text']} />
      }
      //urls || emails
      
      if(urls.includes(str) || emails.includes(str)) {
        return <a href={hrefMap[str]} >{str}</a>
      } 
      //换行
      const _str = str.split(brReg);
      let r = [];
      for(let i = 0; i < _str.length; i++) {
        if(_str[i]) {
          r.push(i % 2 == 1 ? <br/> : _str[i]);
        }
      }

      return r;

    })

  },[text])

  return (
    <span {...props}>{content}</span>
  )
}

export default LinkifyText;