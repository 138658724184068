import React from 'react'
import {ListModal,ListModalProps} from 'components'


interface CommentsActionProps extends ListModalProps{
  isOwn?: boolean;
  onReportClick?: () => void;
  onDeleteClick?: () => void;
}

const CommentsAction:React.FC<CommentsActionProps> = ({isOwn,onReportClick,onDeleteClick,onCancel,...props}) => {

  const options:any = [
    {
      text: '举报',
      onClick:onReportClick,
      warning: true,
    },
  ]

  if(isOwn) {
    options.splice(0,0,{
      text: '删除',
      warning: true,
      onClick:onDeleteClick
    })
  } 

  return (
    <ListModal onCancel={onCancel}  {...props} >
      {options.map(item => {
        return <ListModal.Item type={item.warning ? 'WARNING' : 'DEFAULT'} onClick={() => {onCancel();item.onClick()}}>{item.text}</ListModal.Item>
      })}
    </ListModal>
  )
}


export default CommentsAction;