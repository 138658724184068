import React,{useState,useEffect} from 'react'
import {SVG,SVGProps} from 'components'
import styles from './styles.module.scss'

interface LikeButtonProps extends SVGProps {
  liked?: boolean;
}

const LikeButton:React.FC<LikeButtonProps> = ({size,className,liked,...props}) => {
  const [active,setActive] = useState<boolean>(false);
  const [mounted,setMounted] = useState<boolean>(false);

  useEffect(() => {
    if(mounted) {
      setActive(false);
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          setActive(true);
        })
      })
    }
  },[liked]);

  useEffect(() => {
    setMounted(true);
  },[])

  return (
    <SVG className={`${active ? styles['like-button'] : ''} ${className}`} size={size} fill={liked ? "#ed4956" : '#262626' } viewBox={liked ? 48 : 24 } ariaLable={liked ? "取消赞" : '赞'} type={liked ? "LIKEACTIVE" : 'LIKE' } {...props}/>
  )
}

export default LikeButton;