import { CreateStoryActionType } from 'constants/createStoryActionType'
import textSettings from 'containers/CreateStory/textSettings'
import mediaTypes from 'containers/CreateStory/mediaTypes'

const t = {
  sessionId: null,
  sourceImage: null,
  sourceVideo: null,
  coverPhoto: null,
  isUploading: !1,
  uploadedBlob: null,
  imageData:null,
  activeText: {
    color: 'white',
    fontSize: textSettings.DEFAULT_FONT_SIZE,
    fontWeight: 600,
    height: 0,
    rawText: '',
    renderText: [],
    textShadow: {
      shadowColor: 'rgba(150, 150, 150, 0.3)',
      shadowOffsetX: 0,
      shadowOffsetY: 1,
      shadowBlur: 2
    },
    transformation: {
      cos: 1,
      sin: 0,
      scale: 1
    },
    type: textSettings.TEXT_TYPE,
    width: 0,
    x: 0,
    y: 0
  },
  isTextSaved: !1,
  isEditingText: !1,
  activeDrawing: {
    activeColor: 'white',
    linesArray: [],
    startDrawIndex: []
  },
  isEditingDrawing: !1,
  activeBrush: 'marker',
  brushSize: 10,
  stickerToolOpen: !1,
  canvasStickers: [],
  stickerResults: [],
  isMovingSticker: !1,
  overTrash: !1,
  isExitingStoryCreation: !1,
  isUsingModal: !1,
  canvasDimensions: {
    height: 0,
    width: 0
  },
  mediaType: null
}
  , s = {
    color: 'white',
    fontSize: textSettings.DEFAULT_FONT_SIZE,
    fontWeight: 600,
    height: 0,
    rawText: '',
    renderText: [],
    textShadow: {
      shadowColor: 'rgba(150, 150, 150, 0.3)',
      shadowOffsetX: 0,
      shadowOffsetY: 1,
      shadowBlur: 2
    },
    transformation: {
      cos: 1,
      sin: 0,
      scale: 1
    },
    type: textSettings.TEXT_TYPE,
    width: 0,
    x: 0,
    y: 0
  }
  , n = {
    cos: 1,
    sin: 0,
    scale: 1
  };
var o = function (o = t, c) {
  switch (c.type) {

    case CreateStoryActionType.CREATE_STORY_SET_IMAGE_DATA:
      return {
        ...o,
        imageData: c.imageData
      }
    case CreateStoryActionType.CREATE_STORY_SESSION_STARTED:
      return {
        ...o,
        sessionId: c.sessionId,
        sourceImage: {
          ...o.sourceImage,
          isProcessing: !0
        },
      };
    case CreateStoryActionType.CREATE_STORY_SET_CANVAS_DIMENSIONS:
      return {
        ...o,
        canvasDimensions: {
          width: c.canvasWidth,
          height: c.canvasHeight
        }
      };
    case CreateStoryActionType.CREATE_STORY_IMAGE_PROCESSED:
      return {
        ...o,
        sourceImage: {
          dataURL: c.sourceDataURL,
          file: c.sourceImage,
          height: c.height,
          width: c.width,
          orientation: c.orientation,
          location: c.location,
          isProcessing: !1
        },
        mediaType: mediaTypes.IMAGE
      };
    case CreateStoryActionType.CREATE_STORY_VIDEO_PROCESSED:
      return {
        ...o,
        sourceVideo: {
          dataURL: c.dataURL,
          entityName: c.entityName,
          file: c.file,
          uploadId: c.uploadId,
          uploadMediaHeight: c.uploadMediaHeight,
          uploadMediaWidth: c.uploadMediaWidth,
          uploadMediaDurationMs: c.uploadMediaDurationMs,
          videoTransform: c.videoTransform,
          mediaPublishMode: c.mediaPublishMode,
          isProcessing: !1
        },
        mediaType: mediaTypes.VIDEO
      };
    case CreateStoryActionType.CREATE_STORY_VIDEO_COVER_PHOTO_UPDATED:
      return {
        ...o,
        coverPhoto: {
          dataURL: c.dataURL,
          entityName: c.entityName,
          file: c.file,
          uploadId: c.uploadId,
          uploadMediaHeight: c.uploadMediaHeight,
          uploadMediaWidth: c.uploadMediaWidth
        }
      };
    case CreateStoryActionType.CREATE_STORY_UPLOAD_REQUESTED:
      return {
        ...o,
        uploadedBlob: c.uploadedBlob,
        isUploading: !0
      };
    case CreateStoryActionType.CREATE_STORY_VIDEO_UPLOAD_REQUESTED:
      return {
        ...o,
        isUploading: !0
      };
    case CreateStoryActionType.CREATE_STORY_UPLOAD_STAGGED:
      return {
        ...o,
      };
    case CreateStoryActionType.CREATE_STORY_UPLOAD_FAILED:
      return {
        ...o,
        isUploading: !1
      };
    case CreateStoryActionType.CREATE_STORY_ENTER_ADD_TEXT:
      return {
        ...o,
        canvasStickers: [...o.canvasStickers, {
          ...s,
          x: o.canvasDimensions.width / 2,
          y: o.canvasDimensions.height / 2
        }],
        isEditingText: !0
      };
    case CreateStoryActionType.CREATE_STORY_ENTER_EDIT_TEXT:
      return {
        ...o,
        isTextSaved: !1,
        isEditingText: !0
      };
    case CreateStoryActionType.CREATE_STORY_CHANGE_TEXT:
      if (o.canvasStickers.length > 0) {
        const t = o.canvasStickers[o.canvasStickers.length - 1];
        if (t.type === textSettings.TEXT_TYPE)
          return {
            ...o,
            canvasStickers: [...o.canvasStickers.slice(0, -1), {
              ...t,
              rawText: c.rawText,
              width: c.width,
              height: c.height
            }],
            isTextSaved: !1
          }
      }
      return console.log('Should only change a text element'),
        o;
    case CreateStoryActionType.CREATE_STORY_SAVE_TEXT:
      {
        if (0 === c.renderText.length)
          return {
            ...o,
            canvasStickers: [...o.canvasStickers.slice(0, -1)],
            isEditingText: !1,
          };
        const t = o.canvasStickers[o.canvasStickers.length - 1];
        return t.type === textSettings.TEXT_TYPE ? {
          ...o,
          isEditingText: !1,
          canvasStickers: [...o.canvasStickers.slice(0, -1), {
            ...t,
            renderText: c.renderText
          }],
        } : (console.log('Should only save a text element'),
          o)
      }
    case CreateStoryActionType.CREATE_STORY_CHANGE_COLOR:
      {
        const t = o.canvasStickers[o.canvasStickers.length - 1];
        return t.type === textSettings.TEXT_TYPE ? {
          ...o,
          canvasStickers: [...o.canvasStickers.slice(0, -1), {
            ...t,
            color: c.color
          }]
        } : (console.log('Should only save a text element'),
          o)
      }
    case CreateStoryActionType.CREATE_STORY_MOVE_CANVAS_STICKER:
      if (o.canvasStickers.length > 0) {
        const { deltaX: t, deltaY: s } = c
          , n = o.canvasStickers[o.canvasStickers.length - 1];
        return n.type === textSettings.TEXT_TYPE ? {
          ...o,
          canvasStickers: [...o.canvasStickers.slice(0, -1), {
            ...n,
            type: textSettings.TEXT_TYPE,
            x: n.x + t,
            y: n.y + s
          }],
          isMovingSticker: !0,
          overTrash: c.overTrash
        } : {
          ...o,
          canvasStickers: [...o.canvasStickers.slice(0, -1), {
            ...n,
            type: textSettings.PLACED_TYPE,
            x: n.x + t,
            y: n.y + s
          }],
          isMovingSticker: !0,
          overTrash: c.overTrash
        }
      }
      return console.log('Should not be able to move stickers when there are none'),
        o;
    case CreateStoryActionType.CREATE_STORY_TRANSFORM_CANVAS_STICKER:
      if (o.canvasStickers.length > 0) {
        const t = o.canvasStickers[o.canvasStickers.length - 1]
          , { cos: s, sin: n, scale: _ } = t.transformation;
        return t.type === textSettings.TEXT_TYPE ? {
          ...o,
          canvasStickers: [...o.canvasStickers.slice(0, -1), {
            ...t,
            transformation: {
              cos: s * c.transform.cos - n * c.transform.sin,
              sin: n * c.transform.cos + s * c.transform.sin,
              scale: Math.min(_ * c.transform.scale, textSettings.MAX_SCALE)
            },
            type: textSettings.TEXT_TYPE
          }],
          isMovingSticker: c.stillMoving
        } : {
          ...o,
          canvasStickers: [...o.canvasStickers.slice(0, -1), {
            ...t,
            transformation: {
              cos: s * c.transform.cos - n * c.transform.sin,
              sin: n * c.transform.cos + s * c.transform.sin,
              scale: Math.min(_ * c.transform.scale, textSettings.MAX_SCALE)
            },
            type: textSettings.PLACED_TYPE
          }],
          isMovingSticker: c.stillMoving
        }
      }
      return o;
    case CreateStoryActionType.CREATE_STORY_EDIT_DRAWING:
      return {
        ...o,
        isEditingDrawing: !0
      };
    case CreateStoryActionType.CREATE_STORY_START_DRAWING:
      return {
        ...o,
        activeDrawing: {
          ...o.activeDrawing,
          startDrawIndex: [...o.activeDrawing.startDrawIndex, c.startIndex]
        }
      };
    case CreateStoryActionType.CREATE_STORY_DRAW_LINE:
      return {
        ...o,
        activeDrawing: {
          ...o.activeDrawing,
          linesArray: [...o.activeDrawing.linesArray, c.line]
        }
      };
    case CreateStoryActionType.CREATE_STORY_CHANGE_DRAW_COLOR:
      return {
        ...o,
        activeDrawing: {
          ...o.activeDrawing,
          activeColor: c.color
        },
      };
    case CreateStoryActionType.CREATE_STORY_UNDO_DRAWING:
      return o.activeDrawing.startDrawIndex.length > 0 ? {
        ...o,
        activeDrawing: {
          ...o.activeDrawing,
          linesArray: o.activeDrawing.linesArray.slice(0, o.activeDrawing.startDrawIndex[o.activeDrawing.startDrawIndex.length - 1]),
          startDrawIndex: o.activeDrawing.startDrawIndex.slice(0, o.activeDrawing.startDrawIndex.length - 1)
        }
      } : o;
    case CreateStoryActionType.CREATE_STORY_SAVE_DRAWING:
      return {
        ...o,
        isEditingDrawing: !1,
      };
    case CreateStoryActionType.CREATE_STORY_CHANGE_ACTIVE_BRUSH:
      return {
        ...o,
        activeBrush: c.brush
      };
    case CreateStoryActionType.CREATE_STORY_CHANGE_BRUSH_SIZE:
      return {
        ...o,
        brushSize: c.size
      };
    case CreateStoryActionType.CREATE_STORY_OPEN_STICKER_TOOL:
      return {
        ...o,
        stickerToolOpen: !0,
      };
    case CreateStoryActionType.CREATE_STORY_CLOSE_STICKER_TOOL:
      return {
        ...o,
        stickerToolOpen: !1
      };
    case CreateStoryActionType.CREATE_STORY_CHANGE_STICKER_ORDER:
      return {
        ...o,
        canvasStickers: [...o.canvasStickers.slice(0, c.bumpIndex).concat(o.canvasStickers.slice(c.bumpIndex + 1)), o.canvasStickers[c.bumpIndex]]
      };
    case CreateStoryActionType.CREATE_STORY_ADD_STICKER:
      return {
        ...o,
        canvasStickers: [...o.canvasStickers, {
          selectedVariant: 0,
          transformation: {
            ...n,
            scale: c.sticker[0].image_width_ratio
          },
          type: textSettings.PLACED_TYPE,
          variants: c.sticker,
          x: o.canvasDimensions.width / 2,
          y: o.canvasDimensions.height / 2
        }],
        stickerToolOpen: !1
      };
    case CreateStoryActionType.CREATE_STORY_DELETE_CANVAS_STICKER:
      return {
        ...o,
        canvasStickers: o.canvasStickers.slice(0, o.canvasStickers.length - 1),
        overTrash: !1
      };
    case CreateStoryActionType.CREATE_STORY_TOGGLE_STICKER_VARIANT:
      {
        const t = o.canvasStickers[o.canvasStickers.length - 1];
        return t.type === textSettings.PLACED_TYPE ? {
          ...o,
          canvasStickers: [...o.canvasStickers.slice(0, -1), {
            ...t,
            selectedVariant: (t.selectedVariant + 1) % t.variants.length
          }]
        } : (console.log('Toggle should only be called with stickers'),
          o)
      }
    case CreateStoryActionType.CREATE_STORY_CACHE_STICKER_RESULTS:
      return {
        ...o,
        stickerResults: c.results
      };
    case CreateStoryActionType.CREATE_STORY_RESET_STICKER_RESULTS:
      return {
        ...o,
        stickerResults: []
      };
    case CreateStoryActionType.CREATE_STORY_SAVE_STORY:
      return {
        ...o,
      };
    case CreateStoryActionType.CREATE_STORY_USE_MODAL:
      return {
        ...o,
        isUsingModal: c.isUsingModal
      };
    case 'NAVIGATION_LOCATION_LOADED':
      return c.path.startsWith('/create/story') ? o : {
        ...t,
        stickerResults: o.stickerResults
      };
    case CreateStoryActionType.CREATE_STORY_EXIT_ATTEMPT:
      return {
        ...o,
        isExitingStoryCreation: !0
      };
    case CreateStoryActionType.CREATE_STORY_EXIT:
    case CreateStoryActionType.CREATE_STORY_EXIT_CANCEL:
      
      return {
        ...t,
        isExitingStoryCreation: !1
      };
    default:
      return o
  }
};

export default o;