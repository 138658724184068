import React,{useRef,useMemo} from 'react';
import {Follow,FollowProps,UserCell,UserCellProps,RemoveFollower,RemoveFollowerProps} from 'containers/Commons'
import {RadioLikeButton,RadioLikeButtonProps,SVG} from 'components'
import styles from './styles.module.scss'

export type UserWithActionProps = UserCellProps & RadioLikeButtonProps & RemoveFollowerProps & FollowProps & {
  avatarSize?:number;
  containerClassname?: string;
  containerStyle?: React.CSSProperties;
  actionType?: 'FOLLOW' | 'REMOVEFOLLOWER' | 'CLOSE' | 'RADIO' | 'MOREOPTIONS';
  actionStyle?: React.CSSProperties;
  containerFlexDirection?: 'row' | 'column';
  actionGap?: number;
  onActionClick?: () => void;
};

const UserWithAction:React.FC<UserWithActionProps> = (props) => {
  const {avatarSize,actionGap = 12,containerFlexDirection="row",containerClassname='',actionStyle,user={},actionType,containerStyle,onActionClick,...others} = props;

  const isRow = containerFlexDirection == 'row';
  const radioRef = useRef(null);

  const getAction = useMemo(() => {
    const style = {marginLeft:isRow ? actionGap : 0,marginTop: !isRow ? actionGap : 0,...actionStyle}

    switch(actionType) {
      case 'FOLLOW' :
        return  <Follow user={user} {...others as FollowProps} style={style}/>;
      case 'REMOVEFOLLOWER' :
        return <RemoveFollower user={user} {...others as RemoveFollowerProps} style={style}/>;
      case 'CLOSE':
        return <SVG type="CLOSE" size={16} color="#8e8e8e" fill="#8e8e8e" style={{padding:8,marginLeft:8}} onClick={onActionClick}/>;
      case 'MOREOPTIONS':
        return <SVG type="MOREOPTIONS" size={24} color="#262626" fill="#262626" style={{padding:8}} onClick={onActionClick}  />
      case 'RADIO':
        return <RadioLikeButton {...others as RadioLikeButtonProps} ref={radioRef}/>
      default:
        return null;
    }
  },[props])

  return (
    <div className={`${styles['user-with-action']} ${containerClassname}`} style={{flexDirection:containerFlexDirection,...containerStyle}} onClick={() => { radioRef?.current?.onClick() }}>
      <UserCell size={avatarSize} style={{maxWidth:'100%'}} user={user} {...others as UserCellProps} />
      {getAction}
    </div>
  )
}

export default UserWithAction;