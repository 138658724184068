import React from 'react'
import ReactDOM from 'react-dom';
import useBodyOverflowHidden from 'hooks/useBodyOverflowHidden'
import styles from './modalContainer.module.scss'


export interface ModalProps {
  visible?: boolean;
  onCancel?: () => void;
  keepStructure?: boolean
}

interface ModalContainerProps extends ModalProps {
  onModalMaskClick?: () => void;
  children?: React.ReactNode;
}


const ModalContainer: React.FC<ModalContainerProps> = ({ visible,keepStructure = false, onModalMaskClick, children }) => {
  
  useBodyOverflowHidden(visible)
  
  const Container = (    
    <div className={styles['modal-container']}>
      <div className={styles['modal-container-mask']} onClick={onModalMaskClick} />
      {children}
    </div>
  )

  if(keepStructure) return visible ? Container : null;

  return visible ? ReactDOM.createPortal(Container, document.body) : null

}

export default React.memo(ModalContainer);
