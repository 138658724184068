import ReactDOM from 'react-dom';

export class BodyRender {
  div:any;
  constructor() {
    this.div = null;
  }
  render(children) {
    this.div = document.createElement('div');
    document.body.appendChild(this.div);
    ReactDOM.render(children ,this.div);
  }
  unmount() {
    try{
      ReactDOM.unmountComponentAtNode(this.div);
      document.body.removeChild(this.div);
    } catch {
      
    }
  }
}

export default new BodyRender();