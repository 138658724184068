import { FeedActionType } from 'constants/feedActionType'
import {getFeed} from 'services/commons/feed'

export const changFetchStatus = (isFetching) => {
  return {
    type: FeedActionType.FEED_CHANGE_FETCH_STATUS,
    isFetching
  }
}

export const changeAvatarLoadingStatus = (id,isAvatarLoading) => {
  return {
    type : FeedActionType.FEED_CHANGE_AVATAR_LOADING_STATUS,
    id,
    isAvatarLoading
  }
}

export const changeViewerSeenStory = (id,viewerSeenStory) => {
  return {
    type : FeedActionType.FEED_CHANGE_HAS_VIDEWED,
    id,viewerSeenStory
  }
}

export const changeLikeStatus = (id,liked) => {
  return {
    type: FeedActionType.FEED_CHANGE_LIKED,
    id,
    liked
  }
}


export const changeCommentLikeStatus = (postId,commentId,liked) => {
  return {
    type: FeedActionType.FEED_CHANGE_COMMENT_LIKED,
    postId,
    commentId,
    liked
  }
}

export const addComment = (comment,id) => {
  return {
    type: FeedActionType.FEED_ADD_COMMENT,
    comment,
    id
  }
}

export const changeSaveStatus = (id,saved) => {  
  return {
    type: FeedActionType.FEED_CHANGE_SAVE_STATUS,
    id,
    saved
  }
}

export const changeFollowStatus = (id,viewerFollowStatus) => {
  return {
    type: FeedActionType.FEED_CHANGE_FOLLOW_STATUS,
    id,
    viewerFollowStatus
  }
}

export const deleteFeed = (id) => {
  return {
    type : FeedActionType.FEED_DELETE,
    id
  }
}

export const setFeed = (feeds) => {
  return {
    type: FeedActionType.FEED_SET,
    feeds
  } 
}

export const appendFeed = (feeds) => {
  return {
    type: FeedActionType.FEED_APPEND,
    feeds    
  }
}


export const addFeed = (index,item) => {
  return {
    type : FeedActionType.FEED_ADD,
    item,
    index
  }
}

export const fetchFeeds = (isAppend,effectFetchStatus=false) => {
  return function(dispatch) {
    return new Promise<void>(async (resolve) => {
      effectFetchStatus && dispatch(changFetchStatus(true));
      const feeds:any = await getFeed();
      dispatch((isAppend ? appendFeed : setFeed)(feeds.map(item => {
        item.feedType = 'POST';
        return item;
      })));
      effectFetchStatus && dispatch(changFetchStatus(false));
      resolve();
    })
  }
}