import * as React from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { RouteProps } from 'routes'

interface RenderRoutesProps {
  routes: RouteProps[];
  authed: boolean;
  authPath: string;
  extraProps?: {};
  switchProps?: {};
}

const RenderRoutes: React.FC<RenderRoutesProps> = (_props) => {
  const { routes, authed, authPath, extraProps, switchProps } = _props;
  return (
    <Switch {...switchProps}>
      {routes.map((route) => {
        const { path, requiresAuth, exact, key, strict, navigationVisible, children, redirect,name } = route;
        const Compnent =  route.component;
        
        const isNoneRequiresAuth = requiresAuth === false;
        return <Route
          path={path}
          exact={exact}
          strict={strict}
          key={key}
          render={(props) => {
            console.log(!authed , isNoneRequiresAuth)
            if (authed || !authed && isNoneRequiresAuth) {
              if (children) {
                return (
                  <Compnent {...props} {...extraProps} name={name} navigationVisible={navigationVisible} pathname={path} redirect={redirect}>
                    <RenderRoutes {..._props} routes={children}  />
                  </Compnent>
                )
              }
              return <Compnent {...props} {...extraProps} name={name} navigationVisible={navigationVisible} />;
            }
            return <Redirect to={{ pathname: isNoneRequiresAuth ? path as string : authPath, state: { from: props.location } }} />
          }}
        />
      })}
    </Switch>
  )
}

export default React.memo(RenderRoutes)