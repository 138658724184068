import React from 'react'
import { ListModalProps } from 'components'
import {UserActionConfirm,User} from '../index'

import styles from './retirerFollowConfirm.module.scss'


export interface RetirerFollowConfirmProps extends ListModalProps {
  user?: User;
  onRetirerFollowClick?: (id?: string) => void;
}


const RetirerFollowConfirm: React.FC<RetirerFollowConfirmProps> = ({ user = {}, onRetirerFollowClick = () => { }, ...props }) => {
  const { username } = user;
  return (
    <UserActionConfirm sureText="停止关注" onSure={onRetirerFollowClick} user={user} {...props}>
      <div className={styles['retirer-follow-confirm']}>
        <div className={styles['follow-confirm-text']}>如果需要重新关注 {username}，你需要再次发送申请。</div>
      </div>
    </UserActionConfirm>
  );
}

export default RetirerFollowConfirm;
