import React,{useEffect, useState} from 'react';
import BodyRender from 'utils/BodyRender'
import styles from './styles.module.scss'
import {ScaleIn} from 'components'

interface ToastConentProps  {
  text: string;
  duration?: number;
  onClose?: () => void;
}

const ToastConent: React.FC<ToastConentProps> = ({text,duration,onClose}) => {
  const [visible,setVisible] = useState<boolean>(true);

  useEffect(() =>{
    const timer = setTimeout(() => {
      setVisible(false);
    },duration);
    return () => {
      clearTimeout(timer);
    }
  },[]);
  return (
    <ScaleIn className={`${styles['toast']} ${visible ? '' : styles['toast-hide']}`} type="TOASTLIKE" onTransitionEnd={onClose}>
      <div className={styles['toast-content']} >
        {text}
      </div>
    </ScaleIn>
  )
}

export default class Toast {
  static show({text,duration=2000,onClose=() => {}}:ToastConentProps) {
    const div = document.createElement('div');
    document.body.appendChild(div);
    const _onClose = () => {
      BodyRender.unmount();
      onClose();
    }
    BodyRender.render(<ToastConent text={text} duration={duration} onClose={_onClose}/>)
  }
}