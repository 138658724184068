import { CreatePostActionType } from 'constants/createPostActionType'
import {getLocations,getUsers} from 'services/containers/createPost'

export const setStyle = (style) => {
  return {
    type: CreatePostActionType.CREATE_POST_SET_STYLE,
    style
  }
}

export const clear = () => {
  return {
    type : CreatePostActionType.CREATE_POST_CLEAR
  }
}

export const setCaption = (caption) => {
  return {
    type : CreatePostActionType.CREATE_POST_SET_CAPTION,
    caption
  }
}

export const setCurrentUserTagPosition = (position) => {
  return {
    type : CreatePostActionType.CREATE_POST_SET_CURRENT_USER_TAG_POSITION,
    position
  }
}


export const setLocationTag = (location) => {
  return {
    type : CreatePostActionType.CREATE_POST_SET_LOCATION_TAG,
    location
  }
}

export const setLocations = (locations) => {
  return {
    type : CreatePostActionType.CREATE_POST_SET_LOCATIONS,
    locations
  }
}

export const changeFetchLocationStatus = (isFetchLocations) => {
  return {
    type : CreatePostActionType.CREATE_POST_CHANGE_FETEACH_LOCATIONS_STATUS,
    isFetchLocations
  }
}


export const fetchLocations = () => {
  return async (dispatch) => {
    dispatch(changeFetchLocationStatus(true))
    const locations = await getLocations();
    dispatch(setLocations(locations));
    dispatch(changeFetchLocationStatus(false))
  }
}


export const addTemporaryUserTag = (user) => {
  return {
    type : CreatePostActionType.CREATE_POST_ADD_TEMPORARY_USER_TAG,
    user
  }
}

export const setTemporaryUserTags = () => {
  return {
    type : CreatePostActionType.CREATE_POST_SET_TEMPORARY_USER_TAGS
  }
}


export const setUserTags = () => {
  return {
    type : CreatePostActionType.CREATE_POST_SET_USER_TAGS
  }
}

export const setTemporaryUserTagPosition = (id,position) => {
  return {
    type : CreatePostActionType.CREATE_POST_SET_TEMPORARY_USER_TAG_POSITION,
    position,
    id
  }
}

export const deleteTemporaryUserTag = (id) => {
  return {
    type : CreatePostActionType.CREATE_POST_DELETE_TEMPORARY_USER_TAG,
    id
  }
}


export const setUsers = (users) => {
  return {
    type : CreatePostActionType.CREATE_POST_SET_USERS,
    users
  }
}

export const changeFetchUsersStatus = (isFetchUsers) => {
  return {
    type : CreatePostActionType.CREATE_POST_CHANGE_FETEACH_USERS_STATUS,
    isFetchUsers
  }
}


export const fetchUsers = () => {
  return async (dispatch) => {
    dispatch(changeFetchUsersStatus(true))
    const users = await getUsers();
    dispatch(setUsers(users));
    dispatch(changeFetchUsersStatus(false))
  }
}


export const setAltText = (altText) => {
  return {
    type : CreatePostActionType.CREATE_POST_SET_ALT_TEXT,
    altText
  }
}