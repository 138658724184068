import React from 'react';
import styles from './styles.module.scss'

interface ScaleInProps  {
  children?: React.ReactNode;
  className?: string;
  type?: 'MODALLIKE' | 'TOASTLIKE';
}

const ScaleIn: React.FC<ScaleInProps & React.HTMLAttributes<HTMLDivElement>> = ({className,type='MODALLIKE',children,...props}) => {
  return (
    <div className={`${className} ${styles[`${type}-scale-in`]}`} {...props}>
      {children}
    </div>
  )
}

export default ScaleIn;