import request from 'utils/request'


export async function getLocations() {
  return request({
    url: '/locations'
  })
}


export async function getUsers() {
  return request({
    url: '/users'
  })
}