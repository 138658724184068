import React, { useState,useImperativeHandle,forwardRef } from 'react'
import {SpriteIcon} from 'components'
import styles from './styles.module.scss'


const FeedLike = ({},ref: React.RefObject<any>) => {
  const [showHeart,setShowHeart] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    showLike: () => {
      setShowHeart(false);
      Promise.resolve().then(() => {
        setShowHeart(true)
      })
    }
  }));

  return (
    <div className={styles['feed-like']}>
      {showHeart && <SpriteIcon className={styles['feed-like-heart']} size={128} type="880" ariaLabel="喜欢" backgroundPosition="0 0" backgroundSize="440px 411px"/>}
    </div>
  );
}

export default forwardRef(FeedLike);
