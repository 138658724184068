import React from 'react'
import UserSkeleton,{UserSkeletonProps} from './UserSkeleton'
import styles from './styles.module.scss'


interface UserSkeletonListProps extends UserSkeletonProps {
  visible?: boolean;
  num?: number;
}

const UserSkeletonList: React.FC<UserSkeletonListProps> = ({ visible, num = 8,...props }) => {
  return (
    <>
      {
        visible && [...new Array(num)].map(() => <UserSkeleton className={styles['user-skeleton-item']} {...props} />
        )
      }
    </>
  )
}


export default UserSkeletonList;