import React from 'react';
import styles from './spinner.module.scss'

const t = {
  "IGNORE": {
    "data-visualcompletion": "ignore"
  },
  "IGNORE_DYNAMIC": {
    "data-visualcompletion": "ignore-dynamic"
  },
  "IGNORE_LATE_MUTATION": {
    "data-visualcompletion": "ignore-late-mutation"
  },
  "LOADING_STATE": {
    "data-visualcompletion": "loading-state"
  },
  "MEDIA_VC_IMAGE": {
    "data-visualcompletion": "media-vc-image"
  },
  "CSS_IMG": {
    "data-visualcompletion": "css-img"
  }
}

const s = {
  small: {
    count: 8,
    length: 28,
    offset: 22,
    size: 18,
    thickness: 10
  },
  medium: {
    count: 12,
    length: 25,
    offset: 25,
    size: 32,
    thickness: 6
  },
  large: {
    count: 12,
    length: 25,
    offset: 25,
    size: 64,
    thickness: 6
  }
}
  , n = {
    __className: styles["position-absolute"]
  }

const getReact = (num, genReact) => {
  const res = [];
  for (let i = 0; i < num; i++) {
    res.push(genReact(i))
  }
  return res;
}


const Spinner = function ({ animated: l = !0, color: c = "dark", position: u = "static", progress: h = 1, size: f = "medium" }) {
  const y = s[f]
    , { count: _, length: k, offset: v, size: p, thickness: z } = y
    , E = Math.round(_ * h)
    , b = 'light' === c ? '#fafafa' : '#555555';
  return React.createElement('div', Object.assign({}, t.LOADING_STATE, {
    className: 'absolute' === u ? n : void 0,
    style: {
      height: p,
      justifyContent: "center",
      width: p
    }
  }), React.createElement("svg", {
    "aria-label": '加载中',
    className: `${l ? "" : styles["none-animate"]} ${8 === _ ? styles["small-size"] : ""} ${12 === _ ? styles["spinning"] : ""}`,
    viewBox: "0 0 100 100"
  }, getReact(E, t => React.createElement("rect", {
    fill: b,
    height: z,
    key: t,
    opacity: l ? t / _ : 1,
    rx: z / 2,
    ry: z / 2,
    transform: `rotate(${360 * (t - _ / 4) / _} 50 50)`,
    width: k,
    x: 50 - z / 2 + v,
    y: 50 - z / 2
  }))))
}


export default Spinner;