import {withAsyncRouter} from 'hocs'

const Home = withAsyncRouter(() => import('containers/Home'));
const Comments = withAsyncRouter(() => import('containers/Comments'));
const Story = withAsyncRouter(() => import('containers/Story'));
const Activity = withAsyncRouter(() => import('containers/Activity'));
const Suggested = withAsyncRouter(() => import('containers/Suggested'));
const Profile = withAsyncRouter(() => import('containers/Profile'));
const PostDetail = withAsyncRouter(() => import('containers/PostDetail'));
const Following = withAsyncRouter(() => import('containers/Following'));
const Followers = withAsyncRouter(() => import('containers/Followers'));

const AccountsEdit = withAsyncRouter(() => import('containers/Accounts/AccountsEdit'));
const PasswordChange = withAsyncRouter(() => import('containers/Accounts/PasswordChange'));
const OneTap = withAsyncRouter(() => import('containers/Accounts/OneTap'));
const PrivacyAndSecurity = withAsyncRouter(() => import('containers/Accounts/PrivacyAndSecurity'));

const Signup = withAsyncRouter(() => import('containers/Signup'));
const SignupBy = withAsyncRouter(() => import('containers/Signup/SignupBy'));
const SignupBirthday = withAsyncRouter(() => import('containers/Signup/Birthday'));
const SignupConfirm = withAsyncRouter(() => import('containers/Signup/Confirm'));
const SignupName = withAsyncRouter(() => import('containers/Signup/Name'));
const SignupUsername = withAsyncRouter(() => import('containers/Signup/username'));
const Signin = withAsyncRouter(() => import('containers/Signin'));

const DirectInbox = withAsyncRouter(() => import('containers/Direct/Inbox'));
const DirectNew = withAsyncRouter(() => import('containers/Direct/New'));
const DirectChat = withAsyncRouter(() => import('containers/Direct/Chat'));

const Explore = withAsyncRouter(() => import('containers/Explore'));
const ExploreTags = withAsyncRouter(() => import('containers/Explore/Tags'));
const ExploreLocations = withAsyncRouter(() => import('containers/Explore/Locations'));
const ExploreFeed = withAsyncRouter(() => import('containers/Explore/ExploreFeed'));

const CreatePost  = withAsyncRouter(() => import('containers/CreatePost'));
const CreatePostStyle = withAsyncRouter(() => import('containers/CreatePost/Style'));
const CreatePostDetails = withAsyncRouter(() => import('containers/CreatePost/Details'));
const CreatePostAdvancedSettings = withAsyncRouter(() => import('containers/CreatePost/AdvancedSettings'));
const CreatePostAltText = withAsyncRouter(() => import('containers/CreatePost/AltText'));
const CreatePostAddLocation = withAsyncRouter(() => import('containers/CreatePost/AddLocation'));
const CreatePostTag = withAsyncRouter(() => import('containers/CreatePost/Tag'));

const LikedBy = withAsyncRouter(() => import('containers/LikedBy'));

const CreateStory = withAsyncRouter(() => import('containers/CreateStory'));

const Guide = withAsyncRouter(() => import('containers/Guide'));

const EmptyLayout = withAsyncRouter(() => import('containers/Commons/EmptyLayout'));

const NotFound = withAsyncRouter(() => import('containers/NotFound'));

const Nametag = withAsyncRouter(() => import('containers/Nametag'));

const PushSettings = withAsyncRouter(() => import('containers/PushSettings'));


export interface RouteProps {
  component?: any;
  exact?: boolean;
  path: string | string[];
  name: string;
  requiresAuth?: boolean;
  key?: string;
  strict?: boolean;
  navigationVisible?: boolean;
  children?: RouteProps[];
  redirect?: string;
}

export const routes: RouteProps[] = [
  {
    path: '/',
    exact: true,
    component: Home,
    name: 'Home',
    navigationVisible: true
  },
  {
    path: '/comments/:shortCode',
    exact: true,
    component: Comments,
    name: 'Comments',
    navigationVisible: true
  },
  {
    path: '/stories/:id',
    exact: true,
    component: Story,
    name: 'Story'
  },
  {
    path: '/activity',
    exact: true,
    component: Activity,
    name: 'Activity',
    navigationVisible: true
  },
  {
    path: '/nametag',
    exact: true,
    component: Nametag,
    name: 'Nametag',
    navigationVisible: true
  },
  {
    path: '/suggested',
    exact: true,
    component: Suggested,
    name: 'Suggested',
    navigationVisible: true
  },
  {
    path: '/postDetail/:shortCode',
    exact: true,
    component: PostDetail,
    name: 'PostDetail',
    navigationVisible: true
  },
  {
    path: '/guide/:shortCode',
    exact: true,
    component: Guide,
    name: 'Guide',
    navigationVisible: true
  },
  {
    path: '/:fullname/likedBy',
    exact: true,
    component: LikedBy,
    name: 'LikedBy',
    navigationVisible: true
  },
  {
    path: '/pushSettings',
    exact: true,
    component: PushSettings,
    name: 'PushSettings',
    navigationVisible: true
  },
  {
    path: ['/:fullname/tagFollowing','/:fullname/following'],
    exact: true,
    component: Following,
    name: 'Following',
    navigationVisible: true
  },
  {
    path: ['/:fullname/followers','/:fullname/followers/(mutualOnly|mutuaFirst)'],
    exact: true,
    component: Followers,
    name: 'Followers',
    navigationVisible: true
  },
  {
    path: '/accounts',
    component: EmptyLayout,
    redirect: '/accounts/edit',
    name: 'Accounts',
    children : [
      {
        path :'/accounts/oneTap',
        name : 'OneTap',
        component: OneTap,
        requiresAuth: false,
      },
      {
        path :'/accounts/edit',
        name : 'AccountsEdit',
        component: AccountsEdit,
        navigationVisible: true
      },
      {
        path: '/accounts/passwordChange',
        name: 'PasswordChange',
        component: PasswordChange,
        navigationVisible: true
      },
      {
        path: '/accounts/privacyAndSecurity',
        name :'PrivacyAndSecurity',
        component: PrivacyAndSecurity
      }
    ],
  }, 
  {
    path: '/createStory',
    exact: true,
    component: CreateStory,
    name: 'CreateStory',
  },
  {
    path: '/createPost',
    name: 'CreatePost',
    component: CreatePost,
    redirect: '/createPost/style',
    children : [
      {
        path :'/createPost/style',
        name : 'CreatePostStyle',
        component: CreatePostStyle
      },
      {
        path :'/createPost/details',
        name : 'CreatePostdetails',
        component: CreatePostDetails
      },
      {
        path :'/createPost/advancedSettings',
        name : 'CreatePostAdvancedSettings',
        component: CreatePostAdvancedSettings,
      },
      {
        path :'/createPost/altText',
        name : 'CreatePostAltText',
        component: CreatePostAltText,
      },
      {
        path :'/createPost/addLocation',
        name : 'CreatePostAddLocation',
        component: CreatePostAddLocation,
      },
      {
        path :'/createPost/tag',
        name : 'CreatePostTag',
        component: CreatePostTag,
      }
    ]
  },
  {
    path: '/signup',
    name: 'Signup',
    component : Signup,
    redirect: '/signup/phone',
    requiresAuth: false,
    children: [
      {
        path: ['/signup/phone','/signup/email'],
        name : 'SignupBy',
        component : SignupBy,
        requiresAuth: false,
      },
      {
        path: '/signup/birthday',
        name : 'SignupBirthday',
        component : SignupBirthday,
        requiresAuth: false,
      },
      {
        path: '/signup/confirm',
        name : 'SignupConfirm',
        component : SignupConfirm,
        requiresAuth: false,
      },
      {
        path: '/signup/name',
        name : 'SignupName',
        component : SignupName,
        requiresAuth: false,
      },
      {
        path: '/signup/username',
        name : 'SignupUsername',
        component : SignupUsername,
        requiresAuth: false,
      }
    ]
  },
  {
    path: '/signin',
    exact: true,
    component: Signin,
    name: 'Signin',
    requiresAuth: false
  },
  {
    path:'/direct',
    name: 'Direct',
    component: EmptyLayout,
    redirect: '/direct/inbox',
    children: [
      {
        path: '/direct/inbox',
        name : 'Direct',
        component : DirectInbox,
      },
      {
        path: '/direct/new',
        name: 'DirectNew',
        component: DirectNew
      },
      {
        path:'/direct/chat/:id',
        name: 'DirectChat',
        component: DirectChat
      }
    ]
  },
  {
    path: '/explore',
    component: EmptyLayout,
    name: 'Explore',
    redirect: '/explore/search',
    children: [
      {
        path: '/explore/search',
        name : 'Explore',
        component : Explore,
        navigationVisible: true,
      }, 
      {
        path: '/explore/tags/:tagName',
        name : 'ExploreTags',
        component : ExploreTags,  
        navigationVisible: true,      
      },
      {
        path: '/explore/locations/:locationName',
        name : 'ExploreLocations',
        component : ExploreLocations,  
        navigationVisible: true,      
      },
      {
        path: '/explore/feed/:id',
        name: 'ExploreFeed',
        component : ExploreFeed,  
        navigationVisible: true, 
      }     
    ]
  },
  {
    path : '/notFound',
    exact: true,
    component: NotFound,
    name: 'notFound',
    navigationVisible: true    
  },
  {
    path: ['/:fullname','/:fullname/feed','/:fullname/guides','/:fullname/channel','/:fullname/tagged','/:fullname/saved'],
    exact: true,
    component: Profile,
    name: 'Profile',
    navigationVisible: true
  },
]

