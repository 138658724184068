import React from 'react'
import styles from './styles.module.scss'
import {Avatar,ListModal,ListModalProps} from 'components'
import {User} from '../index'

export interface UserActionConfirmProps extends ListModalProps {
  sureText?: string;
  onSure?: (id?:string) => void;
  user?: User;
}


const UserActionConfirm: React.FC<UserActionConfirmProps> = ({ sureText,user , onSure = () => { }, onCancel,children, ...props }) => {
  const { username, avatar,id } = user;
  return (
    <ListModal onCancel={onCancel} {...props}>
      <ListModal.Item className={styles['user-action-confirm']}>
        <div className={styles['action-confirm-avatar']}>
          <Avatar alt={`${username}的头像`} size={90} src={avatar} />
        </div>
        {children}
      </ListModal.Item>
      <ListModal.Item type="WARNING" onClick={() => { onCancel(); onSure(id) }}>{sureText}</ListModal.Item>
    </ListModal>
  );
}

export default UserActionConfirm;
