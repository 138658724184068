import FilterBuilder from './FilterBuilder'
import shaderMap from './shaderMap'
import textureMap from './textureMap'


const getThumbnail = (name) => {
  return require(`./images/thumbnail/${name}.jpg`)
}


export const FILTERS = {
  normal: (new FilterBuilder).setName('Normal').setShader(shaderMap.normal).setThumbnail(getThumbnail('normal'),getThumbnail('normal-2x')),
  clarendon: (new FilterBuilder).setName('Clarendon').setShader(shaderMap.clarendon).setThumbnail(getThumbnail('clarendon'),getThumbnail('clarendon-2x')).addTexture('map', textureMap[3]).addTexture('map2', textureMap[4]),
  gingham: (new FilterBuilder).setName('Gingham').setShader(shaderMap.gingham).setThumbnail(getThumbnail('gingham'),getThumbnail('gingham-2x')).addTexture('map', textureMap[6]).addTexture('mapLgg', textureMap[7]),
  moon: (new FilterBuilder).setName('Moon').setShader(shaderMap.moon).setThumbnail(getThumbnail('moon'),getThumbnail('moon-2x')).addTexture('map1', textureMap[9]).addTexture('map2', textureMap[10]),
  lark: (new FilterBuilder).setName('Lark').setShader(shaderMap.lark).setThumbnail(getThumbnail('lark'),getThumbnail('lark-2x')).addTexture('map', textureMap[12]),
  reyes: (new FilterBuilder).setName('Reyes').setShader(shaderMap.reyes).setThumbnail(getThumbnail('reyes'),getThumbnail('reyes-2x')).addTexture('map', textureMap[14]),
  juno: (new FilterBuilder).setName('Juno').setShader(shaderMap.reyes).setThumbnail(getThumbnail('juno'),getThumbnail('juno-2x')).addTexture('map', textureMap[15]),
  slumber: (new FilterBuilder).setName('Slumber').setShader(shaderMap.slumber).setThumbnail(getThumbnail('slumber'),getThumbnail('slumber-2x')).addTexture('map', textureMap[17]),
  crema: (new FilterBuilder).setName('Crema').setShader(shaderMap.reyes).setThumbnail(getThumbnail('crema'),getThumbnail('crema-2x')).addTexture('map', textureMap[18]),
  ludwig: (new FilterBuilder).setName('Ludwig').setShader(shaderMap.ludwig).setThumbnail(getThumbnail('ludwig'),getThumbnail('ludwig-2x')).addTexture('map', textureMap[20]),
  aden: (new FilterBuilder).setName('Aden').setShader(shaderMap.reyes).setThumbnail(getThumbnail('aden'),getThumbnail('aden-2x')).addTexture('map', textureMap[21]),
  perpetua: (new FilterBuilder).setName('Perpetua').setShader(shaderMap.perpetua).setThumbnail(getThumbnail('perpetua'),getThumbnail('perpetua-2x')).addTexture('map', textureMap[23]).addTexture('overlay_map', textureMap[24])
}

export const FILTERS_NAMES = Object.keys(FILTERS)



export default class Filter {
  $Filters1: boolean
  $Filters2: {}
  $Filters3: any
  constructor(t) {
    this.$Filters1 = !1;
    this.$Filters2 = {};
    this.$Filters3 = t;
  }
  initializeFilter(s) {
    
    this.$Filters2.hasOwnProperty(s) || (this.$Filters2[s] = FILTERS[s].createFilter(this.$Filters3))
  }
  getFilterProgram(s) {
    if (!FILTERS.hasOwnProperty(s))
      throw new Error(`Filter ${s} not found`);
    return this.initializeFilter(s),
      this.$Filters2[s]
  }
}

