import React,{useState,useEffect,useImperativeHandle,forwardRef} from 'react'
import {Button,SVG} from 'components'
import styles from './radioLikeButton.module.scss'


export interface RadioLikeButtonProps {
  checked?: boolean;
  onChange?: (checked:boolean) => void;
}

const RadioLikeButton = ({checked,onChange = () => {}}:RadioLikeButtonProps,ref: React.RefObject<any>) => {
  const [ownChecked,setOwnChecked] = useState<boolean>(checked);

  const onClick = (e) => {
    e && e.stopPropagation();
    setOwnChecked(!ownChecked);
  }

  useImperativeHandle(ref,() => ({
    onClick
  }))

  useEffect(() => {
    setOwnChecked(checked);
  },[checked])

  useEffect(() => {
    onChange(ownChecked);
  },[ownChecked])


  return (
    <Button onClick={onClick} type="TEXT" className={styles['radio-like-button']}>
      {ownChecked ? <SVG type="CHECKED" size={24} color="#0095f6" fill="#0095f6"  /> : <SVG size={24} tag="span" type="TOGGLESELECTION" />}
    </Button>
  )
}

export default forwardRef(RadioLikeButton);