//获取当前用户
import { UserActionType } from 'constants/userActionType'

const initialState = {
  user:{},
  isFetching: true
}

const userReducer = (state=initialState,action) => {
  switch(action.type) {
    case UserActionType.USER_SET :
      return {
        ...state,
        user :action.user,
      }
    case UserActionType.USER_CHANGE_FETCH_STATUS: 
      return {
        ...state,
        isFetching: action.isFetching
      }
    case UserActionType.USER_SET_AVATAR:
      return {
        ...state,
        user: {
          ...state.user,
          avatar: action.avatar
        }
      }
    default:
      return state;
  }
}



export default userReducer;