import createStory from './createStoryReducer'
import storyTray from './storyTrayReducer'
import user from './userReducer'
import feed from './feedReducer'
import report from './reportReducer'
import postDetail from './postDetailReducer'
import directSearchUser from './directSearchUserReducer'
import comments from './commentsReducer'
import suggested from './suggested'
import search from './searchReducer'
import explore from './exploreReducer'
import exploreTags from './exploreTagsReducer'
import exploreLocations from './exploreLocationsReducer'
import story from './storyReducer'
import activity from './activityReducer'
import followers from './followersReducer'
import following from './followingReducer'
import likedBy from './likedByReducer'
import tagFollowing from './tagFollowingReducer'
import profile from './profileReducer'
import guide from './guideReducer'
import createPost from './createPostReducer'
import direct from './directReducer'
import auth from './authReducer'
import signup from './signupReducer'
import emptyFeed from './emptyFeedReducer'

import {combineReducers} from 'redux';


const rootReducer = combineReducers({
  activity,
  createStory,
  storyTray,
  user,
  feed,
  report,
  postDetail,
  directSearchUser,
  comments,
  suggested,
  search,
  explore,
  exploreTags,
  exploreLocations,
  story,
  followers,
  following,
  tagFollowing,
  profile,
  likedBy,
  guide,
  createPost,
  direct,
  auth,
  signup,
  emptyFeed
})


export default rootReducer;
