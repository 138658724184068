import React from 'react'
import {BubbleType} from './Bubble'
import {FeedProps} from './Feed'
import {Avatar} from 'components'
import {User,Time} from '../index'
import Typing from './Typing'
import styles from './message.module.scss'

export type MessageId = string|number;

export interface MessageProps {
  id?: MessageId;
  type?: 'TYPING' | BubbleType;
  content?: string | FeedProps;
  createdAt?: number;
  owner?: User;
  position?: 'LEFT' | 'RIGHT' | 'CENTER';
  hasTime?: boolean;
  likedUsers?: User[];
  hasAvatar?: boolean;
  renderMessageContent?: (message:MessageProps) => any
}

const Message:React.FC<MessageProps> = (props) => { 
  const {renderMessageContent=() => null,likedUsers=[],hasAvatar=true,...others} = props;
  const {type,owner={},hasTime,createdAt,position="LEFT"}  = others;
  
  const hasLikedUser = likedUsers.length > 0;
  const typing = type == 'TYPING';


  return (
    <div className={styles['message']} >
      {hasTime && createdAt && (
        <div className={styles['message-time']}>
          <Time className={styles['message-time-text']} date={createdAt} displayType="NORMAL" />
        </div>
      )}
      <div className={`${styles['message-content']} ${styles[position]} ${typing ? styles['message-content-typing'] : ''}`} >
        {position != 'RIGHT' && <div className={styles['message-content-avatar']} style={{marginBottom:hasLikedUser ? 20 : 8}}>
          {hasAvatar && <Avatar size={24}  src={owner.avatar} alt={`${owner.username}的头像`}/>}
        </div>}
        {typing ? <Typing /> : renderMessageContent({...props})}
      </div>
      {}
    </div>
  )
}


export default Message;