import { ActivityActionType } from 'constants/activityActionType'


const initialState = {
  activity: [],
  suggested: [],
  isFetching: true
}

const activityReducer = (state=initialState,action) => {
  switch(action.type) {
    case ActivityActionType.ACTIVITY_CHANGE_AVATAR_LOADING_STATUS:
      return {
        ...state,
        suggested: state.suggested.map(item => {
          if(item.user.id == action.id) {
            item.user.isAvatarLoading = action.isAvatarLoading;
          }
          return item;
        })
      }
    case ActivityActionType.ACTIVITY_CHANGE_VIEWER_SEEN_STORY:
      return {
        ...state,
        suggested: state.suggested.map(item => {
          if(item.user.id == action.id) {
            item.viewerSeenStory = action.viewerSeenStory;
          }
          return item;
        })        
      }
    case ActivityActionType.ACTIVITY_SET:
      return {
        ...state,
        activity:action.activity,
        suggested: action.suggested
      }
    case ActivityActionType.ACTIVITY_CHANGE_FETCH_STATUS :
      return {
        ...state,
        isFetching: action.isFetching
      }
    case ActivityActionType.ACTIVITY_CHANGE_ACTIVITY_FOLLOW :
      return {
        ...state,
        activity : state.activity.map(item => {
          if(item.user.id == action.id) {
            item.viewerFollowStatus = action.viewerFollowStatus;
          }
          return item;
        })
      }
    case ActivityActionType.ACTIVITY_CHANGE_SUGGESTED_FOLLOW :
      
      return {
        ...state,
        suggested : state.suggested.map(item => {
          if(item.user.id == action.id) {
            item.viewerFollowStatus = action.viewerFollowStatus;
          }
          return item;
        })
      }
    default:
      return state;
  }
}



export default activityReducer;