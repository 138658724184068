import React,{useEffect, useState} from 'react'
import {Button} from 'components';
import LinkifyText from '../LinkifyText'


interface SeeMoreProps {
  text:string;
  limit?:number;
  more?: React.ReactNode;
}

const SeeMore:React.FC<SeeMoreProps> = ({limit=120,text='',more='更多'}) => {
  const[showMore,setShowMore] = useState<boolean>(false);
  const[content,setContent] = useState<string>('');
  
  const onShowMoreClick = () => {
    setShowMore(false);
    setContent(text);
  }

  useEffect(() => {
    if(text.length > limit) {
      setContent(text.slice(0,120));
      setShowMore(true);
    } else {
      setContent(text);
      setShowMore(false);
    }
  },[])


  return (
    <>
      <LinkifyText style={{wordBreak:'break-word'}} text={content} />
      {showMore &&
        <>
        ...&nbsp;<Button onClick={onShowMoreClick} type="TEXT" style={{ fontWeight: 400,padding:0,display: 'inline-flex',color: '#8e8e8e'}}>{more}</Button>
      </>}
    </>
  )
}

export default SeeMore;