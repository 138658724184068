
import {uid} from 'uid'
import {User,CommentItem} from 'containers/Commons'

export const getComment = (owner:User,text):CommentItem => {
  return {
    owner,
    text,
    id:uid(),
    viewerHasLiked:false,
    createdAt:Date.now(),
    comments:[],
    likeByCount:0,
    count:0,
    viewerSeenStory:false
  }
}