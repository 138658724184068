import { SearchActionType } from 'constants/searchActionType'


const initialState = {
  isFetching :false,
  result: [],
}

const searchReducer = (state=initialState,action) => {
  switch(action.type) {
    case SearchActionType.SEARCH_SET :
      return {
        ...state,
        result:action.result
      }
    case SearchActionType.SEARCH_CHANGE_FETCH_STATUS :
      return {
        ...state,
        isFetching: action.isFetching
      }
    default:
      return state;
  }
}



export default searchReducer;