import React, { useState,useRef, useEffect } from 'react'
import {SVG} from 'components'
import {UserTag,User} from '../index'
import useClickOutside from 'use-click-outside';
import { useInView } from 'react-intersection-observer';
import { useDoubleTap } from 'use-double-tap';
import useViewport from 'hooks/useViewport'
import styles from './PostTags.module.scss'


export interface MediaTaggedUser {
  user?: User;
  x?: number;
  y?:number;
}

interface PostTagsProps {
  mediaTaggedUsers?:MediaTaggedUser[];
  hasTag?: boolean;
  onIconClick?: () => void;
  isTv?: boolean;
}

const PostTags: React.FC<PostTagsProps> = ({mediaTaggedUsers=[],isTv,hasTag,onIconClick=() => {}}) => {
  const [accountActive, setAccountActive] = useState<boolean>(false);
  const [iconActive, setIconActive] = useState<boolean>(true);
  const [iconClicked,setIconClicked] = useState<boolean>(false);
  const [imgDimensions,setImgDimensions] = useState({});
  const {viewportWidth} = useViewport();

  const el = useRef<HTMLDivElement>(null);
  const { ref, inView } = useInView({
    threshold: 0.5,
  })
  
  const onSvgClick = (e?) => {
    e && e.stopPropagation()
    setAccountActive(!accountActive);
    setIconClicked(true);
    setIconActive(true);
    onIconClick();
  }

  useClickOutside(el,() => {
    if (!accountActive && iconClicked && inView && hasTag) {
      setIconActive(false);
    }
  })

  useEffect(() =>{
    !inView && setIconActive(true);
  },[inView])


  useEffect(() => {
    if(el.current) {
      const {width,height} = el.current.getBoundingClientRect();
      setImgDimensions({
        width,height
      })
    }
  },[viewportWidth,accountActive])

  const bind = useDoubleTap(() => {},300,{
    onSingleTap() {
      onSvgClick();
    }
  })

  const tags = mediaTaggedUsers.map(({x,y,user}) => {
    return {
      position:[x,y],
      username:user.username
    }
  })


  return (
    <>
      <div ref={ref} className={`${styles['post-tags']} ${accountActive ? styles['tags-account-active'] : ''} ${!hasTag ? styles['none-pointer'] : ''}`}>
        <div {...bind} ref={el} className={styles['post-tags-content']}>
          <SVG onClick={onSvgClick} className={`${styles['tags-content-icon']} ${iconActive ? styles['content-icon-active'] : ''} ${!hasTag ? styles['auto-pointer'] : ''}`} type={isTv? 'TV' : 'TAGS'} size={12} fill="#ffffff" />
          {hasTag && tags.map(tag => <UserTag imgDimensions={imgDimensions} tag={tag} hasDelete={false} className={styles['tags-content-account']}/>)}
        </div>
      </div>
    </>
  )
}


export default PostTags;