import React from 'react'
import ModalContainer,{ModalProps} from './ModalContainer'
import styles from './listModal.module.scss'
import {ScaleIn,Button,ButtonProps} from 'components'

export interface ListModalProps extends ModalProps {
  style?: React.CSSProperties;
  children?: React.ReactNode;
  cancleText?: string;
  hasCancle?: boolean;
}

export interface ListModalItemProps extends ButtonProps {}

export const ListModalItem:React.FC<ListModalItemProps> = ({className,type='DEFAULT',children,...props}) => {
  return (
    <Button type="TEXT" className={`${styles['list-modal-item']} ${!children ? styles['not-for-btn'] : ''} ${styles[`modal-item-${type}`]} ${className}`} {...props}>{children}</Button>
  )
}


class ListModal extends React.Component<ListModalProps,{}>  {
  static Item = ListModalItem;
  
  render() {
    const {onCancel,style,children,cancleText="取消",hasCancle=true,...props} = this.props;
    return (
      <ModalContainer  onModalMaskClick={onCancel} {...props}>
        <div className={styles['list-modal']} style={style}>
          <ScaleIn className={styles['list-modal-content']}>
            {children}
            {hasCancle && <Button type="TEXT" className={styles['list-modal-item']} onClick={onCancel}>{cancleText}</Button>}
          </ScaleIn>
        </div>
      </ModalContainer>
    ) 
  }

}

export default ListModal;
