import React, { useState,createContext,useContext,useEffect } from 'react'
import {Button} from 'components'
import styles from './styles.module.scss'

export const TabsContext = createContext(null);

interface TabBarProps {
  onClick?: (isActive:boolean) => void;
  keys?: string;
  children?: React.ReactNode;
  className?: string;
}

const TabBar: React.FC<TabBarProps> = ({ onClick = () => { },keys,children,className}) => {
  const {activeKey, setActiveKey,hasActiveStyle,borderType} = useContext(TabsContext);
  const isActive = activeKey == keys;

  return (
  <Button type="TEXT" onClick={() => { onClick(isActive);setActiveKey(keys) }} className={`${styles['tab-bar']} ${isActive && hasActiveStyle ? styles[`bar-${borderType}-active`] : ''} ${className}`}>{children}</Button>
  )
}

interface TabPanelProps {
  onTabBarClick?:(isActive:boolean) => void;
  keys?: string;
  children?: React.ReactNode;
  tabBarClassName?: string;
  tab?: React.ReactNode;
  renderer?: (isActive:boolean) => React.ReactNode;
  className?: string;
}

export const TabPanel:React.FC<TabPanelProps> = ({onTabBarClick,keys,renderer,children,tabBarClassName,className,tab}) => {
  const {activeKey,setTabBarsMap,hideStyleType} = useContext(TabsContext);
  const isDisplayHide = hideStyleType == 'DISPLAY';
  const isActived = activeKey == keys;

  useEffect(() => {
    setTabBarsMap(tabBarsMap => {
      tabBarsMap[keys] = <TabBar onClick={onTabBarClick} keys={keys} className={tabBarClassName}>{tab}</TabBar>;
      return {...tabBarsMap};
    })
    return () => {
      setTabBarsMap(tabBarsMap => {
        tabBarsMap[keys] = null;
        return {...tabBarsMap};
      })      
    }
  },[tab])

  const child = renderer ? renderer(isActived) : children;

  return(
    <>
      { isDisplayHide ? <div className={`${styles['tab-panel']} ${className} ${isActived ? '': styles['tab-panel-hide']}`}>{child}</div> : (isActived && child) }
    </>
  )
}

interface TabsProps {
  children: React.ReactNode;
  onTabChange?: (key: string) => void;
  defaultActiveKey?: string;
  hasActiveStyle?: boolean;
  className?: string;
  borderType?: 'TOP' | 'BOTTOM' | 'NONE';
  hideStyleType?:'DEFAULT' | 'DISPLAY'
}


const Tabs: React.FC<TabsProps> = ({className,children,onTabChange = () => {},borderType,hideStyleType = 'DEFAULT' ,defaultActiveKey,hasActiveStyle}) => {
  const [activeKey, setActiveKey] = useState<string>(defaultActiveKey);
  const [tabBarsMap,setTabBarsMap] = useState({});

  useEffect(() => {
    setActiveKey(defaultActiveKey);
  },[defaultActiveKey])

  useEffect(() => {
    onTabChange(activeKey)
  },[activeKey])

  return (
    <TabsContext.Provider value={{activeKey,hideStyleType,setActiveKey,hasActiveStyle,borderType,setTabBarsMap}}>
      <div className={`${styles['tabs']}  ${styles[`tabs-border-${borderType}`]} ${className}`}>{Object.keys(tabBarsMap).map(key => tabBarsMap[key])}</div>
      {children}
    </TabsContext.Provider>
  )
}

export default Tabs;