import React,{useEffect,useState} from 'react'
import {SpriteIcon} from 'components'
import styles from './notification.module.scss'

export interface Notifications {
  type : 'POST' | 'HEART' | 'USER',
  num: number;
}

type NotificationProps = {
  showDelayTime?: number;
  hideDelayTime?: number;
  NotificationStack?: Notifications[]
}


const Notification:React.FC<NotificationProps> = ({showDelayTime=1500,NotificationStack,hideDelayTime = 10000}) => {
  const [isHide,setIsHide] = useState<boolean>(true);
  const backgroundPositionMap = {
    'POST' : '-486px -262px',
    'HEART' :'-484px -374px',
    'USER' : '-169px -451px'
  }
  const backgroundSizeMap = {
    'POST' : '510px 477px',
    'HEART' : '544px 520px',
    'USER' : '544px 520px'
  }

  useEffect(() => {
    let showTimer,hideTimer;
    showTimer = setTimeout(() => {
      setIsHide(false);
      hideTimer = setTimeout(() => {
        setIsHide(true);
      },hideDelayTime);
    },showDelayTime);
    
    return () => {
      clearTimeout(showTimer);
      clearTimeout(hideTimer);
    }
  },[])

  return(
    <div className={`${styles['notification']} ${isHide ? styles['notification-hide'] : ''}`}>
      <div className={styles['notification-content']}>
        {NotificationStack.map(({type,num},i) => {
          return (
            <>
              <SpriteIcon size={16} backgroundPosition={backgroundPositionMap[type]} backgroundSize={backgroundSizeMap[type]} ariaLabel="" type={type == 'POST' ? '1020' : '1088'} style={{ marginRight: 4 ,marginLeft:i > 0 ? 12 :0}} />
              {num}
            </>
          )
        })}
      </div>
      <div className={styles['notification-arrow']} />
    </div>
  )
}

export default Notification;

