import React from 'react'
import MessageContainer,{MessageContainerProps} from './MessageContainer'
import Composer,{ComposerProps} from './Composer'


interface ChatProps {
  className?: string;
  hasComposer?: boolean;
}

const Chat:React.FC<MessageContainerProps&ComposerProps & ChatProps> = ({className,children,hasComposer=true,...props}) => {
  return (
    <div className={className}>
      <MessageContainer {...props as MessageContainerProps}/>
      {!hasComposer && children}
      {hasComposer && <Composer {...props as ComposerProps}/>}
    </div>
  )
}

export default Chat;