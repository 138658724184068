import { SuggestedActionType } from 'constants/suggestedActionType'

const initialState = {
  isFetching: true,
  suggested: []
}

const SuggestedUsersInTrayReducer = (state = initialState, action) => {
  switch (action.type) {
    case SuggestedActionType.SUGGESTED_CHANGE_AVATAR_LOADING_STATUS:
      return {
        ...state,
        suggested: state.suggested.map(item => {
          if(item.user.id == action.id) {
            item.user.isAvatarLoading = action.isAvatarLoading;
          }
          return item;
        })
      }
    case SuggestedActionType.SUGGESTED_CHANGE_VIEWER_SEEN_STORY:
      return {
        ...state,
        suggested: state.suggested.map(item => {
          if(item.user.id == action.id) {
            item.viewerSeenStory = action.viewerSeenStory;
          }
          return item;
        })        
      }
    case SuggestedActionType.SUGGESTED_CHANGE_FETCH_STATUS:
      return {
        ...state,
        isFetching: action.isFetching
      }
    case SuggestedActionType.SUGGESTED_APPEND:
      return {
        ...state,
        suggested: [...state.suggested,...action.suggested]
      }
    case SuggestedActionType.SUGGESTED_SET:
      return {
        ...state,
        suggested: action.suggested
      }
    case SuggestedActionType.SUGGESTED_DELETE: 
      return {
        ...state,
        suggested: state.suggested.filter(item => item.user.id != action.id)
      }
    case SuggestedActionType.SUGGESTED_CHANGE_FOLLOW_STATUS:
      state.suggested.forEach(item => {
        if(item.user.id == action.id) {
          item.viewerFollowStatus = action.viewerFollowStatus;
        }
      })
      return {
        ...state,
        suggested: [...state.suggested]
      }
    default:
      return state;
  }
}


export default SuggestedUsersInTrayReducer;