import { CreateStoryActionType } from 'constants/createStoryActionType'


export const editDrawing = () => {
  return {
    type: CreateStoryActionType.CREATE_STORY_EDIT_DRAWING
  }
}

export const enterAddText = function () {
  return {
    type: CreateStoryActionType.CREATE_STORY_ENTER_ADD_TEXT
  }
}


export const setImageData = (imageData) => {
  return {
    type : CreateStoryActionType.CREATE_STORY_SET_IMAGE_DATA,
    imageData
  }
}

export const enterEditText = function () {
  return {
    type: CreateStoryActionType.CREATE_STORY_ENTER_EDIT_TEXT
  }
}

export const openStickerTool = function () {
  return {
    type: CreateStoryActionType.CREATE_STORY_OPEN_STICKER_TOOL
  }
}


export const saveStory = function () {
  return {
    type: CreateStoryActionType.CREATE_STORY_SAVE_STORY
  }
}


//设置画布的大小
export const setCanvasDimensions = function (t, n) {
  return {
    type: CreateStoryActionType.CREATE_STORY_SET_CANVAS_DIMENSIONS,
    canvasWidth: t,
    canvasHeight: n
  }
}

//上传动态
export const uploadStory = function (n, o) {
  return {

  }
}

export const useModal = function (t) {
  return {
    type: CreateStoryActionType.CREATE_STORY_USE_MODAL,
    isUsingModal: t
  }
}

//添加贴图
export const addSticker = function (t) {
  return {
    type: CreateStoryActionType.CREATE_STORY_ADD_STICKER,
    sticker: t
  }
}

//关闭贴图选项
export const closeStickerTool = function () {
  return {
    type: CreateStoryActionType.CREATE_STORY_CLOSE_STICKER_TOOL
  }
}

//修改贴图顺序
export const changeStickerOrder = function (t) {
  return {
    type: CreateStoryActionType.CREATE_STORY_CHANGE_STICKER_ORDER,
    bumpIndex: t
  }
}

//删除贴图
export const deleteCanvasSticker = function () {
  return {
    type: CreateStoryActionType.CREATE_STORY_DELETE_CANVAS_STICKER,
  }
}


//移动贴图
export const moveCanvasSticker = function (t, n, o) {
  return {
    type: CreateStoryActionType.CREATE_STORY_MOVE_CANVAS_STICKER,
    deltaX: t,
    deltaY: n,
    overTrash: o
  }
}

//
export const toggleStickerVariant = function () {
  return {
    type: CreateStoryActionType.CREATE_STORY_TOGGLE_STICKER_VARIANT
  }
}

//移动贴纸
export const transformCanvasSticker = function (t, n) {
  return {
    type: CreateStoryActionType.CREATE_STORY_TRANSFORM_CANVAS_STICKER,
    stillMoving: t,
    transform: n
  }
}

//改变刷子的大小
export const changeBrushSize = function (t) {
  return {
    type: CreateStoryActionType.CREATE_STORY_CHANGE_BRUSH_SIZE,
    size: t
  }
}

//划线
export const drawLineAction = function (t) {
  return {
    type: CreateStoryActionType.CREATE_STORY_DRAW_LINE,
    line: t
  }
}

//保存划线
export const saveDrawing = function (t) {
  return {
    type: CreateStoryActionType.CREATE_STORY_SAVE_DRAWING,
    timeSpent: t
  }
}


//开始划线
export const startDrawing = function (t) {
  return {
    type: CreateStoryActionType.CREATE_STORY_START_DRAWING,
    startIndex: t
  }
}

//撤销划线
export const undoDrawing = function () {
  return {
    type: CreateStoryActionType.CREATE_STORY_UNDO_DRAWING
  }
}

//修改划线颜色
export const changeDrawColor = function (t) {
  return {
    type: CreateStoryActionType.CREATE_STORY_CHANGE_DRAW_COLOR,
    color: t
  }
}

//改变刷子类型
export const changeBrush = function (t) {
  return {
    type: CreateStoryActionType.CREATE_STORY_CHANGE_ACTIVE_BRUSH,
    brush: t
  }
}

//修改当前激活的文本
export const changeActiveText = function (t, n, o) {
  return {
    type: CreateStoryActionType.CREATE_STORY_CHANGE_TEXT,
    rawText: t,
    width: n,
    height: o
  }
}

//保存文本
export const saveText = function (t) {
  return {
    type: CreateStoryActionType.CREATE_STORY_SAVE_TEXT,
    renderText: t,
  }
}

//修改文本颜色
export const changeColor = function (t) {
  return {
    type: CreateStoryActionType.CREATE_STORY_CHANGE_COLOR,
    color: t
  }
}

//退出编辑
export const exitStoryCreation = function () {
  return {
    type: CreateStoryActionType.CREATE_STORY_EXIT
  }
}