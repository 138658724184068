import React,{useEffect} from 'react'
import {Button,SpriteIcon} from 'components'
import GridLines from './GridLines'
import styles from './createStyle.module.scss'



interface CreateStyleProps {
  className?: string;
  height?: number;
  hideGridLines?: boolean;
  mirrored?:boolean;
  rotationAngle?: number;
  src?: string;
  width?: number;
  disableAnimation?: boolean;
  onExpandTap?: (e:React.UIEvent) => void;
  onRotateTap?: (e:React.UIEvent) => void;
  onTouchStart?: (e:React.TouchEvent) => void;
  onTouchEnd?: (e:React.TouchEvent) => void;
  onTouchMove?: (e:React.TouchEvent) => void;
  scaleFactor?: number;
  offsetLeft?: number;
  offsetTop?: number;
}


const CreateStyle: React.FC<CreateStyleProps> = ({ onTouchEnd,onTouchMove,onTouchStart,onExpandTap,onRotateTap,scaleFactor,offsetLeft,offsetTop,disableAnimation,mirrored,rotationAngle,hideGridLines = false,  src, width, height }) => {

  const $ = scaleFactor * height / width;
  let S = -1 * scaleFactor * (offsetLeft / width);
  scaleFactor < 1 && (S = (1 - scaleFactor) / 2);
  let _ = -1 * $ * (offsetTop / height);
  $ < 1 && (_ = (1 - $) / 2);
  const k = Math.min.bind(Math, 1),
    x = k(scaleFactor) / k($),
    j = [];
  let transformOrigin;

  if (1 === x) {
    const t = Math.min(width, height);
    transformOrigin = `${(offsetLeft + t / 2) / width * 100}% ${(offsetTop + t / 2) / height * 100}%`
  }
  if(mirrored) {
    j.push('scaleX(-1)');
  }
  j.push(`rotate(${rotationAngle}deg)`);
  const transform = j.join(' ');
  let transition;
  !0 !== disableAnimation && (transition = 'all 0.2s ease');

  const style:React.CSSProperties = {
    backgroundImage :`url(${src})`,
    height: `${100 * $}%`,
    left: `${100 * S}%`,
    top: `${100 * _}%`,
    width: `${100 * scaleFactor}%`,
    transition,
    transform,
    transformOrigin
  }


  return (
    <div className={styles['create-style']}>
      <div className={styles['create-style-content']} >
        <div className={styles['style-content-img']} style={style} onTouchEnd={onTouchEnd} onTouchStart={onTouchStart} onTouchMove={onTouchMove} />
        <GridLines visible={(!hideGridLines )} />
        {width !== height && <Button className={styles['content-img-expand']} onClick={onExpandTap}>
          <SpriteIcon type="178" size={30} backgroundSize="89px 89px" backgroundPosition="-59px 0" />
        </Button>}
        <Button className={styles['content-img-rotate']} onClick={onRotateTap}>
          <SpriteIcon type="178" size={30} backgroundSize="89px 89px" backgroundPosition="0 -59px" />
        </Button>
      </div>
    </div>
  )
}


export default CreateStyle;