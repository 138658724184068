import { StoryTrayActionType } from 'constants/storyTrayActionType'


const initialState = {
  storyTraies :[],
  isFetching: true,
}

const storyTrayReducer = (state=initialState,action) => {
  switch(action.type) {
    case StoryTrayActionType.STORY_TRAY_CHANGE_FETCH_STATUS :
      return {
        ...state,
        isFetching:action.isFetching
      }
    case StoryTrayActionType.STORY_TRAY_SET :
      return {
        ...state,
        storyTraies:action.storyTraies
      }
    case StoryTrayActionType.STORY_TARY_CHANGE_HAS_VIEWED:
      return {
        ...state,
        storyTraies : state.storyTraies.map(item => {
          item.user.id == action.id && (item.viewerSeenStory = action.viewerSeenStory);
          return item;
        })
      }
    case StoryTrayActionType.STORY_TRAY_CHANGE_AVATAR_LOADING_STATUS:
      return {
        ...state,
        storyTraies : state.storyTraies.map(item => {
          item.user.id == action.id && (item.user.isAvatarLoading = action.isAvatarLoading);
          return item;
        })
      }
    default:
      return state;
  }
}



export default storyTrayReducer;