export enum CreateStoryActionType {
  CREATE_STORY_ADD_STICKER="CREATE_STORY_ADD_STICKER",
  CREATE_STORY_CACHE_STICKER_RESULTS="CREATE_STORY_CACHE_STICKER_RESULTS",
  CREATE_STORY_CHANGE_ACTIVE_BRUSH="CREATE_STORY_CHANGE_ACTIVE_BRUSH",
  CREATE_STORY_CHANGE_BRUSH_SIZE="CREATE_STORY_CHANGE_BRUSH_SIZE",
  CREATE_STORY_CHANGE_COLOR="CREATE_STORY_CHANGE_COLOR",
  CREATE_STORY_CHANGE_DRAW_COLOR="CREATE_STORY_CHANGE_DRAW_COLOR",
  CREATE_STORY_CHANGE_STICKER_ORDER="CREATE_STORY_CHANGE_STICKER_ORDER",
  CREATE_STORY_CHANGE_TEXT="CREATE_STORY_CHANGE_TEXT",
  CREATE_STORY_CLOSE_STICKER_TOOL="CREATE_STORY_CLOSE_STICKER_TOOL",
  CREATE_STORY_DELETE_CANVAS_STICKER="CREATE_STORY_DELETE_CANVAS_STICKER",
  CREATE_STORY_DRAW_LINE="CREATE_STORY_DRAW_LINE",
  CREATE_STORY_EDIT_DRAWING="CREATE_STORY_EDIT_DRAWING",
  CREATE_STORY_ENTER_ADD_TEXT="CREATE_STORY_ENTER_ADD_TEXT",
  CREATE_STORY_ENTER_EDIT_TEXT="CREATE_STORY_ENTER_EDIT_TEXT",
  CREATE_STORY_EXIT="CREATE_STORY_EXIT",
  CREATE_STORY_EXIT_ATTEMPT="CREATE_STORY_EXIT_ATTEMPT",
  CREATE_STORY_EXIT_CANCEL="CREATE_STORY_EXIT_CANCEL",
  CREATE_STORY_IMAGE_PROCESSED="CREATE_STORY_IMAGE_PROCESSED",
  CREATE_STORY_MOVE_CANVAS_STICKER="CREATE_STORY_MOVE_CANVAS_STICKER",
  CREATE_STORY_OPEN_STICKER_TOOL="CREATE_STORY_OPEN_STICKER_TOOL",
  CREATE_STORY_RESET_STICKER_RESULTS="CREATE_STORY_RESET_STICKER_RESULTS",
  CREATE_STORY_SAVE_DRAWING="CREATE_STORY_SAVE_DRAWING",
  CREATE_STORY_SAVE_STORY="CREATE_STORY_SAVE_STORY",
  CREATE_STORY_SAVE_TEXT="CREATE_STORY_SAVE_TEXT",
  CREATE_STORY_SESSION_STARTED="CREATE_STORY_SESSION_STARTED",
  CREATE_STORY_SET_CANVAS_DIMENSIONS="CREATE_STORY_SET_CANVAS_DIMENSIONS",
  CREATE_STORY_START_DRAWING="CREATE_STORY_START_DRAWING",
  CREATE_STORY_TOGGLE_STICKER_VARIANT="CREATE_STORY_TOGGLE_STICKER_VARIANT",
  CREATE_STORY_TRANSFORM_CANVAS_STICKER="CREATE_STORY_TRANSFORM_CANVAS_STICKER",
  CREATE_STORY_UNDO_DRAWING="CREATE_STORY_UNDO_DRAWING",
  CREATE_STORY_UPLOAD_FAILED="CREATE_STORY_UPLOAD_FAILED",
  CREATE_STORY_UPLOAD_REQUESTED="CREATE_STORY_UPLOAD_REQUESTED",
  CREATE_STORY_UPLOAD_STAGGED="CREATE_STORY_UPLOAD_STAGGED",
  CREATE_STORY_UPLOAD_SUCCEEDED="CREATE_STORY_UPLOAD_SUCCEEDED",
  CREATE_STORY_USE_MODAL="CREATE_STORY_USE_MODAL",
  CREATE_STORY_VIDEO_COVER_PHOTO_UPDATED="CREATE_STORY_VIDEO_COVER_PHOTO_UPDATED",
  CREATE_STORY_VIDEO_PROCESSED="CREATE_STORY_VIDEO_PROCESSED",
  CREATE_STORY_VIDEO_UPLOAD_REQUESTED="CREATE_STORY_VIDEO_UPLOAD_REQUESTED",
  CREATE_STORY_SET_IMAGE_DATA = 'CREATE_STORY_SET_IMAGE_DATA'
}