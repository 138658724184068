import Filter from '../ImageFilter/Filter'
import {invalidateCacheIfNeeded} from '../ImageFilter/FilterProgram'

class t extends Error {
  type: string;
  constructor() {
    super('GL context could not be created');
    this.type = 'gl_unsupported_error';
  }
}
export default class FilterGLContext {
  $FilterGLContext1: number;
  $FilterGLContext2: boolean;
  $FilterGLContext3: boolean;
  $FilterGLContext4: any;
  $FilterGLContext5: any;
  $FilterGLContext6: any;
  $FilterGLContext9: any;

  constructor(s) {
    this.$FilterGLContext1 = 0;
    this.$FilterGLContext2 = !1;
    this.$FilterGLContext3 = !1;
    const n = {
      preserveDrawingBuffer: !0,
      antialias: !1
    }
    const o = s.getContext('webgl', n);
    s.getContext('experimental-webgl', n);
    if (!o)
      throw new t;
    this.$FilterGLContext4 = o;
    this.$FilterGLContext5 = new Filter(this.$FilterGLContext4)
  }
  init() {
    const t = this.$FilterGLContext4;
    t.clearColor(1, 1, 1, 0);
    t.clear(t.COLOR_BUFFER_BIT | t.DEPTH_BUFFER_BIT);
  }
  destroy() {
    invalidateCacheIfNeeded(null);
    this.stopDraw();
  }
  setFilter(t) {
    if(this.$FilterGLContext6) {
      this.$FilterGLContext7().unuse();
    }
    this.$FilterGLContext6 = t;
    const s = this.$FilterGLContext7();
    s.use();
    const n = s.loadAssets();
    return this.$FilterGLContext8(),
      n
  }
  setImage(t) {
    const s = this.$FilterGLContext7().setImage(t);
    return this.$FilterGLContext8(),
      s
  }
  setParam(t, s) {
    this.$FilterGLContext7().setParam(t, s);
    this.$FilterGLContext8();
  }
  setOnDraw(t) {
    this.$FilterGLContext9 = t
  }
  draw() {
    const t = ++this.$FilterGLContext1
      , s = this.$FilterGLContext4;
    return this.$FilterGLContext7().waitForAssetLoad().then(() => {
      if(t === this.$FilterGLContext1) {
        s.viewport(0, 0, s.drawingBufferWidth, s.drawingBufferHeight);
        s.clear(s.COLOR_BUFFER_BIT | s.DEPTH_BUFFER_BIT);
        s.drawArrays(s.TRIANGLES, 0, 6);
        this.$FilterGLContext10();
        this.$FilterGLContext9 && this.$FilterGLContext9();
      }
    }).catch(t => {
      console.log(`[filters] Could not draw: ${t && t.message}`);
      this.$FilterGLContext3 = !0;
    })
  }
  startDraw() {
    return this.$FilterGLContext2 = !0,
      this.$FilterGLContext3 = !1,
      this.$FilterGLContext8()
  }
  stopDraw() {
    this.$FilterGLContext2 = !1
  }
  $FilterGLContext7() {
    return this.$FilterGLContext5.getFilterProgram(this.$FilterGLContext6)
  }
  $FilterGLContext10() {
    const t = this.$FilterGLContext4
      , s = t.getError();
    if (s === t.NO_ERROR)
      return;
    const n = {
      [t.INVALID_ENUM]: 'INVALID_ENUM',
      [t.INVALID_VALUE]: 'INVALID_VALUE',
      [t.INVALID_OPERATION]: 'INVALID_OPERATION',
      [t.INVALID_FRAMEBUFFER_OPERATION]: 'INVALID_FRAMEBUFFER_OPERATION',
      [t.OUT_OF_MEMORY]: 'OUT_OF_MEMORY',
      [t.CONTEXT_LOST_WEBGL]: 'CONTEXT_LOST_WEBGL'
    }
      , o = this.$FilterGLContext6 || 'none';
    console.log(`[filters] ${n[s]} error in GL draw: ${o}`)
  }
  $FilterGLContext8() {
    return this.$FilterGLContext2 ? this.draw() : Promise.resolve()
  }
}