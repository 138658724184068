import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import isYesterday from 'dayjs/plugin/isYesterday'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/zh-cn'

dayjs.extend(isToday)
dayjs.extend(isYesterday)
dayjs.extend(localizedFormat)

export function timeDiff(time : number|Date|string,hasWeek?: boolean) :string {
  const now = dayjs(Date.now());
  const before = dayjs(time);
  const minDiff = now.diff(before,'minute');
  const hourDiff = now.diff(before,'hour');
  const dayDiff = now.diff(before,'day');
  const yearDiff = now.diff(before,'year');
  const weekDiff = now.diff(before,'week');
  
  if(minDiff < 10) {
    return '刚刚';
  }
  if(minDiff >= 10 && minDiff < 60) {
    return `${minDiff}分钟`
  }

  if(hourDiff >= 1 && hourDiff < 24) {
    return `${hourDiff}小时`
  }

  if(dayDiff >= 1 && dayDiff < 7) {
    return `${dayDiff}天`;
  }

  if(weekDiff >= 1 && hasWeek) {
    return `${weekDiff}周`;
  }

  return `${yearDiff != 0 ? `${before.year()}年` : ''}${before.month() + 1}月${before.date()}日`
}


export function normal(time : number|Date|string) :string {
  const date = dayjs(time).locale('zh-cn');
  if(date.isToday()) {
    return date.format('Ah:mm');
  }
  if(date.isYesterday()) {
    return `昨天 ${date.format('Ah:mm')}`
  }

  return date.format('YYYY年M月D日 Ah:mm')
}