import React from 'react'
import ReportTags,{ReportTagsProps} from './ReportTags'
import {Spin} from 'components'



export interface ReportActionProps extends ReportTagsProps{
  isFetchReport?: boolean;
}


const ReportAction: React.FC<ReportActionProps> = ({isFetchReport,...props}) => {

  return (
    <>
      <Spin boxHeight={150} type="SPIN" size={30} visible={isFetchReport} />
      {!isFetchReport && <ReportTags {...props} />}
    </>
  )
}


export default ReportAction;