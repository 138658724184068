import {useEffect} from 'react'

let isHidden = false;

const useBodyOverflowHidden = (visible?:boolean) => {
  useEffect(() => {
    if(isHidden) return;
    if(visible) {
      document.body.style.overflow = 'hidden';
      isHidden = true;
    }
    return () => {
      isHidden = false;
      document.body.style.overflow = '';
    }
  },[visible]);
}

export default useBodyOverflowHidden;