import React,{useState} from 'react'
import {ModalProps,ListModalItem,ConfirmModal} from 'components'
import BlockActionFeedback from './BlockActionFeedback'


interface BlockActionConfirmProps extends ModalProps {
  username?: string;
  onActionClick?: () => void;
  blocked?: boolean;
  hasFeedback?: boolean;
}

const BlockActionConfirm:React.FC<BlockActionConfirmProps> = ({blocked,username,hasFeedback,onActionClick=() => {},onCancel,...props}) => {

  const [feedbackVisible,setFeedbackVisible] = useState<Boolean>(false);

  const title = `${!blocked ? '' : '取消'}屏蔽${username}?`
  const text = !blocked ? '对方将无法在 Instagram 找到你的个人主页、帖子或快拍。对方不会收到自己被拉黑的通知。' : '对方可以在 Instagram 看到你的帖子并关注你了。对方不会收到自己已被取消屏蔽的通知。'

  return (
    <>
      <ConfirmModal title={title} text={text} onCancel={onCancel} {...props}>
        <ListModalItem type="PRIMARY" onClick={() => { onCancel();onActionClick();hasFeedback && setFeedbackVisible(true)  }}>{!blocked ? '拉黑' : '取消拉黑'}</ListModalItem>
      </ConfirmModal>
      <BlockActionFeedback username={username} blocked={blocked} visible={Boolean(hasFeedback && feedbackVisible)} onCancel={() => { setFeedbackVisible(false) }} />
    </>
  )
}


export default BlockActionConfirm;