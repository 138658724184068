import React,{useCallback, useEffect,useState} from 'react'
import {BodyRender} from 'utils/BodyRender'
import {Button} from 'components'
import styles from './styles.module.scss'


export interface SnackBarProps {
  text: React.ReactNode;
  duration?: number;
  bottom?: number;
  onClose?: () => void;
  onActionClick?: () => void;
  actionText?: React.ReactNode;
}

export const SnackBar:React.FC<SnackBarProps> = ({text, duration=8000,onClose = () => {},actionText = '重试',bottom = 44,onActionClick}) => {
  const [visible,setVisible] = useState<boolean>(false);

  useEffect(() => {
    Promise.resolve().then(() => {
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
      },duration);
    })
  },[])

  const onTransitionEnd = useCallback(() => {
    Promise.resolve().then(() => {
      !visible && onClose();
    })
  },[visible]);

  return (
    <div className={styles['snack-bar']} style={{bottom}}>
      <div className={`${styles['snack-bar-content']} ${visible ? styles['bar-content-visible'] : ''}`} onTransitionEnd={onTransitionEnd}>
        <p className={styles['bar-content-text']}>{text}</p>
        {onActionClick && <Button type="TEXT" style={{padding:0,color:'#0095f6'}} onClick={onActionClick}>{actionText}</Button>}
      </div>
    </div>
  );

}

export class Message {
  static show(props:SnackBarProps | string) {
    const _props = typeof props === 'string' ? {text:props,onClose(){}} : props;
    const bodyRender = new BodyRender();
    bodyRender.render(<SnackBar {..._props} onClose={() => {
      _props.onClose && _props.onClose();
      bodyRender.unmount();
    }}/>)
  }
}

export default SnackBar;
