import { FeedActionType } from 'constants/feedActionType'

const initialState = {
  isFetching: true,
  feeds: []
}

const feedReducer = (state = initialState, action) => {
  switch (action.type) {
    case FeedActionType.FEED_CHANGE_AVATAR_LOADING_STATUS:
      return {
        ...state,
        feeds: state.feeds.map(item => {
          if(item.feedType == 'POST' && item.owner.id == action.id) {
            item.owner.isAvatarLoading = action.isAvatarLoading;
          }
          return item;
        })
      }
    case FeedActionType.FEED_CHANGE_HAS_VIDEWED:
      return {
        ...state,
        feeds: state.feeds.map(item => {
          if(item.feedType == 'POST' && item.owner.id == action.id) {
            item.viewerSeenStory = action.viewerSeenStory;
          }
          return item;
        })
      }
    case FeedActionType.FEED_CHANGE_FETCH_STATUS:
      return {
        ...state,
        isFetching: action.isFetching
      }
    case FeedActionType.FEED_APPEND:
      return {
        ...state,
        feeds: [...state.feeds,...action.feeds]
      }
    case FeedActionType.FEED_SET:
      return {
        ...state,
        feeds: action.feeds
      }
    case FeedActionType.FEED_ADD:
      const items = Array.isArray(action.item) ? action.item : [action.item];
      state.feeds.splice(action.index, 0, ...items);
      return {
        ...state,
        feeds: [...state.feeds]
      }
    case FeedActionType.FEED_CHANGE_FOLLOW_STATUS:
      return {
        ...state,
        feeds: state.feeds.map(item => {
          if(item.feedType == 'POST' && item.owner.id == action.id) {
            item.viewerFollowStatus = action.viewerFollowStatus;
          }
          return item;
        })
      }
    case FeedActionType.FEED_CHANGE_COMMENT_LIKED: 
      return {
        ...state,
        feeds: state.feeds.map(item => {
          if(item.id == action.postId) {
            item.mediaPreviewComment.comments = item.mediaPreviewComment.comments.map(it => {
              if(it.id == action.commentId) {
                it.viewerHasLiked = action.liked;
              }
              return it;
            })
          }
          return item;
        })
      }
    case FeedActionType.FEED_DELETE:
      return {
        ...state,
        feeds: state.feeds.filter(item => item.id != action.id)
      }
    case FeedActionType.FEED_ADD_COMMENT: 
      return {
        ...state,
        feeds: state.feeds.map(item => {
          if(item.id == action.id) {
            item.mediaPreviewComment.comments = [...item.mediaPreviewComment.comments,action.comment];
          }
          return item;
        })
      }
    case FeedActionType.FEED_CHANGE_LIKED:
      return {
        ...state,
        feeds: state.feeds.map(item => {
          if(item.id == action.id) {
            item.viewerHasLiked = action.liked;
          }
          return item;
        })
      }
    case FeedActionType.FEED_CHANGE_SAVE_STATUS:
      return {
        ...state,
        feeds: state.feeds.map(item => {
          if(item.id == action.id) {
            item.vierwerHasSaved = action.saved;
          }
          return item;
        })
      }
    default:
      return state;
  }
}


export default feedReducer;