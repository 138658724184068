import React, { useEffect, useState } from 'react'
import {Button} from 'components'
import styles from './labelInput.module.scss'


interface LabelInputProps  {
  value?: string;
  onChange?: (v: any) => void;
  type?: string;
  className?: string;
  errorText?: string;
  help?: string;
  prepend?: React.ReactNode;
  placeholderScale?: boolean;
  placeholder?: string;
}

const LabelInput: React.FC<LabelInputProps & React.InputHTMLAttributes<HTMLInputElement>> = ({ errorText, className, placeholder, children, value = '',help, onChange = () => { }, onFocus = () => { }, onBlur = () => { },placeholderScale = true, type,prepend, ...props }) => {
  const [inputValue, setInputValue] = useState<string>(value);
  const [focused, setFocused] = useState<boolean>(false);

  const _onChange = (e) => {
    const value = e.target.value;
    onChange(e);
    setInputValue(value);
  }

  useEffect(() => {
    setInputValue(value);
  }, [value])

  return (
    <div>
      <div className={`${styles['label-input']} ${focused ? styles['label-input-focus'] : ''} ${errorText ? styles['label-input-error'] : ''} ${className}`}>
        {prepend}
        <label className={styles['label-input-content']}>
          {placeholderScale && <span className={`${styles['input-content-placeholder']} ${inputValue.length ? styles['content-placeholder-active'] : ''}`}>{placeholder}</span>}
          <input onFocus={(e) => { setFocused(true); onFocus(e) }} onBlur={(e) => { setFocused(false); onBlur(e); }} className={`${styles['input-content-inner']} ${inputValue.length && placeholderScale ? styles['content-inner-active'] : ''}`} aria-label={placeholder} onChange={_onChange} placeholder={!placeholderScale ? placeholder :''}  type={type} value={inputValue} {...props} />
        </label>
        {children}
      </div>
      {help && <div className={styles['label-input-help']}>{help}</div>}
      {errorText && <div className={styles['label-input-error']}>{errorText}</div>}
    </div>
  )
}

export const PasswordInput: React.FC<LabelInputProps & React.InputHTMLAttributes<HTMLInputElement>> = ({...props}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  return (
    <LabelInput className={styles['name-btn']} type={showPassword ? 'text' : 'password'} placeholder="密码" {...props}>
      <Button type="TEXT" onClick={(e) => { e.preventDefault();setShowPassword(!showPassword) }}>{showPassword ? '隐藏' : '显示'}</Button>
    </LabelInput>
  )
}




export default LabelInput;