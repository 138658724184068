import React,{useState,useEffect,useMemo} from 'react'
import {throttle} from 'throttle-debounce'
import { connect } from 'react-redux';
import {Spin,Button} from 'components';
import {ShortenNumber,UserCell,User} from 'containers/Commons'
import * as searchAction from 'store/actions/searchAction'
import styles from './styles.module.scss'

export type SearchType = 'USER' | 'TAG' | 'LOCATION';

export interface TagItem {
  id?: string;
  name?: string;
  postCount?: number;
  profilePicUrl?: string;
}

export interface LocationItem {
  id?: string;
  name?: string;
  address?: string;
}

export interface SearchItem {
  id?: string;
  type?: SearchType;
  tag?: TagItem;
  location?: LocationItem;
  user?: User
}

interface TagProps extends TagItem {
  onClick?: () => void;
}

const Tag:React.FC<TagProps> = ({name,postCount,onClick}) => {
  return (
    <Button className={styles['tag']} type="TEXT" onClick={onClick}>
      <div className={styles['tag-name']}>#{name}</div>
      <div className={styles['tag-post-count']}><ShortenNumber value={postCount}/>&nbsp; 篇帖子</div>
    </Button>
  )
}


interface TypeaheadProps {
  inputString?: string;
  cursorIndex?: number;
  onSelected?: (pendingQueryStartIndex:number,text:string) => void;
  onSearch?: (type:SearchType) => void;
  isFetching?: boolean;
  result?:SearchItem[];
  visible?:boolean;
}


const Typeahead:React.FC<TypeaheadProps> = ({inputString,isFetching,cursorIndex,onSelected,onSearch,result,visible}) => {  
  const [pendingQueryStartIndex,setPendingQueryStartIndex] = useState<number>(null);
  const [searchResultVisible,setSearchResultVisible] = useState<boolean>(visible);
  const [searchType,setSeachType] = useState<SearchType>('USER');

  const _onSearch = useMemo(() => throttle(300,onSearch),[]);

  const mentionReg = /([@＠])/;
  const tagReg = /([#])/;
  const spaceReg = /\s/;
  const label = inputString.slice(cursorIndex - 1, cursorIndex);

  if(mentionReg.test(label) && pendingQueryStartIndex === null) {
    setPendingQueryStartIndex(cursorIndex); 
    setSeachType('USER');
  }
  
  if(tagReg.test(label) && pendingQueryStartIndex === null) {
    setPendingQueryStartIndex(cursorIndex); 
    setSeachType('TAG');
  }

  if((spaceReg.test(label) || !label) && pendingQueryStartIndex !== null) {
    setPendingQueryStartIndex(null);
  }

  useEffect(() => {
    if(pendingQueryStartIndex) {
      const str = inputString.slice(pendingQueryStartIndex, cursorIndex).trim();
      if(str.length) {
        setSearchResultVisible(true);
        _onSearch(searchType);
      } else {
        setSearchResultVisible(false);
      }
    } else {
      setSearchResultVisible(false);
    }
  },[pendingQueryStartIndex,cursorIndex])

  useEffect(() => {
    !visible && setPendingQueryStartIndex(null);
  },[visible])

  const getSearchItem = (item:SearchItem) => {
    switch(item.type) {
      case 'USER' :
        const user = item.user;
        return <UserCell gap={10} verifiedBadgeSize="SMALL" className={styles['search-result-user']} src={user.avatar} size={30} isVerified={user.isVerified} username={user.username} description={user.fullname} onClick={() => {onSelected(pendingQueryStartIndex,user.fullname);setSearchResultVisible(false) }} />
      case 'TAG':
        const tag = item.tag;
        return <Tag {...tag} onClick={() => { onSelected(pendingQueryStartIndex,tag.name);setSearchResultVisible(false) }}/>
      default:
        return null;

    }
  }

  return (
    <>
      {searchResultVisible && <div className={styles['search-result']}>
        {isFetching ? <Spin visible={true} boxHeight={200} size={32} />: result.map(item => getSearchItem(item))}
      </div>}
    </>
  )
}

export default connect((state:any) => {
  return {
    isFetching: state.search.isFetching,
    result:state.search.result
  }
},{
  onSearch: searchAction.search
})(Typeahead);