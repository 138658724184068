import { FollowerActionType } from 'constants/followersActionType'


const initialState = {
  followers: [],
  isFetching: true
}

const followersReducer = (state=initialState,action) => {
  switch(action.type) {
    case FollowerActionType.FOLLOWERS_SET:
      return {
        ...state,
        followers: action.followers
      }
    case FollowerActionType.FOLLOWERS_CHANGE_AVATAR_LOADING_STATUS:
      return {
        ...state,
        followers: state.followers.map(item => {
          if(item.user.id == action.id) {
            item.user.isAvatarLoading = action.isAvatarLoading;
          }
          return item;
        })
      }
    case FollowerActionType.FOLLOWERS_CHANGE_VIEWER_SEEN_STORY:
      return{
        ...state,
        followers: state.followers.map(item => {
          if(item.user.id == action.id) {
            item.viewerSeenStory = action.viewerSeenStory;
          }
          return item;
        })
      }
    case FollowerActionType.FOLLOWERS_CHANGE_FETCH_STATUS :
      return {
        ...state,
        isFetching: action.isFetching
      }
    case FollowerActionType.FOLLOWERS_APPEND : 
      return {
        ...state,
        followers : [...state.followers,...action.followers]
      }
    case FollowerActionType.FOLLOWERS_CHANGE_FOLLOW_STATUS :
      return {
        ...state,
        followers : state.followers.map(item => {
          if(item.user.id == action.id) {
            item.viewerFollowStatus = action.viewerFollowStatus;
          }
          return item;
        })
      }
    case FollowerActionType.FOLLOWERS_REMOVE :
      return {
        ...state,
        followers : state.followers.filter(item => item.user.id != action.id)
      }
    default:
      return state;
  }
}



export default followersReducer;