import React from 'react'
import {Button,Image} from 'components'
import styles from './creationFilterThumbnail.module.scss'


interface CreationFilterThumbnailProps {
  filterBuilder?: any;
  isSelected?: boolean;
  pixelRatio?: number;
  name?:string;
  onClick?: (e:any) => void;
}

const CreationFilterThumbnail:React.FC<CreationFilterThumbnailProps> = ({name,filterBuilder,isSelected,pixelRatio,onClick = () => {}}) => {

  const _onClick = () => {
    onClick(name);
  }

  return (
    <Button onClick={_onClick} type="TEXT" className={styles['creation-filter-thumbnail']}>
      <div className={`${styles['filter-thumbnail-title']} ${isSelected ? styles['thumbnail-title-selected'] : ''}`}>{filterBuilder.getName()}</div>
      <Image alt="" className={styles['filter-thumbnail-img']} src={filterBuilder.getThumbnail(pixelRatio)} />
    </Button>
  )
}


export default CreationFilterThumbnail;