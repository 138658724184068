import React, { useState } from 'react'
import {SVG,ConfirmModal,ListModalItem} from 'components'
import MoreOptions from './MoreOptions'
import {UserCell,User,Report,Share,UnfollowConfirm,FollowEvent,FollowUser,FollowInUsername} from '../index'
import styles from './postHeader.module.scss'


export interface HasSomeOption {
  hasVerified?: boolean;
  hasFollow?: boolean;
  hasOpenPostDetail?: boolean;
}

export interface PostHeaderProps extends FollowEvent,FollowUser,HasSomeOption {
  description: string,
  onOpenPostClick?: () => void;
  onTaggedUserClick?: () => void;
  onDirectClick?: () => void;
  hasTaggedUser?: boolean;
  isOwn?: boolean;
  onDeletePostClick?: () => void;
  onAvatarClick?: (user:User ) => void;
  sideAuthor?: User
}

const PostHeader: React.FC<PostHeaderProps> = ({isOwn,viewerSeenStory,hasOpenPostDetail,hasVerified,onChangeFollowStatus,user,description, viewerFollowStatus,hasFollow,onOpenPostClick,onTaggedUserClick,hasTaggedUser,onDirectClick,onDeletePostClick,onAvatarClick,sideAuthor}) => {
  const [moreOptionsModalVisible, setMoreOptionsVisible] = useState<boolean>(false);
  const [reportVisible, setReportVisible] = useState<boolean>(false);
  const [unfollowVisible, setUnfollowVisible] = useState<boolean>(false);
  const [shareVisible, setShareVisible] = useState<boolean>(false);
  const [deletePostConfirmVisible,setDeletePostConfirmVisible] = useState<boolean>(false);
  const {hasNewStory,isVerified,isAvatarLoading} = user;

  
  return (
    <>
      <header className={styles['post-header']}>
        <UserCell 
          flexDirection="row" 
          hasRing={sideAuthor ? false : hasNewStory} 
          usernameStyle={{fontWeight:600}} 
          onAvatarClick={sideAuthor ? null : onAvatarClick}
          isLoading={isAvatarLoading} 
          seenStory={viewerSeenStory}
          gap={15} 
          description={description} 
          size={sideAuthor ? 24 : 32} 
          user={sideAuthor ? [user,sideAuthor] : user}
          mutilUsername={true}
          avatarContainerSize={32}
          avatarOffset={8}
          isVerified={hasVerified && isVerified}
          verifiedBadgeSize="SMALL"
          usernameChild={ hasFollow && <FollowInUsername viewerFollowStatus={viewerFollowStatus} user={user} onChangeFollowStatus={onChangeFollowStatus}/>}/>
        <SVG 
          onClick={() => { setMoreOptionsVisible(true) }} 
          size={24}  
          type="MOREOPTIONS" 
          ariaLable="更多选项" 
          viewBox={24}
          className={styles['post-header-options']} 
          />
      </header>
      <MoreOptions 
        viewerFollowStatus={viewerFollowStatus}
        visible={moreOptionsModalVisible} 
        onCancel={() => { setMoreOptionsVisible(false) }} 
        onUnfollowClick={() => { setUnfollowVisible(true) }}
        onReportClick={() => { setReportVisible(true) }}
        onOpenPostClick={onOpenPostClick}
        hasOpenPostDetail={hasOpenPostDetail}
        onTaggedUserClick={onTaggedUserClick}
        hasTaggedUser={hasTaggedUser}
        isOwn={isOwn}
        onDeletePostClick={() => { setDeletePostConfirmVisible(true) }}
        onShareClick={() => {setShareVisible(true)}}
      />
      <ConfirmModal title="删除帖子" text="确认删除帖子？" 
        visible={deletePostConfirmVisible}  onCancel={() => { setDeletePostConfirmVisible(false) }}>
        <ListModalItem type="WARNING" onClick={() => { setDeletePostConfirmVisible(false);onDeletePostClick(); }}>删除</ListModalItem>
      </ConfirmModal>

      <UnfollowConfirm 
        user={user} 
        visible={unfollowVisible} 
        onCancel={() => { setUnfollowVisible(false) }} 
        onUnfollowClick={(id) => { onChangeFollowStatus(id,'UNFOLLOW') }}
      />
      <Share 
        onDirectClick={() => { setShareVisible(false);onDirectClick() }}
        visible={shareVisible} 
        onCancel={() => { setShareVisible(false) }} 
      />
      <Report 
        user={user}
        visible={reportVisible} 
        onClose={() => { setReportVisible(false) }}
      />
    </>
  );
}

export default PostHeader
