import React from 'react'
import { Link,useHistory } from 'react-router-dom';
import {SVG, SVGProps } from 'components'
import styles from './styles.module.scss'



interface NavigationProps extends SVGProps {
  linkTo?: string;
}

export const NavigationBack:React.FC<NavigationProps> = ({...props}) => {
  const history = useHistory();
  
  return (
    <Navigation type={'BACK' } onClick={() => { history.action !== 'POP' ?  history.goBack() : history.push('/');}} {...props}/>
  )
}

const Navigation:React.FC<NavigationProps> = ({linkTo,type,children,...props}) => {
  const child = <>{type && <SVG tag="span" type={type} {...props}/>} {children}</>
  return (
    <div className={styles['navigation']} >
      {linkTo ? <Link to={linkTo}>{child}</Link> : child}
    </div>
  )
}


const Title:React.FC<React.HTMLAttributes<HTMLDivElement>> = ({className,children,...props}) => {
  return (
    <h4 className={`${styles['title']} ${className}`} {...props}>{children}</h4>
  )
}

interface NavigationsProps {
  left?:NavigationProps ;
  title?:React.ReactNode;
  right?:NavigationProps;
}

export const Navigations:React.FC<NavigationsProps & NavBarProps> = ({left={},title,right={},...props}) => {

  return (
    <NavBar {...props}>
      {(left.type =='BACK' && !left.onClick)  ?  <NavigationBack /> : <Navigation {...left}/>}
      <Title >{title}</Title>
      <Navigation {...right} />
    </NavBar>
  )
}


type Direction = 'TOP' | 'BOTTOM' | 'NONE';

interface NavBarProps {
  height?: number;
  fixedType?: Direction;
  borderType?: Direction | boolean;
  style?: React.CSSProperties;
}

class NavBar extends React.Component<NavBarProps,{}> {
  static Navigation = Navigation;
  static Title = Title;
  
  render() {
    const { height=45, fixedType, borderType = 'BOTTOM', style, children } = this.props;

    return (
      <nav className={styles['nav-bar']} style={{ height }}>
        <div className={`${styles['nav-bar-content']} ${fixedType ? styles['nav-bar-fixed'] : ''} ${styles[`nav-fixed-${fixedType}`]} ${styles[`nav-border-${borderType}`]}`} style={{ height, ...style }}>
          {children}
        </div>
      </nav>
    )
  }
}

export default NavBar;