import React from 'react'
import {OptionButton,Radio} from 'components'
import styles from './reportTags.module.scss'

export interface ReportTag {
  type?: string;
  text?: string;
  canSubmit?: boolean;
  isFetching?: boolean;
  tagAction?: 'NONE' | 'BLOCK' | 'UNFOLLOW';
  warning?: boolean;
}


export interface Report {
  title?: string;
  reportTags?: ReportTag[];
  tag?: 'BUTTON' | 'RADIO';
}

export interface ReportTagsProps {
  report?: Report;
  onReportTagClick?: (reportTag:ReportTag,index:number) => void;
  onRadioChange?: (e:React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

const ReportTags:React.FC<ReportTagsProps> = ({report,onReportTagClick,onRadioChange,className}) => {
  const {title,reportTags=[],tag = 'BUTTON'} = report;
  return (
    <div className={`${styles['report-tags']} ${className}`}>
      {title && <h4 className={styles['report-tags-title']}>{title}</h4>}
      {reportTags.length !=0 && <div className={styles['report-tags-content']}>
        {reportTags.map((item,index) => 
          tag == 'BUTTON' ? 
            <OptionButton 
              type={item.warning ? 'WARNING' : 'DEFAULT'} 
              loading={item.isFetching} 
              onClick={() => { onReportTagClick(item,index) }} 
              className={styles['tags-content-btn']}
              >
              {item.text}
            </OptionButton> : 
            <Radio 
              onChange={onRadioChange} 
              name="seletedTag" 
              value={item.type}
              className={styles['tags-content-radio']}
            >
              {item.text}
            </Radio>
        )}
      </div>}
    </div>
  )
}

export default ReportTags;