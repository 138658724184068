import * as React from 'react'
import {Segment} from './Segment'

class AvatarAnimation extends React.Component {
  strokeStyle(t,width,strokeType) {
    const n = t.createLinearGradient(0, width , width, 0);
    switch(strokeType) {
      case "COLOR" :
        n.addColorStop(0, '#FDD074');
        n.addColorStop(.25, '#F77F34');
        n.addColorStop(.5, '#DD326E');
        n.addColorStop(.75, '#D82B7E');
        n.addColorStop(1, '#A432B1');
      break;
      case 'LIGHT' : 
        n.addColorStop(0,'#dbdbdb');
        n.addColorStop(1,'#dbdbdb');
      break;
    }
    return n;
  }
  constructor(props) {
    super(props);

    this.draw = () => {
      if(!this.refs.canvasEl) return;
      const {width,strokeType='COLOR',lineWidth=2} = this.props;
      
      const t = this.refs.canvasEl.getContext('2d');
      const devicePixelRatio = window.devicePixelRatio || 1;
      this.aniFuc = null;
      const s = {
        canvasCenter: width/2,
        drawableCanvasSize: width,
        elementCenterOffset: 0,
        lineWidth,
        radius: (width - 4)/2,
      }
      t.strokeStyle = this.strokeStyle(t,width,strokeType);
      t.lineWidth = lineWidth;
      t.lineCap = 'round';
      t.lineJoin = 'round';
      t.save();
      t.scale(devicePixelRatio,devicePixelRatio);
      this.segment.draw(t,{
          bounds: {
            centerX: s.canvasCenter,
            centerY: s.canvasCenter,
            radius: s.radius,
            width: s.drawableCanvasSize,
            height: s.drawableCanvasSize
        },
        lineWidth: s.lineWidth
      })
      t.restore()

    }
    this.runAni = () =>{
      null == this.aniFuc && (this.aniFuc = requestAnimationFrame(this.draw));
    }
    this.segment = new Segment(this.runAni)
    this.aniFuc = null;

  }
  componentDidUpdate(t){
    !t.isLoading && this.props.isLoading ? this.segment.startSpinning() : t.isLoading && !this.props.isLoading ? this.segment.stopSpinning() : '';
    //t.isLoading && !this.props.isLoading ? this.segment.stopSpinning() : this.segment.spinOnceIntoFullRing();
    (t.width != this.props.width || t.height != this.props.height)&& this.draw();
  }
  componentDidMount(){

    this.props.isLoading ? this.segment.startSpinning() : this.props.showRing && (this.props.spinOnce ? this.segment.spinOnceIntoFullRing() : this.draw());
  }
  componentWillUnmount(){
    null != this.aniFuc && (cancelAnimationFrame(this.aniFuc));
    this.aniFuc = null;
  }

  render(){
    const {width,height,style} = this.props;
    const devicePixelRatio = window.devicePixelRatio;
    return (
      <>
       <canvas ref="canvasEl" width={width * devicePixelRatio} height={height * devicePixelRatio}  style={{width,height,...style}}/>
      </>
    )
  }
}

  export default AvatarAnimation;
