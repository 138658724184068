import axios from 'axios'
// 创建axios实例
const request = axios.create({
  timeout: 60000, // 请求超时时间
  baseURL: '/api'
})

// request拦截器
request.interceptors.request.use(
  config => {
    return config
  },
  error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// response 拦截器
request.interceptors.response.use(
  response => {
    const res = response.data
    return res;
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

export default request

