import React,{useMemo,useCallback} from 'react'
import styles from './styles.module.scss'
import { AvatarProps,Avatar,AvatarGroup,AvatarGroupProps } from 'components'
import {Username,UsernameProps,UsernameGroup} from 'containers/Commons'

export interface User {
  username?: string;
  id?: string;
  avatar?: string;
  fullname?: string;
  hasNewStory?: boolean;
  isAvatarLoading?: boolean;
  isVerified?: boolean;
  isPrivite?: boolean;
}

export interface UserCellProps extends AvatarProps,UsernameProps,AvatarGroupProps {
  user?: User | User[];
  gap?: number;
  flexDirection?: 'column' | 'row';
  description?: React.ReactNode | React.ReactNode[];
  descriptionClassName?: string;
  descriptionStyle?: React.CSSProperties;
  avatarClassName?: string;
  avatarStyle?: React.CSSProperties;
  usernameClassName?: string;
  usernameChild?: React.ReactNode;
  usernameStyle?: React.CSSProperties;
  className?: string;
  style?: React.CSSProperties;
  onAvatarClick?: (user:User) => void;
  avatarChild?: React.ReactNode;
  onClick?: () => void;
  useFullnameDescription?: boolean;
  avatarContainerSize?: number;
  avatarOffset?: number;
  avatarPadding?: number;
  mutilUsername?: boolean;
  mutilAvatarDisplayType? : 'GROUP' | 'BADGE';
}

const UserCell:React.FC<UserCellProps> = (props) => {

  const {mutilAvatarDisplayType = 'BADGE',mutilUsername,avatarContainerSize,avatarChild, username,onAvatarClick,onClick, style, descriptionStyle, className, description = [], avatarStyle, usernameStyle, usernameChild, flexDirection = 'row', usernameClassName = "", avatarClassName = "", descriptionClassName = "",hasRing, gap,user = {},useFullnameDescription,hasLink = true,avatarOffset,avatarPadding=2,...others} = props;

  const isRow = flexDirection == 'row';
  const isArrayUser = Array.isArray(user);
  const isGroup = isArrayUser && (user as User[]).length != 1;

  const descriptions = useMemo(() => {
    const descriptions = Array.isArray(description) ? description : [description];
    
    useFullnameDescription && descriptions.unshift(isGroup ? user[0].fullname : (user as User).fullname);
    return descriptions;
  },[props])

  const getAvatar = useCallback((user={},children,style) => {
    const {username,fullname,avatar} = user;
    return <Avatar linkTo={!hasLink ? null : `/${fullname}`} onClick={() => { onAvatarClick && onAvatarClick(user) }} style={style} className={avatarClassName} alt={`${username}的头像`} hasRing={hasRing}  src={avatar}  {...others as AvatarProps} >
    {children}
  </Avatar>
  },[props])

  const getUsername = useCallback((user,children) => {
    const {username,fullname,isVerified} = user;
    return <Username style={usernameStyle} className={usernameClassName} username={username} fullname={fullname} hasLink={hasLink} isVerified={isVerified} {...others as UsernameProps}>{children}</Username>
  },[props])

  const _Avatar = useMemo(() => {
    if(isGroup) {
      switch(mutilAvatarDisplayType) {
        case 'BADGE':
          return (
            <div style={{width:avatarContainerSize,height:avatarContainerSize}}>
              {getAvatar(user[0],null,null)}
              {getAvatar(user[1],null,{position:'absolute',top:avatarOffset,left:avatarOffset,background:'#fff',boxSizing:'content-box',padding:avatarPadding})}
              {avatarChild}
            </div>
          )
        case 'GROUP':
          return(
            <AvatarGroup size={props.size} avatars={(user as User[]).map(item => {return {src:item.avatar,alt:`${item.username}的头像`}})} overlapDirection={props.overlapDirection} borderColor={props.borderColor} />
          )
      }
    } else {
      return getAvatar(isArrayUser ? user[0] : user,avatarChild,avatarStyle);
    }

  },[props])

  const _username = useMemo(() => {

    if(username) {
      return (
        <div className={styles['user-cell-username']}>{username}</div>
      )
    }

    if(isGroup && mutilUsername) {
      return  <UsernameGroup users={user as User[]} hasLink={hasLink} />
    } else {
      return getUsername(isArrayUser ? user[0] : user,usernameChild);
    }

  },[props])


  return (
    <div className={`${styles['user-cell']} ${styles[`user-cell-${flexDirection}`]} ${className}`} style={{ ...style, flexDirection }} onClick={onClick}>
      {_Avatar}
      <div className={`${styles['user-cell-description']} ${isRow ? '' : styles['cell-description-column']}`} style={{ marginLeft: isRow ? gap : 0, marginTop: !isRow ? gap : 0 }}>
        {_username}
        {descriptions.map((d, i) => (
          <div className={`${styles['cell-description-text']} ${i > 0 ? styles['description-text-low'] : ''} ${descriptionClassName}`} style={descriptionStyle}>
            {d}
          </div>))}
      </div>
    </div>
  );
}

export default React.memo(UserCell);
