export default {
  "LINE_TYPE": {
      "CURVE": "curve",
      "DOT": "dot",
      "END": "end"
  },
  "BRUSHES": {
      "MAGIC": "magic",
      "MARKER": "marker",
      "ERASER": "eraser",
      "CHISEL": "chisel"
  },
  "TEXT_COLORS_ENUM": {
      "yellow": "#fdcb5c",
      "orange": "#fd8d32",
      "pink": "#d10869",
      "purple": "#a307ba",
      "blue": "#0095f6",
      "green": "#58c322",
      "black": "black",
      "white": "white"
  },
  "DRAW_COLORS_ENUM": {
      "yellow": "yellow-5",
      "orange": "orange-5",
      "pink": "pink-5",
      "purple": "purple-5",
      "blue": "blue-5",
      "green": "green-5",
      "black": "black",
      "white": "white"
  },
  "TEXT_COLORS": [
      "#fdcb5c",
      "#fd8d32",
      "#d10869",
      "#a307ba",
      "#0095f6",
      "#58c322",
      "black",
      "white"
  ],
  "DRAW_COLORS": [
      "#fdcb5c",
      "#fd8d32",
      "#d10869",
      "#a307ba",
      "#0095f6",
      "#58c322",
      "black",
      "white"
  ],
  "TRASH_ID": "trash_button_id",
  "TEXT_TYPE": "text",
  "PLACED_TYPE": "placed",
  "MIN_BRUSH_SIZE": 1,
  "MAX_BRUSH_SIZE": 22,
  "DEFAULT_FONT_SIZE": 22,
  "MAX_SCALE": 2.3636353636363636
}