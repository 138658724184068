import React from 'react'
import numberFormat from 'utils/numberFormat'

interface ShortenNumberProps {
  value?: number
}


const ShortenNumber:React.FC<ShortenNumberProps> = ({value}) => {
  return (
    <>
      {value > 9999 ? `${numberFormat.formatLargeNumberForCJKLocale(value)}${numberFormat.getSuffixForCJKLocale(value)}` : value}
    </>
  )
}

export default ShortenNumber;