import React,{createContext} from 'react'

type PhotoContextValue = {
  canvasImage?: HTMLCanvasElement;
}

export const PhotoContext = createContext<PhotoContextValue>({});

type PhotoContextProviderProps = {
  children: React.ReactNode
}

export const PhotoContextProvider:React.FC<PhotoContextProviderProps & PhotoContextValue> = ({children,canvasImage}) => {
  return (
    <PhotoContext.Provider value={{canvasImage }}>{children}</PhotoContext.Provider>
  )
}

export default PhotoContext;