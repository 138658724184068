import * as React from 'react'
import {Button} from 'components'
import {Username,UserCell,ShortenNumber,LikeButton,SeeMore,LinkifyText,User,CommentItem,Time,TimeProps} from '../index'

import {Link} from 'react-router-dom'
import styles from './postComment.module.scss'

interface PostCommentLikeProps {
  count?: number;
  fullname?:  string;
}

export interface MediaPreviewLike {
  count?: number;
  mutualUsers?: User[]
}


export interface MediaPreviewComment {
  count?: number;
  comments?: CommentItem[]
}


const PostCommentLike: React.FC<PostCommentLikeProps> = ({ count,fullname }) => {
  return (
    <div className={styles['post-comment-like']}>
      <Link to={`/${fullname}/likedBy`}>
        <span><ShortenNumber value={count} /></span>&nbsp;次赞
      </Link>
    </div>
  )
}

interface CommentLikeActionProps {
  mediaPreviewLike?: MediaPreviewLike,
  onLikeClick?: () => void;
  fullname?: string;
}

const CommentLikeAction: React.FC<CommentLikeActionProps> = ({ mediaPreviewLike, onLikeClick,fullname }) => {
  
  return (
    <div className={styles['comment-like-action']}>
      {!mediaPreviewLike.count && <div className={styles['like-action-first']}>
        抢先&nbsp;<Button type="TEXT" onClick={onLikeClick} className={styles['action-first-btn']}>点赞</Button>
      </div>}
      <div className={styles['like-action-user']}>
        <UserCell size={20} gap={5} hasVerifiedBadge={false} mutilAvatarDisplayType="GROUP" mutilUsername={false} user={mediaPreviewLike.mutualUsers} style={{ flex: 'none' }} />&nbsp;和&nbsp;<Link to={`/${fullname}/likedBy`}>另{<ShortenNumber value={mediaPreviewLike.count} />}个用户</Link>&nbsp;赞了
      </div>
    </div>
  )
}


interface PostCommentRootProps {
  caption?: string;
  owner?: User;
  count?: number;
  shortCode?: string;
}

const PostCommentRoot: React.FC<PostCommentRootProps> = ({ shortCode,count, caption, owner }) => {
  return (
    <>
      <div className={styles['post-comment-root']}>
        <Username fullname={owner.fullname} username={owner.username} style={{ display: 'inline-flex',fontWeight:500 }} /> &nbsp;
        <SeeMore text={caption} />
      </div>
      <div className={styles['post-comment-num']}>
        <Link to={`/comments/${shortCode}`}>全部 {<ShortenNumber value={count} />} 条评论</Link>
      </div>
    </>
  )
}

interface PostCommentRecentProps {
  comments?: CommentItem[];
  onLikeClick?:(id:string,liked:boolean) => void;
}

const PostCommentRecent: React.FC<PostCommentRecentProps> = ({ comments = [] ,onLikeClick = () => {}}) => {
  return (
    <div className={styles['post-comment-recent']}>
      {comments.map(({owner,id,text,viewerHasLiked}) => {
        return (<div className={styles['comment-recent-item']} key={id}>
          <div className={styles['recent-item-content']}>
            <Username style={{ display: 'inline-flex',fontWeight:600 }} username={owner.username} />&nbsp;
            <LinkifyText className={styles['item-content-text']} text={text} />
          </div>
          <LikeButton onClick={() => { onLikeClick(id,!viewerHasLiked)}} liked={viewerHasLiked} size={12} style={{ marginLeft: 3 }} />
        </div>)
      })}
    </div>
  )
}


const PostCommentTime: React.FC<TimeProps> = ({ ...props }) => {
  return (
    <div className={styles['post-comment-time']}>
      <Time {...props} hasWeek={true}/>
    </div>
  )
}

interface PostCommentProps {
  mediaPreviewComment?: MediaPreviewComment,
  mediaPreviewLike?: MediaPreviewLike,
  onLikeClick?: () => void;
  onCommentLikeClick?: (commentId:string,liked:boolean) => void;
  createAt?: number;
  caption?: string;
  owner?: User;
  onTimeClick?: () => void;
  shortCode?: string;
}

const PostComment: React.FC<PostCommentProps> = ({ owner,onTimeClick,mediaPreviewComment, mediaPreviewLike, caption, onLikeClick, createAt,onCommentLikeClick,shortCode }) => {
  return (
    <div className={styles['post-comment']}>
      {(mediaPreviewLike.mutualUsers.length || mediaPreviewLike.count == 0) ? <CommentLikeAction mediaPreviewLike={mediaPreviewLike} onLikeClick={onLikeClick} fullname={owner.fullname} /> : <PostCommentLike fullname={owner.fullname} count={mediaPreviewLike.count} />}
      <PostCommentRoot shortCode={shortCode} caption={caption} owner={owner} count={mediaPreviewComment.count} />
      <PostCommentRecent comments={mediaPreviewComment.comments} onLikeClick={onCommentLikeClick} />
      <PostCommentTime date={createAt} onClick={onTimeClick} />
    </div>
  );
}

export default PostComment;
