

import { useMediaQuery } from 'react-responsive'


export const useMax735 = () => {
  return useMediaQuery({
    query: '(max-width: 735px)'
  })
}

export const useMin640 = () => {
  return useMediaQuery({
    query: '(min-width:640px)'
  })
}

export const useMax415 = () => {
  return useMediaQuery({
    query: '(max-width:415px)'
  })
}

export const useMax935 = () => {
  return useMediaQuery({
    query: '(max-width:935px)'
  })
}

export const useMin736 = () => {
  return  useMediaQuery({
    query: '(min-width:736px)'
  })
}