export enum CreatePostActionType {
  CREATE_POST_SET_STYLE="CREATE_POST_SET_STYLE",
  CREATE_POST_CLEAR="CREATE_POST_CLEAR",
  CREATE_POST_SET_CAPTION = 'CREATE_POST_SET_CAPTION',
  CREATE_POST_SET_LOCATION_TAG = 'CREATE_POST_SET_LOCATION_TAG',
  CREATE_POST_SET_LOCATIONS = 'CREATE_POST_SET_LOCATIONS',
  CREATE_POST_CHANGE_FETEACH_LOCATIONS_STATUS = 'CREATE_POST_CHANGE_FETEACH_LOCATIONS_STATUS',
  CREATE_POST_ADD_TEMPORARY_USER_TAG = 'CREATE_POST_ADD_TEMPORARY_USER_TAG',
  CREATE_POST_SET_TEMPORARY_USER_TAGS = 'CREATE_POST_SET_TEMPORARY_USER_TAGS',
  CREATE_POST_SET_USER_TAGS = 'CREATE_POST_SET_USER_TAGS',
  CREATE_POST_SET_USERS = 'CREATE_POST_SET_USERS',
  CREATE_POST_CHANGE_FETEACH_USERS_STATUS = 'CREATE_POST_CHANGE_FETEACH_USERS_STATUS',
  CREATE_POST_SET_ALT_TEXT = 'CREATE_POST_SET_ALT_TEXT',
  CREATE_POST_DELETE_TEMPORARY_USER_TAG = 'CREATE_POST_DELETE_TEMPORARY_USER_TAG',
  CREATE_POST_SET_CURRENT_USER_TAG_POSITION = 'CREATE_POST_SET_CURRENT_USER_TAG_POSITION',
  CREATE_POST_SET_TEMPORARY_USER_TAG_POSITION = 'CREATE_POST_SET_TEMPORARY_USER_TAG_POSITION'
}