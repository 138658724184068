import React from 'react'
import Follow ,{FollowProps}from '../Follow'
import styles from './styles.module.scss'


const FollowInUsername:React.FC<FollowProps> = ({...props}) => {
  return (
    <div className={styles['follow-in-username']}>
      <span className={styles['in-username-dot']}>•</span>
      <Follow {...props} type="TEXT" className={styles['in-username-btn']}/>
    </div>
  )
}

export default FollowInUsername;