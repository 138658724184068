import React from 'react'
import styles from './styles.module.scss';
import { AvatarAnimation, ImageProps, Image } from 'components'
import { Link } from 'react-router-dom';

export interface AvatarProps extends ImageProps {
  hasRing?: boolean;
  isLoading?: boolean;
  size?: number;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  shape?: 'CIRCLE' | 'SQUARE',
  seenStory?: boolean;
  spinOnce?: boolean;
  strokeWidth?: number,
  linkTo?: string;
  strokeType?: 'LIGHT' | 'COLOR';
}

const Avatar: React.FC<AvatarProps> = ({ size, style, seenStory, hasRing, className, isLoading, onClick = () => { }, shape = "CIRCLE", children, strokeWidth, linkTo, spinOnce, strokeType, ...props }) => {
  const _LinkTo = !hasRing ? linkTo : null;
  const Tag: any = _LinkTo ? Link : 'span'

  const shapeRadiusMap = {
    'CIRCLE': '50%',
    'SQUARE': '4px'
  }
  return (
    <Tag style={{ width: size, height: size, ...style }} to={_LinkTo} onClick={onClick} className={`${styles['avatar']} ${className}`}>
      <Image style={{ borderRadius: shapeRadiusMap[shape] }} {...props} />
      {hasRing && <AvatarAnimation spinOnce={spinOnce} width={size + 10} strokeType={strokeType ? strokeType : seenStory ? 'LIGHT' : 'COLOR'} height={size + 10} isLoading={isLoading} showRing={hasRing} lineWidth={strokeWidth ? strokeWidth : (seenStory ? 1 : 2)} style={{ position: 'absolute', top: '-5px', left: '-5px' }} />}
      {children}
    </Tag>
  )
}

export default Avatar;



export interface AvatarGroupProps {
  overlapDirection?: 'LEFT' | 'RIGHT';
  borderColor?: 'WHITE' | 'BLACK' | 'TRANSPARENT';
  size?: number;
  avatars?: ImageProps[];
}


export const AvatarGroup:React.FC<AvatarGroupProps> = ({overlapDirection='LEFT',borderColor='WHITE',size,avatars}) => {
  const isLeft = overlapDirection === 'LEFT';
  const avatarsLength = avatars.length;
  const isRight = !isLeft;

  return (
    <div className={styles['avatar-group']} style={{justifyContent: isLeft ? 'start' : 'end'}}>
      {avatars.map(({src,alt},i) => {
        const style = {
          height : size,
          width: size,
          order: i + 1
        };

        if(isRight) {
          style.order = avatarsLength - (i + 1);
        }

        return (
          <div className={`${styles['avatar-group-item']} ${styles[`item-border-${borderColor}`]} ${i > 0 ? styles[`group-item-${overlapDirection}`] : ''}`}>
            <Avatar src={src} size={size} alt={alt}/>
          </div>
        )
      })}
    </div>
  )
}