import React from 'react'
import styles from './userSkeleton.module.scss'

export interface UserSkeletonProps {
  avatarSize?: number;
  gap?: number;
  className?: string;
  visible?: boolean;
  describeRaduis?: number;
  describeHeight?: number;
  descriptionTopWidth?: number | string;
  descriptionBottomWidth?: number | string;
  flexDirection?: 'row' | 'column';
  style?: React.CSSProperties;
  descriptionStyle?:React.CSSProperties;
}

const UserSkeleton:React.FC<UserSkeletonProps> = ({flexDirection='row',style,avatarSize=44,describeHeight,descriptionTopWidth,descriptionBottomWidth,gap,className,visible=true,describeRaduis=0,descriptionStyle={}}) => {

  const isRow = flexDirection == 'row';

  return (
    <>
      {visible && <div className={`${styles['user-skeleton']} ${styles[`user-skeleton-${flexDirection}`]} ${className}`} style={style}>
        <div className={styles['user-skeleton-avatar']} style={{width:avatarSize,height:avatarSize}} />
        <div className={styles['user-skeleton-description']} style={{...descriptionStyle,marginLeft:isRow ? gap : 0,marginTop:isRow ? 0 : gap}}>
          <div className={styles['skeleton-description-top']} style={{borderRadius:describeRaduis,height:describeHeight,width:descriptionTopWidth}}/>
          {descriptionBottomWidth != undefined && <div className={styles['skeleton-description-bottom']} style={{borderRadius:describeRaduis,height:describeHeight,width:descriptionBottomWidth}}/>}
        </div>
      </div>}
    </>
  )
}


export default UserSkeleton;