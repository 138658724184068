import React,{useState} from 'react'
import {Button,ButtonProps} from 'components'
import {User} from '../index'
import RemoveFollowerConfirm from './RemoveFollowerConfirm'

export interface RemoveFollower {
  user?: User;
  onRemoveFollowerClick?: (id?:string) => void;
}


export interface RemoveFollowerProps extends ButtonProps,RemoveFollower {}

const RemoveFollower:React.FC<RemoveFollowerProps> = ({onRemoveFollowerClick,user,type="DEFAULT",...props}) => {
  const [ removeFollowerConfirmVisible,setRemoveFollowerConfirmVisible] = useState<boolean>(false);

  return (
    <>
      <Button {...props} type={type} onClick={() => { setRemoveFollowerConfirmVisible(true) }}>移除</Button>
      <RemoveFollowerConfirm onRemoveFollowerClick={onRemoveFollowerClick} user={user} visible={removeFollowerConfirmVisible} onCancel={() => { setRemoveFollowerConfirmVisible(false) }} />
    </>
  )
}

export default RemoveFollower;