import React from 'react';
import {Button,Avatar} from 'components'
import {Username,LikeButton,LinkifyText,User,Time} from '../index'
import { useDoubleTap } from 'use-double-tap';
import { useLongPress } from 'use-long-press';
import styles from './comment.module.scss'

export interface CommentItem {
  createdAt?: number;
  text?: string;
  id?: string;
  owner?: User;
  viewerHasLiked?: boolean;
  likeByCount?: number;
  comments?: CommentItem[];
  count?: number;
  isFetchChildComments?: boolean;
  expanded?:boolean;
  viewerSeenStory?: boolean;
}


interface CommentProps {
  hasReply?: boolean;
  hasLike?: boolean;
  onReplyClick?: () => void;
  onLikeClick?: (id:string,liked:boolean) => void;
  style?: React.CSSProperties;
  onLongPress?: () => void;
  hasStoryLink?: boolean;
  onAvatarClick?: () => void;
  comment?:CommentItem;
}

const Comment:React.FC<CommentProps> = ({style,hasReply=true,hasLike=true,onLikeClick=() =>{},onReplyClick,onLongPress,hasStoryLink=false,onAvatarClick,comment}) => {
  const {viewerHasLiked,id,owner,viewerSeenStory,text,createdAt,likeByCount} = comment;

  const doubleTapBind = useDoubleTap(() => {
    !viewerHasLiked && onLikeClick(id,true);
  },500)
  const longPressBind = useLongPress(onLongPress,{threshold:1000});
  return (
    <div className={`${styles['comment']} ${hasLike ? styles['comment-with-like'] : ''}`} style={style} {...doubleTapBind} >
      <div className={styles['comment-contianer']} {...longPressBind}>
        <Avatar src={owner.avatar} size={32} hasRing={hasStoryLink && owner.hasNewStory} style={{marginRight : 18}} onClick={onAvatarClick} linkTo={`/${owner.fullname}`} seenStory={viewerSeenStory} isLoading={owner.isAvatarLoading}/>
        <div className={styles['comment-contianer-content']}>
          <Username username={owner.username} fullname={owner.fullname} className={styles['contianer-content-username']} isVerified={owner.isVerified} verifiedBadgeSize="SMALL"/>
          <LinkifyText text={text}/>
          <div className={styles['contianer-content-actions']}>
            <Time date={createdAt} hasWeek={true} style={{fontSize:12}}/>
            {hasLike && likeByCount != 0 && <a className={styles['content-actions-like']}>{likeByCount.toLocaleString()} 次赞</a>}
            {hasReply && <Button onClick={onReplyClick} className={styles['content-actions-reply']} type="TEXT">回复</Button>}
          </div>
        </div>
      </div>
      {hasLike && <LikeButton onClick={(e) => {e.stopPropagation();onLikeClick(id,!viewerHasLiked); }} liked={viewerHasLiked} size={12} style={{marginTop:9}}/>}
    </div>
  )
}


export default Comment;