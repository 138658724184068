import React, { createContext, useCallback,useContext,useState,cloneElement,Children, useEffect } from 'react'
import styles from './styles.module.scss'

const FormContext = createContext(null);


interface FormItemProps {
  label?: string;
  help?: React.ReactNode | React.ReactNode [];
  name?: string;
}

export const FormItem: React.FC<FormItemProps> = ({ label, help, name, children }) => {

  const {form,setForm,onValuesChange} = useContext(FormContext);
  const _help = Array.isArray(help) ? help :  [help];


  const onChange = useCallback((e) => {
    const value = e.target.value;
    form[name] = value;
    onValuesChange({name,value},form);
    setForm({...form})
  },[form])

  return (
    <div className={styles['form-item']}>
      <label className={styles['form-item-label']}>
        <div className={styles['item-label-text']}>{label}</div>
        <div className={styles['item-label-input']}>
          {Children.map(children,child => {
            return cloneElement(child as React.ReactElement<any>,{value:form[name],onChange})
          })}
          {_help.map(help => <div className={styles['label-input-help']} >{help}</div>)}
        </div>
      </label>
    </div>
  )
}


interface FormProps {
  initialValues? : {};
  onSubmit?: (o:{}) => void;
  className?: string;
  onValuesChange?: (changedValues,allValues?) => void;
  children?:React.ReactNode
}

const Form: React.FC<FormProps> = ({initialValues,onSubmit=() => {},className,onValuesChange=() => {},children}) => {
  const [form,setForm] = useState<{}>(initialValues||{});

  useEffect(() => {
    setForm({...form,...initialValues})
  },[initialValues])

  return (
    <FormContext.Provider value={{form,setForm,onValuesChange}}>
      <form className={className} onSubmit={() => { onSubmit(form) }}>{children}</form>
    </FormContext.Provider>
  )
}

export default React.memo(Form);