export enum ProfileActionType {
  PROFILE_SET = 'PROFILE_SET',
  PROFILE_CHANGE_FETCH_STATUS = 'PROFILE_CHANGE_FETCH_STATUS',
  PROFILE_CHANGE_BLOCK_STATUS = 'PROFILE_CHANGE_BLOCK_STATUS',
  PROFILE_CHANGE_RESTRIT_STATUS = 'PROFILE_CHANGE_RESTRIT_STATUS',
  PROFILE_CHANGE_FOLLOW_STATUS = 'PROFILE_CHANGE_FOLLOW_STATUS',
  PROFILE_SET_IS_OWN = 'PROFILE_SET_IS_OWN',
  PROFILE_SET_HIGHLIGHTS_TRAY = 'PROFILE_SET_HIGHLIGHTS_TRAY',
  PROFILE_CHANGE_FETCH_HIGHLIGHTS_STATUS = 'PROFILE_CHANGE_FETCH_HIGHLIGHTS_STATUS',
  PROFILE_CHANGE_HIGHLIGHTS_AVATAR_LOADING_STATUS = 'PROFILE_CHANGE_HIGHLIGHTS_AVATAR_LOADING_STATUS',
  PROFILE_CHANGE_FETCH_POSTS_STATUS = 'PROFILE_CHANGE_FETCH_POSTS_STATUS',
  PROFILE_SET_POSTS = 'PROFILE_SET_POSTS',
  PROFILE_APPEND_POSTS = 'PROFILE_APPEND_POSTS',
  PROFILE_CHANGE_FETCH_GUIDES_STATUS = 'PROFILE_CHANGE_FETCH_GUIDES_STATUS',
  PROFILE_SET_GUIDES = 'PROFILE_SET_GUIDES',
  PROFILE_APPEND_GUIDES = 'PROFILE_APPEND_GUIDES',
  PROFILE_CHANGE_AVATAR_LOADING_STATUS = 'PROFILE_CHANGE_AVATAR_LOADING_STATUS',
  PROFILE_CHANGE_VIEWER_SEEN_STORY = 'PROFILE_CHANGE_VIEWER_SEEN_STORY'
}
