import React from 'react'
import { ListModalProps } from 'components'
import UserActionConfirm from '../UserActionConfirm'
import {RemoveFollower} from '.'
import styles from './removeFollowerConfirm.module.scss'


interface RemoveFollowerConfirmProps extends ListModalProps,RemoveFollower { }


const RemoveFollowerConfirm: React.FC<RemoveFollowerConfirmProps> = ({ user = {}, onRemoveFollowerClick = () => { }, ...props }) => {
  const { username } = user;
  return (
    <UserActionConfirm sureText="移除" onSure={onRemoveFollowerClick} user={user} {...props}>
      <div className={styles['remove-follower-confirm']}>
        <h2 className={styles['follower-confirm-title']}>移除粉丝？</h2>
        <div className={styles['follower-confirm-text']}>Instagram 不会通知 {username} 你已移除其粉丝身份</div>
      </div>
    </UserActionConfirm>
  );
}

export default RemoveFollowerConfirm;
