import React,{useState} from 'react'
import PostHeader,{HasSomeOption} from './PostHeader'
import PostComment from './PostComment'
import PostActions from './PostActions'
import ReleaseComment from './ReleaseComment'
import {useMin640} from 'hooks/useMediaQuery'
import PostCarousel from './PostCarousel'
import {User,DirectShare,StickyDilogWithUsers,FollowType,Dimensions,Media,MediaTaggedUser,MediaPreviewComment,MediaPreviewLike,ViewerFollowStatus,LocationItem} from '../index'
import {useHistory} from 'react-router-dom'
import useActiveStoryUserId,{UseActiveStoryUserIdParams} from 'hooks/useActiveStoryUserId'
import styles from './styles.module.scss'

interface ClipsMusic {
  id?: string;
  artistName?: string;
  songName?: string;
}

export interface PostItem  extends ViewerFollowStatus{
  owner?: User;
  viewerHasLiked?: boolean;
  viewerSeenStory?: boolean;
  vierwerHasSaved?: boolean;
  dimensions?: Dimensions;
  mediaPreviewComment?: MediaPreviewComment;
  mediaPreviewLike?: MediaPreviewLike;
  caption?: string;
  mediaTaggedUsers?: MediaTaggedUser[];
  carousels?:Media[];
  location?:LocationItem;
  clipsMusic?:ClipsMusic;
  createAt?: number;
  id?: string;
  shortCode?: string;
  isOwn?: boolean;
  sideAuthor?:User;
}


export interface PostEvent extends UseActiveStoryUserIdParams {
  onChangeFollowStatus?: (id:string,viewerFollowStatus:FollowType) => void;
  onChangeLikeStatus?: (id:string,liked:boolean) => void;
  onChangeSaveStatus?: (id:string,saved:boolean) => void;
  onChangeCommentLikeStatus?:(postId:string,commentId:string,liked:boolean) => void;
  onSetPostDetail?: (post:PostItem) => void;
  onDeletePost?: (id?:string) => void;
}


interface PostProps extends PostEvent,HasSomeOption {
  onSendComment?:(text:string) => void;
  post?:PostItem;
}


const Post: React.FC<PostProps> = ({post,hasFollow,hasOpenPostDetail,onSetPostDetail,hasVerified,onChangeLikeStatus,onChangeSaveStatus,onChangeCommentLikeStatus,onChangeFollowStatus = () => {},onSendComment,onDeletePost,...props}) => {
  const min640 = useMin640();
  const {id,owner,location,clipsMusic,carousels=[],dimensions,viewerHasLiked,vierwerHasSaved,mediaPreviewComment,mediaPreviewLike,createAt,caption,viewerFollowStatus,viewerSeenStory,mediaTaggedUsers,isOwn,shortCode,sideAuthor} = post;
  const [taggedUserVisible,setTaggedUserVisible] = useState<boolean>(false);
  const [taggedUsers,setTaggedUsers] = useState<User[]>([]);
  const [directShareVisible,setDirectShareVisible] = useState<boolean>(false);
  const setActiveStoryUserId = useActiveStoryUserId(props);
  const history = useHistory();

  const onOpenPostClick = () => {
    if(hasOpenPostDetail) {
      onSetPostDetail(post);
      history.push(`/postDetail/${shortCode}`);
    }
  }

  const onCommentClick = () => {
    history.push(`/comments/${shortCode}`);
  }

  const _setTaggedUser = (users:User[]) => {
    setTaggedUsers(users);
    setTaggedUserVisible(true);
  }

  const onAvatarClick = (user:User) => {
    user.hasNewStory && setActiveStoryUserId(user.id);
  }

  return (
    <div className={styles['post']} >
      <PostHeader 
        viewerFollowStatus={viewerFollowStatus} 
        user={owner}  
        description={location ? `${location.name}${location.address}` : clipsMusic ? `${clipsMusic.artistName} • ${clipsMusic.songName}`: '' } 
        hasFollow={hasFollow}
        onChangeFollowStatus={(id,followStatus) => { onChangeFollowStatus(id,followStatus);}}
        onOpenPostClick={onOpenPostClick}
        hasOpenPostDetail={hasOpenPostDetail}
        hasVerified={hasVerified}
        hasTaggedUser={mediaTaggedUsers.length > 0}
        onTaggedUserClick={() => { _setTaggedUser(mediaTaggedUsers.map(item => item.user)); }}
        onDirectClick={() => { setDirectShareVisible(true) }}
        isOwn={isOwn}
        onAvatarClick={onAvatarClick}
        onDeletePostClick={() =>{ onDeletePost(id) }}
        viewerSeenStory={viewerSeenStory}
        sideAuthor={sideAuthor}
      />
      <PostCarousel 
        medias={carousels} 
        dimensions={dimensions} 
        owner={owner} 
        onLiked={() => { onChangeLikeStatus(id,true) }}
        onTagClick={(taggedUsers) => { _setTaggedUser(taggedUsers.map(item => item.user)) }}
      />
      <PostActions 
        liked={viewerHasLiked} 
        saved={vierwerHasSaved} 
        onLikeClick={() => { onChangeLikeStatus(id,!viewerHasLiked) }} 
        onSaveClick={() => { onChangeSaveStatus(id,!vierwerHasSaved) }}
        onDirectClick={() => { setDirectShareVisible(true) }}
        onCommentClick={() => { onCommentClick() }}
      />
      <PostComment  
        mediaPreviewComment={mediaPreviewComment} 
        mediaPreviewLike={mediaPreviewLike} 
        onLikeClick={() => { onChangeLikeStatus(id,!viewerHasLiked) }} 
        owner={owner} 
        createAt={createAt} 
        caption={caption} 
        shortCode={shortCode}
        onTimeClick={onOpenPostClick}
        onCommentLikeClick={(commentId,liked) => { onChangeCommentLikeStatus(id,commentId,liked) }} 
      />
      {min640 && <ReleaseComment onSend={onSendComment}/>}

      <StickyDilogWithUsers 
        visible={taggedUserVisible}
        avatarSize={44}
        title="已标记"
        onCancel={() => { setTaggedUserVisible(false) }} 
        users={taggedUsers}
      />
      <DirectShare 
        visible={directShareVisible}
        onClose={() => { setDirectShareVisible(false) }}
      />
    </div>
  );
}

export default React.memo(Post);
