import React from 'react'
import styles from './formInput.module.scss'

export const Input:React.FC<React.InputHTMLAttributes<HTMLInputElement>> = ({className,...props}) => {
  return (
    <input className={`${styles['input']} ${className}`} {...props} />
  )
}

export const Textarea:React.FC<React.InputHTMLAttributes<HTMLTextAreaElement>> = ({className,...props}) => {
  return (
    <textarea className={`${styles['textarea']} ${className}`} {...props} />
  )
}