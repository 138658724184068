import React,{useMemo} from 'react'
import { SVGMap } from './SVGMap'
import styles from './styles.module.scss'

export type SVGType = 'MOREOPTIONS'| 'SAVEDACTIVE' |'CAMERA'|'DIRECT'|'LIKE'|'LIKEACTIVE'|'COMMENT'|'SAVED'|'PLUS'|'SPIN'|'THINSPIN'|'CLOSE'|'BACK'|'CHECKMARK'|'HOME'|'HOMEACTIVE'|'SEARCH'|'SEARCHACTIVE'|'NEWPOST'|'VIDEO'|'CLIP'|'TV'|'CAROUSEL'|'TAGS'|'DISCOVERPEOPLE'|'OPTIONS'|'POSTS' | 'IGTVDASH' | 'TAGGED'| 'NEWMESSAGE' | 'VIEWDETAILS' | 'PHOTO' | 'TAG' | 'LOCATION' | 'SHIELD' | 'CHANNEL' | 'INVISIBLE' | 'GUIDE' | 'THINCLOSE' | 'TOGGLESELECTION' | 'CHECKED' |'';

export interface SVGProps {
  style?: React.CSSProperties;
  size?: number;
  type?: SVGType;
  className?: string;
  fill?: string;
  ariaLable?: string;
  onClick?: (e: any) => void;
  tag?: 'button' | 'span';
  viewBox?: number;
  color?: string;
}

const SVG: React.FC<SVGProps> = ({ size=24,tag='button', className,color,fill = '#262626', type, ariaLable,viewBox=24,...props}) => {
  const Tag = tag as keyof JSX.IntrinsicElements;

  const inSvg = useMemo(() => {
    switch(type) {
      case 'SPIN' :
      case 'THINSPIN':
        return SVGMap[type](fill);
      default:
        return SVGMap[type];
    }
  },[type])

  return (
    <Tag className={`${className} ${type == 'BACK' ? styles['back'] : ''}`} {...props}>
      <svg aria-label={ariaLable} color={color} fill={fill} height={size} width={size} viewBox={`0 0 ${viewBox} ${viewBox}`} >
        {inSvg}
      </svg>
    </Tag>
  )
}

export default SVG;

