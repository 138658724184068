function n1(n, t) {
	return 1 - 3 * t + 3 * n
}
var y = 10,w = 4;
function t1(n, t) {
	return 3 * t - 6 * n
}

function u1(n) {
	return 3 * n
}

function o1(o, f, c) {
	return ((n1(f, c) * o + t1(f, c)) * o + u1(f)) * o
}

function f(o, f, c) {
	return 3 * n1(f, c) * o * o + 2 * t1(f, c) * o + u1(f)
}

function c1(n, t, u, f, c) {
	var v, w, l = 0;
	do {
		(v = o1(w = t + (u - t) / 2, f, c) - n) > 0 ? u = w : t = w
	} while (Math.abs(v) > s && ++l < y);
	return w
}

function v(n, t, u, c) {
	for (var v = 0; v < w; ++v) {
		var l = f(t, u, c);
		if (0 === l)
			return t;
		t -= (o1(t, u, c) - n) / l
	}
	return t
}

const x = (n, t, u, w) => {

	var l = .001,
		s = 1e-7,
		b = .1,
		h = 'function' == typeof Float32Array;

	function s1(t) {
		for (var o = 0, w = 1; 10 !== w && y[w] <= t; ++w)
			o += b;
		var s = o + (t - y[--w]) / (y[w + 1] - y[w]) * b,
			h = f(s, n, u);
		return h >= l ? v(t, s, n, u) : 0 === h ? s : c1(t, o, o + b, n, u)
	}
	if (!(0 <= n && n <= 1 && 0 <= u && u <= 1)){
		throw new Error('bezier x values must be in [0, 1] range');
	}
	var y = h ? new Float32Array(11) : new Array(11);
	if (n !== t || u !== w)
		for (var A = 0; A < 11; ++A)
			y[A] = o1(A * b, n, u);
	return function (f) {
		return n === t && u === w ? f : 0 === f ? 0 : 1 === f ? 1 : o1(s1(f), t, w)
	}
}

const t = -1,
	s = 270,
	n = 2e3,
	o = 2e3,
	h = 8e3,
	p = 30,
	l = 8e3,
	u = x(1, .25, 1, .25),
	c = 'ANIM_MODE_SOLID',
	S = 'ANIM_MODE_SPINNING',
	D = 'ANIM_MODE_STOPPING';
export var Segment = class {
	constructor(t) {
		this.animStartTime = 0;
		this.lastFrameStartTime = 0;
		this.animMode = c;
		this.segments = [];
		this.invalidated = !1;
		this.onInvalidate = t;
	}
	invalidate() {
		this.invalidated || (this.invalidated = !0,this.onInvalidate())
	}
	setAnimMode(t) {
		t !== this.animMode && (this.animMode = t,
			this.invalidate())
	}
	startSpinning({
		count: s = t
	} = {}) {
		this.createSegmentsForSpinning({
				spinCount: s
			}),
			this.animStartTime = this.lastFrameStartTime = Date.now(),
			this.setAnimMode(S)
	}
	stopSpinning() {
		this.animMode !== c && this.animMode !== D && this.setAnimMode(D)
	}
	spinOnce() {
		this.startSpinning({
			count: 1
		})
	}
	spinOnceIntoFullRing() {
		this.createSegmentsForSpinning({
				spinCount: 1
			}),
			this.animStartTime = this.lastFrameStartTime = Date.now() - 1e3,
			this.setAnimMode(S)
	}
	draw(t, {
		bounds: s,
		lineWidth: h
	}) {
		const p = Date.now() - this.lastFrameStartTime;
		switch (this.lastFrameStartTime = Date.now(),
			this.invalidated = !1,
			t.clearRect(-1, -1, s.width + 2, s.height + 2),
			this.animMode) {
			case S: {
				const o = p / n;
				this.updateAndDrawSegmentsForSpinning(t, {
					bounds: s,
					progressAmount: o,
					lineWidth: h
				});
				break
			}
			case D: {
				const n = p / o;
				this.updateAndDrawSegmentsForStopping(t, {
					bounds: s,
					progressAmount: n,
					lineWidth: h
				});
				break
			}
			case c:
				this.drawSolidCircle(t, {
					bounds: s,
					lineWidth: h
				});
				break;
			default:
				throw new Error('unexpected animMode')
		}
	}
	drawSolidCircle(t, {
		bounds: s,
		lineWidth: n
	}) {
		t.save(),
			t.beginPath(),
			t.arc(s.centerX, s.centerY, s.radius, 0, 2 * Math.PI),
			t.stroke(),
			t.restore()
	}
	createSegmentsForSpinning({
		spinCount: t
	}) {
		this.createSegments({
			spinCount: t,
			delayIncrement: .03333333333333333,
			useIterpolator: !0
		})
	}
	createSegmentsForHighlighting() {
		this.createSegments({
			spinCount: t,
			delayIncrement: .016666666666666666,
			useIterpolator: !0
		})
	}
	createSegments({
		spinCount: n,
		delayIncrement: o,
		useIterpolator: h = !0
	}) {
		const l = [];
		for (let c = p; --c >= 0;) {
			const p = h ? u(o * c) : o * c;
			l.push(new class {
					constructor({
						segmentIndex: s,
						startDelay: n,
						maxIterations: o = t
					}) {
						this.progress = 0,
							this.segmentIndex = s,
							this.startDelay = n,
							this.maxIterations = o
					}
					isTerminated() {
						return 0 === this.maxIterations && 1 === this.progress
					}
					updateAndDrawForSpinning(t, {
						bounds: s,
						progressAmount: n,
						ringRotation: o,
						lineWidth: h
					}) {
						this.startDelay < 0 && (this.startDelay += n),
							this.startDelay > 0 ? (this.progress += this.startDelay,
								this.startDelay = 0) : 0 === this.startDelay && (this.progress += n),
							this.progress > 1 && (this.maxIterations > 0 && this.maxIterations--,
								0 !== this.maxIterations ? this.progress = this.progress % 1 : this.progress = 1);
						let p;
						this.progress < 0 ? p = 0 : this.progress < .5 ? (p = 2 * this.progress,
								p = 1 - u(1 - p)) : (p = 2 * this.progress - 1,
								p = u(p = 1 - p)),
							this.drawSegment(t, {
								allowShrinkToZero: !0,
								bounds: s,
								ringRotation: o,
								segmentSizeProgress: p,
								activeStrokeWidth: h
							})
					}
					updateAndDrawForStopping(t, {
						bounds: s,
						progressAmount: n,
						ringRotation: o,
						lineWidth: h
					}) {
						this.progress < .5 && (this.progress = 1 - this.progress),
							this.progress += n,
							this.progress > 1 && (this.progress = 1);
						let p = 2 * this.progress - 1;
						p = u(p = 1 - p),
							this.drawSegment(t, {
								allowShrinkToZero: !0,
								bounds: s,
								ringRotation: o,
								segmentSizeProgress: p,
								activeStrokeWidth: h
							})
					}
					drawSegment(t, {
						activeStrokeWidth: n,
						allowShrinkToZero: o,
						bounds: h,
						ringRotation: p,
						segmentSizeProgress: l
					}) {
						t.save();
						let u = 12 * (1 - l);
						o || (u = Math.max(u, .1));
						const c = s + 12 * this.segmentIndex - 6,
							S = p + (c - u / 2);
						if (o) {
							const s = 2 * Math.PI * h.radius * u / 360;
							t.lineWidth = s < n ? s : n
						}
						t.lineWidth !== n && (t.stroke(),
							t.beginPath());
						const D = 2 * S * Math.PI / 360,
							I = 2 * u * Math.PI / 360;
						t.moveTo(h.centerX + Math.cos(D) * h.radius, h.centerY + Math.sin(D) * h.radius),
							t.arc(h.centerX, h.centerY, h.radius, D, D + I),
							t.lineWidth !== n && (t.stroke(),
								t.beginPath()),
							t.restore()
					}
				}
				({
					segmentIndex: c,
					startDelay: -p,
					maxIterations: n
				}))
		}
		this.segments = l
	}
	updateAndDrawSegmentsForSpinning(t, {
		bounds: s,
		progressAmount: n,
		lineWidth: o
	}) {
		this.updateAndDrawSegments(t, {
			bounds: s,
			gradientRotationDuration: l,
			progressAmount: n,
			ringRotationDuration: h,
			lineWidth: o
		})
	}
	updateAndDrawSegmentsForStopping(t, {
		bounds: s,
		progressAmount: n,
		lineWidth: o
	}) {
		t.save(),
			t.beginPath();
		const p = (Date.now() - this.animStartTime) / h * 360 % 360;
		let l = !1;
		for (const h of this.segments)
			h.updateAndDrawForStopping(t, {
				bounds: s,
				progressAmount: n,
				ringRotation: p,
				lineWidth: o
			}),
			1 !== h.progress && (l = !0);
		l || this.setAnimMode(c),
			t.stroke(),
			t.restore(),
			this.invalidate()
	}

	updateAndDrawSegments(t, {
		bounds: s,
		gradientRotationDuration: n,
		progressAmount: o,
		ringRotationDuration: h,
		lineWidth: p
	}) {
		t.save(),
			t.beginPath();
		const l = (Date.now() - this.animStartTime) / h * 360 % 360;
		let u = !1;
		for (const n of this.segments) {
			switch (this.animMode) {
				case S:
					n.updateAndDrawForSpinning(t, {
						bounds: s,
						progressAmount: o,
						ringRotation: l,
						lineWidth: p
					});
					break;
				default:
					throw new Error('unexpected animMode')
			}
			n.isTerminated() || (u = !0)
		}
		u || this.stopSpinning(),
			t.stroke(),
			t.restore(),
			this.invalidate()
	}
}