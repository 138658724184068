import React from 'react'
import {SpriteIcon,Button} from 'components'
import {FollowUser,FollowEvent} from '../Follow'
import { useMin640 } from 'hooks/useMediaQuery'
import UserWithAction from '../UserWithAction'

import styles from './suggestedUser.module.scss'

export interface SuggestedUserProps extends FollowUser,FollowEvent {
  onDeleteClick?: () => void;
}

const SuggestedUser:React.FC<SuggestedUserProps> = ({user,onDeleteClick,...props}) => {
  const min640 = useMin640();
  return (
    <div className={styles['suggested-user']} >
      <div className={styles['suggested-user-content']} >
        <Button type="TEXT" title="关闭" className={styles['user-content-close']} onClick={onDeleteClick}>
          <SpriteIcon ariaLabel="关闭" backgroundSize="440px 411px" size={8} backgroundPosition="-428px -275px" type="880"/>
        </Button>
        <UserWithAction 
          containerFlexDirection="column"
          containerStyle={{width:'100%'}} 
          actionGap={0}
          actionType="FOLLOW" 
          user={user} 
          flexDirection="column" 
          useFullnameDescription={true}
          descriptionClassName={styles['user-content-description']} 
          descriptionStyle={{textAlign:'center'}} 
          usernameClassName={styles['user-content-username']} 
          isVerified={user.isVerified}  
          gap={min640 ? 20 : 8} 
          avatarSize={min640 ? 54 : 77} 
          actionStyle={{width:'100%'}}
          {...props}/>
      </div>
    </div>
  )
}

export default SuggestedUser;
