import React,{forwardRef} from 'react'
import {Button,Spin} from 'components'
import AutosizeTextarea,{AutosizeTextareaProps} from './AutosizeTextarea'
import styles from './commentInput.module.scss'


export interface CommentInputProps extends AutosizeTextareaProps {
  hasSendBtn?: boolean;
  loading?: boolean;
  onSend?:(text:any) => void;
}

const CommentInput = ({hasSendBtn,value='',loading=false,onSend=() =>{},...props}:CommentInputProps,ref: React.RefObject<any>) => {


  return (
    <AutosizeTextarea value={value}  maxRows={6} ref={ref} {...props} >
      {hasSendBtn && <Button onClick={() => { onSend(value);}} className={styles['button']} type="TEXT" disabled={ String(value).length == 0 ? true : false }>发布</Button>}
      <Spin visible={loading} size={32} className={styles['spinning']} />
    </AutosizeTextarea>
  )
}

export default forwardRef(CommentInput);