import React, { useEffect, useState, useRef,useCallback } from 'react'
import {TagButton,Button} from 'components'
import {UserSkeletonList,UserWithAction,User} from '../index'
import { debounce } from 'throttle-debounce';
import { connect } from 'react-redux';
import * as directSearchUserAction from 'store/actions/directSearchUserAction'
import styles from './styles.module.scss'


const Suggested: React.FC<UsersWithRadioProps> = ({ ...props }) => {

  return (
    <div className={styles['suggested']}>
      <h2 className={styles['suggested-title']}>推荐</h2>
      <UsersWithRadio {...props} />
    </div>
  )
}

interface SearchUserProps extends UsersWithRadioProps {
  isFetching?: boolean;
}

const SearchUser: React.FC<SearchUserProps> = ({ isFetching,...props }) => {
  return (
    <>
      {isFetching ? <UserSkeletonList avatarSize={56} gap={12} flexDirection="row" describeHeight={14} describeRaduis={4} descriptionTopWidth={116} descriptionBottomWidth={82} visible={true} /> : <UsersWithRadio {...props} />}
    </>
  )
}

interface UserRadio extends User {
  checked?: boolean;
  closable?: boolean;
}

interface UsersWithRadioProps {
  users?: UserRadio[];
  onChange?: (user: UserRadio,checked:boolean) => void;
}

const UsersWithRadio: React.FC<UsersWithRadioProps> = ({ users=[], onChange = () => { } }) => {

  return (
    <div className={styles['users-with-radio']}>
      {users.map((user) => {
        return (
          <Button type="TEXT" className={styles['with-radio-btn']}>
            <UserWithAction actionType="RADIO" containerStyle={{flex:1}} hasLink={false} avatarSize={44} gap={12} user={user} useFullnameDescription={true} checked={user.checked} onChange={(checked) => { onChange(user,checked ) }} />
          </Button>
        ) 
      })}
    </div>
  )
}


interface SearchUserInputProps {
  selectedUsers?: UserRadio[];
  onDeleteUser?: (user:User) => void;
  onSearch?: (value:string) => void;
}

const SearchUserInput: React.FC<SearchUserInputProps> = ({selectedUsers,onDeleteUser = () => {},onSearch}) => {
  const [ownSelectedUsers,setOwnSelectedUsers] = useState<UserRadio[]>([]);
  const inputRef = useRef(null);
  

  const _onSearch = useCallback(debounce(300,onSearch),[])

  useEffect(() => {
    inputRef.current.scrollIntoView();
  }, [ownSelectedUsers])

  useEffect(() => {
    setOwnSelectedUsers(selectedUsers);
  },[selectedUsers])

  const setClosable = useCallback((user) => {
    console.log(user.closable);
    if(user.closable) {
      onDeleteUser(user);
    } else {
      setOwnSelectedUsers((prev) => {
        return prev.map(item => {
          item.closable = item.id == user.id;
          return item;
        });
      });
    }
  },[onDeleteUser])

  const onFocus = () => {
    setOwnSelectedUsers(ownSelectedUsers.map(item => {
      item.closable = false;
      return item;
    }));
  }

  return (
    <div className={styles['search-user-input']}>
      <h4 className={styles['user-input-title']}>收件人：</h4>
      <div className={styles['user-input-content']}>
        <div className={styles['input-content-tags']}>
          {ownSelectedUsers.map(user => <TagButton onClick={() => { setClosable(user) }} closable={user.closable} onDeleteClick={() => { onDeleteUser(user) }}>{user.username}</TagButton>)}
        </div>
        <input onChange={(e) => { _onSearch(e.target.value); }} ref={inputRef} onFocus={onFocus}  className={styles['input-content-inner']} placeholder="搜索" />
      </div>
    </div>
  )
}


interface DirectSearchUserProps {
  suggested?: UserRadio[];
  searchUsers?: UserRadio[];
  onSearch?: (value:string) => void;
  onSelectedUsersChange?: (users:UserRadio[]) => void;
  isFetching?: boolean;
  fetchSuggested?: () => {}
}

const DirectSearchUser: React.FC<DirectSearchUserProps> = ({fetchSuggested,isFetching,suggested,searchUsers,onSearch,onSelectedUsersChange=() => {}}) => {
  const [selectedUsers,setSelectedUsers] = useState<UserRadio[]>([]);
  const [searchValue,setSearchValue] = useState<string>('');


  const onChange = useCallback((user,checked) => {
    if(checked) {
      user.closable = false;
      setSelectedUsers((prev) => {
        return [...prev,user]
      })
    } else {
      setSelectedUsers((prev) => {
        return prev.filter(item => item.id != user.id);
      })
    }
  },[])

  useEffect(() => {
    onSelectedUsersChange(selectedUsers);
    console.log(selectedUsers);
  },[selectedUsers])

  useEffect(() => {
    fetchSuggested();
  },[])


  return (
    <div className={styles['direct-search-user']}>
      <SearchUserInput 
        selectedUsers={selectedUsers}
        onSearch={(v) => {setSearchValue(v);onSearch(v)  }}
        onDeleteUser={(user) => {
          setSelectedUsers(prev => prev.filter(item => item.id != user.id))
        }}
      />
      {!searchValue.length && <Suggested 
        users={suggested.map(item => {
          item.checked = selectedUsers.some(it => it.id == item.id);
          return item;
        })}
        onChange={onChange}
      />}
      <SearchUser 
        isFetching={isFetching}
        users={searchUsers.map(item => {
          item.checked = selectedUsers.some(it => it.id == item.id);
          return item;
        })}
        onChange={onChange}
      />
    </div>
  )
}

export default connect((state:any) => {
  return {
    isFetching: state.directSearchUser.isFetching,
    suggested: state.directSearchUser.suggested,
    searchUsers: state.directSearchUser.searchUsers
  }
},{
  fetchSuggested:directSearchUserAction.fetchSuggested,
  onSearch:directSearchUserAction.fetchSearchUser
})(DirectSearchUser);