import { DirectSearchUserActionType } from 'constants/directSearchUserActionType'


const initialState = {
  suggested :[],
  searchUsers: [],
  isFetching: false,
}

const directSearchUserReducer = (state=initialState,action) => {
  switch(action.type) {
    case DirectSearchUserActionType.DIRECT_SEARCH_USER_SET_SUGGESTED :
      return {
        ...state,
        suggested:action.suggested
      }
    case DirectSearchUserActionType.DIRECT_SEARCH_USER_SET :
      return {
        ...state,
        searchUsers:action.searchUsers
      }
    case DirectSearchUserActionType.DIRECT_SEARCH_USER_CHANGE_FETCH_STATUS:
      return {
        ...state,
        isFetching : action.isFetching
      }
    default:
      return state;
  }
}



export default directSearchUserReducer;