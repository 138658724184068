import React from 'react'
import styles from './confirmModal.module.scss'
import BodyRender from 'utils/BodyRender'
import {ListModal ,ListModalProps,ButtonType } from 'components'

export interface ConfirmModalProps extends ListModalProps {
  title?: React.ReactNode;
  text?: React.ReactNode;
  titleChild?: React.ReactNode;
  children?: React.ReactNode;
  sureText?: React.ReactNode;
  onOk?: () => void;
  sureBtnType?: ButtonType
}


const ConfirmModal: React.FC<ConfirmModalProps> = ({ titleChild, title, text,onOk=() => {},onCancel=() => {},sureText,children,sureBtnType="WARNING", ...props }) => {

  return (
    <ListModal onCancel={onCancel} {...props} >
      <ListModal.Item style={{padding:0}}>
        <div className={styles['confirm-modal']}>
          {titleChild}
          <h3 className={styles['confirm-modal-title']}>{title}</h3>
          <p className={styles['confirm-modal-text']}>{text}</p>
        </div>
      </ListModal.Item>
      {children}
      {sureText && <ListModal.Item type={sureBtnType} onClick={() => { onOk(); onCancel()}}>{sureText}</ListModal.Item>}
    </ListModal>
  );
}

export class Confirm {
  static show({onCancel=() => {},...props}:ConfirmModalProps) {
    BodyRender.render(<ConfirmModal onCancel={() => { BodyRender.unmount();onCancel() }} visible={true} keepStructure={true} {...props} />)
  }
}

export default ConfirmModal;
