import FilterProgram from './FilterProgram'

class FilterBuilder {
  $FilterBuilder1: any[]
  $FilterBuilder2: any
  $FilterBuilder3: any
  $FilterBuilder4: any
  $FilterBuilder5: any

  constructor() {
    this.$FilterBuilder1 = []
  }
  setName(t) {
    return this.$FilterBuilder2 = t,
      this
  }
  setShader(t) {
    return this.$FilterBuilder3 = t,
      this
  }
  setThumbnail(t, l) {
    return this.$FilterBuilder4 = t,
      this.$FilterBuilder5 = l,
      this
  }
  addTexture(t, l) {
    return this.$FilterBuilder1.push([t, l]),
      this
  }
  getName() {
    return this.$FilterBuilder2
  }
  getThumbnail(t) {
    return t > 1.5 ? this.$FilterBuilder5 : this.$FilterBuilder4
  }
  createFilter(t) {
    return new FilterProgram(t, this.$FilterBuilder2, this.$FilterBuilder3, this.$FilterBuilder1)
  }
}

export default FilterBuilder;
