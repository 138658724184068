import React from 'react'
import { SpriteIcon } from 'components'
import { Link } from 'react-router-dom';
import {User} from '../index'
import useMutilUsername from 'hooks/useMutilUsername'
import styles from './styles.module.scss'


export interface UsernameProps {
  className?: string;
  style?: React.CSSProperties;
  username?: React.ReactNode;
  isVerified?: boolean;
  verifiedBadgeSize?: 'LARGE' | 'SMALL';
  fullname?: string;
  hasLink?: boolean;
  hasVerifiedBadge?: boolean;
}

const Username: React.FC<UsernameProps> = ({ hasLink = true, className, verifiedBadgeSize = 'SMALL', style, username, isVerified = false, fullname,hasVerifiedBadge=true, children }) => {
  const UsernameTag: any = hasLink ? Link : 'span'
  const backgroundPositionMap = {
    'LARGE': '-194px -351px',
    'SMALL': '-428px -142px'
  }
  const sizeMap = {
    'LARGE': 18,
    'SMALL': 12
  }


  return (
    <div className={`${styles['username']} ${className}`} style={style}>
      <UsernameTag to={`/${fullname}`} className={styles['username-text']}>{username}</UsernameTag>
      {isVerified && hasVerifiedBadge && <SpriteIcon style={{ margin: '0 4px', flex: '0 0 auto' }} ariaLabel="已验证" backgroundPosition={backgroundPositionMap[verifiedBadgeSize]} backgroundSize="440px 411px" type="880" size={sizeMap[verifiedBadgeSize]} />}
      {children}
    </div>
  )
}


interface UsernameGroupProps extends UsernameProps {
  users?: User[];
  containerClassname?: string;
}

export const UsernameGroup:React.FC<UsernameGroupProps> = ({users=[],containerClassname,children,...props}) => {
  
  const {usernameSplit}  = useMutilUsername(users);

  return (
    <div className={`${styles['username-group']} ${containerClassname}`}>
      {users.slice(0,3).map((user,index) => {
        return (
          <><Username {...props} username={user.username} fullname={user.fullname} /> { usernameSplit[index] }</>
        )
      })}
      {children}
    </div>
  )
}

export default Username;
