import React, { useEffect,useMemo } from 'react'
import PostSkeleton from '../PostSkeleton'
import {VirtualList,ObserverLoad} from 'components'
import {User,Post,PostEvent,SuggestedInTray,CommentItem,PostItem} from '../index'
import { connect } from 'react-redux';

import {getComment} from 'utils/getMockData'
import * as feedAction from 'store/actions/feedAction'
import * as postDetailAction from 'store/actions/postDetailAction'
import * as storyAction from 'store/actions/storyAction'

import styles from './styles.module.scss'

interface Feed extends PostItem {
  feedType?: 'POST' | 'SUGGESTEDUSERS';
}

interface FeedProps extends PostEvent {
  isFetching?: boolean;
  hasSkeleton?: boolean;
  feeds?: Feed[];
  fetchFeeds?: (isAppend:boolean,effectFetchStatus:boolean) => Promise<void>;
  onAddFeed?: (index: number, item: any) => void;
  onAddComment?: (comment:CommentItem,feedId?:string) => void;
  currentUser?: User;
  hasSuggestedUsers?: boolean;
}

const Feed: React.FC<FeedProps> = ({ onChangeFollowStatus, hasSuggestedUsers = false, isFetching, feeds = [], fetchFeeds, onAddFeed,onAddComment,currentUser,hasSkeleton,...props}) => {

  useEffect(() => {
    !feeds.length && fetchFeeds(false,true).then(() => {
      hasSuggestedUsers && onAddFeed(2, { feedType: 'SUGGESTEDUSERS' })
    })
  }, [])

  const onSendComment = (text,feedId) => {
    onAddComment(getComment(currentUser,text),feedId)
  }

  const posts = useMemo(() => {
    return  feeds.map((post,index) => {
      switch (post.feedType) {
        case 'POST':
          return <Post onSendComment={(text) => { onSendComment(text,post.id) }} hasOpenPostDetail={true} hasVerified={false} onChangeFollowStatus={onChangeFollowStatus} post={post}  {...props as PostEvent} key={index} />
        case 'SUGGESTEDUSERS':
          return <SuggestedInTray title="推荐用户" className={styles['suggested']}/>
      }
    })
  },[feeds])

  const onLoad = () => {
    fetchFeeds(true,false)
  }

  return (
    <>
      {isFetching && hasSkeleton ?
        <PostSkeleton visible={isFetching} /> :
        <VirtualList
          renderer={({ index }) => {
            return posts[index];
          }}
          initialRenderCount={2}
          skipChildMeasurement={false}
          direction="vertical"
          itemCount={posts.length}
          estimatedItemSize={800}
          overscanCount={3} />}
        <ObserverLoad 
          onLoad={onLoad} 
          height={48} 
          style={{marginTop:40}} 
          visible={posts.length != 0} 
        />
    </>
  )
}


export default connect((state: any) => {
  return {
    isFetching: state.feed.isFetching,
    feeds: state.feed.feeds,
    currentUser: state.user.user
  }
}, {
  fetchFeeds: feedAction.fetchFeeds,
  onAddFeed: feedAction.addFeed,
  onChangeLikeStatus: feedAction.changeLikeStatus,
  onChangeSaveStatus: feedAction.changeSaveStatus,
  onChangeCommentLikeStatus: feedAction.changeCommentLikeStatus,
  onChangeFollowStatus: feedAction.changeFollowStatus,
  onSetPostDetail: postDetailAction.setPostDetail,
  onAddComment: feedAction.addComment,
  onDeletePost: feedAction.deleteFeed,
  onChangeAvatarLoadingStatus: feedAction.changeAvatarLoadingStatus,
  onChangeViewerSeenStory: feedAction.changeViewerSeenStory,
  fetchStory:storyAction.fetchStory
})(Feed);