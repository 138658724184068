import React,{useRef,useState,useCallback} from 'react'
import {ListModal} from 'components'
import {FeedLike,LinkifyText,Report} from 'containers/Commons'
import { useDoubleTap } from 'use-double-tap';
import { useLongPress } from 'use-long-press';
import { connect } from 'react-redux';
import MessageLiked,{MessageLikedProps} from './MessageLiked'
import copy from 'copy-to-clipboard';
import styles from './bubble.module.scss'
import { User } from '../UserCell';


export type BubbleType = 'TEXT' | 'IMG' | 'LIKE' | 'FEED' | 'EMOJI' | 'USER';

export interface BubbleProps extends MessageLikedProps {
  type?: BubbleType;
  content?: any;
  children?: React.ReactNode;
  onLiked?: () => void;
  owner?: User;
  onUnSendClick?: () => void;
}

const Bubble:React.FC<BubbleProps> = ({type,content,children,onRemoveLike,likedUsers=[],onLiked = () => {},currentUser,owner,onUnSendClick =() => {}}) => {

  const ref = useRef(null);
  const [actionVisible,setActionVisible] = useState<boolean>(false);
  const [reportVisible,setReportVisible] = useState<boolean>(false);
  const longPressBind = useLongPress(() => {
    setActionVisible(true)
  },{threshold:1000});

  const isFeed = type == 'FEED';
  const isOwn = currentUser && owner && currentUser.id === owner.id;
  const hasFeedLike = type == 'IMG' || isFeed;
  const hasContent = type == 'TEXT' || type == 'EMOJI';
  const hasCopy = hasContent || isFeed;
  const hasLikedUser = likedUsers.length > 0;

  const bind = useDoubleTap(() => {
    if(hasFeedLike) {
      ref?.current?.showLike();
    }
    onLiked();
  })

  const onCopy = useCallback(() => {
    switch(type) {
      case 'EMOJI': case 'TEXT':
        copy(content as string);
      break;
      case 'FEED':
        copy(`${window.location.origin}/postDetail/${content.id}`);
      break;
    }
    setActionVisible(false)
  },[content,type])

  return (
    <>
      <div {...longPressBind}>
        <div role={type} {...bind} className={`${styles['bubble']} ${styles[type]} ${hasLikedUser ? styles['bubble-liked-users'] : styles['none-liked-users']}`} >
          {hasContent && <p className={styles['bubble-text']}><LinkifyText text={content} /></p>}
          {hasFeedLike && <FeedLike ref={ref}/>}
          {children}
        </div>
        { hasLikedUser && <MessageLiked likedUsers={likedUsers} onRemoveLike={onRemoveLike} currentUser={currentUser} />}
      </div>
      <ListModal visible={actionVisible} onCancel={() => { setActionVisible(false) }} >
        {hasCopy && <ListModal.Item onClick={onCopy}>复制</ListModal.Item>}
        {isOwn ? <ListModal.Item onClick={() => { onUnSendClick();setActionVisible(false) }}>撤回</ListModal.Item> : <ListModal.Item type="WARNING" onClick={() => { setActionVisible(false);setReportVisible(true)}} >举报</ListModal.Item>}
      </ListModal>
      <Report visible={reportVisible} user={owner} onClose={() => { setReportVisible(false) }} />
    </>
  )
}


export default connect((state: any) => {

  return {
    currentUser: state.user.user
  }
})(Bubble)