import React from 'react'
import styles from './styles.module.scss'

interface DotProps  {
  size?: 'DEFAULT' | 'MEDIUM' | 'SMALL' | 'TINY' | 'ATOMIC' | 'SUPERATOMIC';
  color?: string;
}


const Dot:React.FC<DotProps & React.HTMLAttributes<HTMLDivElement>> = ({size="DEFAULT",className,color = '#78de45',style,...props}) => {
  return (
    <div className={`${styles['dot']} ${styles[size]} ${className}`} style={{backgroundColor:color,...style}} {...props} />
  )
}

export default Dot;