import React from 'react'
import BodyRender from 'utils/BodyRender'
import styles from './styles.module.scss'


interface LoadingBarProps {
  height?: number;
  onAnimationend?: (e:React.AnimationEvent) => void
}

const LoadingBar:React.FC<LoadingBarProps> = ({ height = 3,onAnimationend = () => {}}) => {

  return (
    <div className={styles['loading-bar']} style={{height}} onAnimationEnd={onAnimationend}/>
  )

}

export class Loading {
  loadingBarEntered: boolean
  isDone:boolean;
  isRunning:boolean;

  constructor() {
    this.loadingBarEntered = false;
    this.isDone = false;
    this.isRunning = false;
  }
  ummount() {
    BodyRender.unmount();
    this.isDone = false;
    this.isRunning = false;
    this.loadingBarEntered = false;    
  }
  onAnimationend({animationName}:React.AnimationEvent) {
    if(animationName == styles['LoadingBarEnter']) {
      this.loadingBarEntered = true;
      if(this.isDone) {
        this.ummount();
      }
    }
  }
  start({height}:LoadingBarProps) {
    this.isDone = false;
    !this.isRunning && BodyRender.render(<LoadingBar height={height} onAnimationend={this.onAnimationend.bind(this)}/>);
    this.isRunning = true;
  }
  
  done() {
    if(this.loadingBarEntered) {
      this.ummount();
    } else {
      this.isDone = true;
    }
  }
}

export const loading = new Loading();

export default LoadingBar;
