import React, { useState, useRef, useEffect, useImperativeHandle,createContext,useContext,useCallback } from 'react'
import styles from './styles.module.scss'
import { useSwipeable } from "react-swipeable";
import useViewport from 'hooks/useViewport'


const CubeEffectContext = createContext(null);

interface CubeEffectItemProps {
  index:number;
}


export const CubeEffectItem:React.FC<CubeEffectItemProps> = ({index,children}) => {
  const {currentPage,width, setCubeEffectItemNum,isSwiping} = useContext(CubeEffectContext);
  const filterMap = [-1,0,1];
  const step = index - currentPage;
  const classNameMap = {
    '-1' : 'prev',
    '0' : 'current',
  }

  useEffect(() => {
    setCubeEffectItemNum(i => i + 1);
  },[])

  return (
    <>
      {filterMap.includes(step) && <div className={`${styles['effect-content-item']} ${styles[classNameMap[step]]}`} style={{ width,transform: step == 1 ? `rotateX(0deg) rotateY(180deg) translate3d(${width}px, 0px, ${width}px)`: '' }}>
        {(currentPage == index || isSwiping) && children}
      </div>}
    </>
  )
}


interface CubeEffectProps {
  children?: React.ReactNode;
  onClose?: () => void;
  onPageChange?: (page: number) => void;
  activePage?: number;
}

const CubeEffect = ({ children,activePage=0, onClose = () => { }, onPageChange = () => { } }: CubeEffectProps, ref: React.RefObject<any>) => {
  const [currentPage, setCurrentPage] = useState<number>(activePage);
  const [width, setWidth] = useState<number>(0);
  const [cubeEffectItemNum, setCubeEffectItemNum] = useState<number>(-1);
  const [isSwiping,setIsSwiping] = useState<boolean>(false);

  const {viewportWidth} = useViewport();
  const cubeEffectEl = useRef<HTMLDivElement>(null);
  const rotateY = useRef(0);

  const swipe = useCallback((dir: 'Left' | 'Right' | 'Up') => {
    
    if(dir == 'Right' && currentPage > 0 || dir == 'Left' && currentPage < cubeEffectItemNum - 1) {
      cubeEffectEl.current.style.transitionDuration = '300ms';
      if (dir == 'Right' && currentPage > 0) {
        rotateY.current = 0;
      } else {
        rotateY.current = -180;
      }
      cubeEffectEl.current.style.transform = `translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(${rotateY.current}deg)`;
      setIsSwiping(true);
    }
  },[currentPage,cubeEffectItemNum,cubeEffectEl])

  useImperativeHandle(ref, () => ({
    swipe
  }))

  useEffect(() => {
    onPageChange(currentPage);
  }, [currentPage]);

  useEffect(() => {
    const { width } = cubeEffectEl.current.parentElement.getBoundingClientRect();
    setWidth(width);
    cubeEffectEl.current.style.transformOrigin = `50% 50% -${width / 2}px`
  }, [viewportWidth,cubeEffectEl]);

  const handlers = useSwipeable({
    onSwiped({ dir }) {
      if (dir == 'Down') return onClose();
      swipe(dir);
    }
  })

  const onTransitionEnd = useCallback((e) => {
    e.stopPropagation();
    setIsSwiping(false);
    if(e.target != cubeEffectEl.current) return;
    cubeEffectEl.current.style.transitionDuration = '';
    setCurrentPage(rotateY.current == 0 ? currentPage - 1 : currentPage + 1);
    cubeEffectEl.current.style.transform = `translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(-90deg)`;
  },[currentPage,cubeEffectEl])

  return (
    <CubeEffectContext.Provider value={{width,currentPage,isSwiping, setCubeEffectItemNum}}>
      <div  {...handlers} className={styles['cube-effect']} style={{transformOrigin:`top left ${width/2}px`}}>
        <div className={styles['cube-effect-content']} ref={cubeEffectEl} onTransitionEnd={onTransitionEnd}>
          <CubeEffectItem index={-1} />
          {children}
        </div>
      </div>
    </CubeEffectContext.Provider>
  )
}

export default React.forwardRef(CubeEffect);