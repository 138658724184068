import React from 'react'
import styles from './styles.module.scss'
import { ListModalProps } from 'components'
import {UserActionConfirm,User} from '../index'

export interface UnfollowConfirmProps extends ListModalProps {
  user?: User;
  onUnfollowClick?: (id?:string) => void;
}


const UnfollowConfirm: React.FC<UnfollowConfirmProps> = ({ user = {}, onUnfollowClick = () => { }, ...props }) => {
  const { username } = user;
  return (
    <UserActionConfirm user={user} sureText="停止关注" onSure={onUnfollowClick} {...props}>
      <div className={styles['unfollow-confirm-username']}>停止关注@{username}？</div>
    </UserActionConfirm>
  );
}

export default UnfollowConfirm;
