import React, { useRef,useState } from 'react'
import PostTags from './PostTags'
import {Image,Video,Carousel,CarouselItem} from 'components'
import {FeedLike,TV,User,MediaTaggedUser} from '../index'
import { useDoubleTap } from 'use-double-tap';

export interface Dimensions {
  width?:number;
  height?:number;
}

export interface Media {
  accessibilityCaption?: string;
  dimensions?: Dimensions;
  displayUrl?: string;
  taggedUsers?: MediaTaggedUser[];
  isVideo?: boolean;
  id?: string;
  videoUrl?: string;
  videoDuration?: number;
  viewers?: User[];
  videoViewCount?: number;
  isTv?: boolean;
  title?: string;
  caption?: string;
}


interface PostCarouselProps {
  medias?: Media[];
  dimensions?: Dimensions;
  onLiked?:() => void;
  onTagClick?:(users:MediaTaggedUser[]) => void;
  owner?: User
}

const PostCarousel: React.FC<PostCarouselProps> = ({medias=[],owner,dimensions,onLiked=() => {},onTagClick=() => {}}) => {
  const [TvVisisble,setTvVisible] = useState<boolean>(false);
  const [activeMedia,setActiveMedia] = useState<Media>(null);

  const ref = useRef(null);

  const bind = useDoubleTap(() => {
    ref?.current?.showLike();
    onLiked();
  })

  const onIconClick = (isTv,isVideo,taggedUsers,media) => {
    if(isTv) {
      setActiveMedia(media);
      setTvVisible(true);
    } else {
      isVideo && onTagClick(taggedUsers)
    }
  }

  return (
    <>
      <div {...bind}>
        <Carousel imgWidth={dimensions.width} imgHeight={dimensions.height}>
          {medias.map(media => {
            const {id,displayUrl,isVideo,videoUrl,accessibilityCaption,taggedUsers=[],isTv} = media;

            return (<CarouselItem key={id}>
              {isVideo ? <Video src={videoUrl} poster={displayUrl} alt={accessibilityCaption}/> : <Image src={displayUrl} alt={accessibilityCaption} />}
              {taggedUsers.length > 0 && <PostTags onIconClick={() => { onIconClick(isTv,isVideo,taggedUsers,media) }} hasTag={!isVideo} isTv={isTv} mediaTaggedUsers={taggedUsers}/>}
            </CarouselItem>)
          })}
        </Carousel>
        <FeedLike ref={ref} />
      </div>
      {TvVisisble && <TV onBackClick={() => { setTvVisible(false) }} user={owner} videoUrl={activeMedia.videoUrl} videoViewCount={activeMedia.videoViewCount} />}
    </>
  );
}

export default React.memo(PostCarousel);
