import * as React from 'react'
import styles from './postActions.module.scss'
import {LikeButton}  from 'containers/Commons'
import {SVG} from 'components'

interface PostActionsProps {
  liked?:boolean;
  saved?:boolean;
  onLikeClick?:() => void;
  onSaveClick?: () => void;
  onDirectClick?: () => void;
  onCommentClick?: () => void;
}


const PostActions: React.FC<PostActionsProps> = ({liked,saved,onLikeClick,onSaveClick,onDirectClick,onCommentClick}) => {
  return (
    <div className={styles['post-actions']}>
      <LikeButton  className={styles['post-actions-btn']} liked={liked} size={24} onClick={onLikeClick}/>
      <SVG  className={styles['post-actions-btn']} ariaLable="评论"  type="COMMENT" onClick={onCommentClick} />
      <SVG  className={styles['post-actions-btn']} ariaLable="分享帖子"  type="DIRECT" onClick={onDirectClick} />
      <SVG  className={styles['post-actions-btn']} ariaLable="收藏"  type={saved ? "SAVEDACTIVE" : "SAVED"} onClick={onSaveClick} />
    </div>
  );
}

export default PostActions;
