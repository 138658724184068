import { useState,useEffect } from "react";
import {debounce} from 'throttle-debounce'


interface viewportDimensions {
  viewportWidth?: number;
  viewportHeight?: number;
}

function useViewport() {  
  const [viewportDimensions,setViewportDimensions] = useState<viewportDimensions>({viewportHeight:window.innerHeight,viewportWidth:window.innerWidth});


  useEffect(() => {
    const setViewport = debounce(200,() => {
      setViewportDimensions({viewportHeight:window.innerHeight,viewportWidth:window.innerWidth})
    })

    window.addEventListener('resize',setViewport);
    setViewport();
    return () => {
      window.removeEventListener('resize',setViewport);
    }

  },[])

  return viewportDimensions

}

export default useViewport;
