import React from 'react'
import styles from './styles.module.scss'

export interface SpriteIconProps extends React.HTMLAttributes<HTMLSpanElement> {
  backgroundSize: string;
  backgroundPosition: string;
  ariaLabel?: string;
  size?: number;
  type?: '1020' | '880' | '358' | '1088' | '178' | '1138' | '542' | '418';
  className?: string;
  style?: React.CSSProperties;
}

const SpriteIcon:React.FC<SpriteIconProps> = ({backgroundSize,ariaLabel ,backgroundPosition, size,style,className,
type = '1020',...props}) => {
  return <span aria-label={ariaLabel} className={`${styles[`sprite-icon`]} ${className}`} style={{backgroundSize,backgroundPosition,width:size, height:size,...style,backgroundImage:`url(${require(`./images/${type}.png`)})`}} {...props}/>
}

export default SpriteIcon;
