import React from 'react'
const routerObserveQueue = [] /* 存放路由卫视钩子 */
/* 懒加载路由卫士钩子 */
export const RouterHooks = {
  /* 路由组件加载之前 */
  beforeRouterComponentLoad: function(callback) {
    routerObserveQueue.push({
      type: 'before',
      callback
    })
  },
  /* 路由组件加载之后 */
  afterRouterComponentDidLoaded(callback) {
    routerObserveQueue.push({
      type: 'after',
      callback
    })
  }
}
/* 路由懒加载HOC */
export default function withAsyncRouter(loadRouter) {
  return class Component extends React.Component {
    constructor(props) {
      super(props)
      /* 触发每个路由加载之前钩子函数 */
      this.dispatchRouterQueue('before')
    }
    state = {Component: null}
    dispatchRouterQueue(type) {
      routerObserveQueue.forEach(item => {
        if (item.type === type) item.callback(this.props)
      })
    }
    componentWillMount() {
      window.scrollTo(0, 0);
    }
    componentDidMount() {
      document.title = this.props.name;
      if (this.state.Component) return
      
      loadRouter()
        .then(module => module.default)
        .then(Component => {
          this.setState({Component});
          this.dispatchRouterQueue('after')
        })
    }
    render() {
      const {Component} = this.state
      return Component ? <Component {
      ...this.props
      }
      /> : null
    }
  }
}