import React from 'react'
import {ViewerFollowStatus} from '../index'
import {ListModal,ListModalProps} from 'components'


interface MoreOptionsProps extends ListModalProps,ViewerFollowStatus{
  onUnfollowClick?: () => void;
  onReportClick?: () => void;
  onOpenPostClick?: () => void;
  onShareClick?: () => void;
  onCopyLikClick?: () => void;
  onTaggedUserClick?: () => void;
  onDeletePostClick?: () => void;
  hasOpenPostDetail?: boolean;
  hasTaggedUser?: boolean;
  isOwn?: boolean;
}

const MoreOptions:React.FC<MoreOptionsProps> = ({onDeletePostClick,isOwn,hasOpenPostDetail,viewerFollowStatus,onShareClick,onOpenPostClick,onReportClick,onUnfollowClick,onCopyLikClick,onCancel,hasTaggedUser,onTaggedUserClick,...props}) => {
  const isFollowing = viewerFollowStatus == 'FOLLOWING';
  
  const options:any = [
    {
      text: '分享到...',
      onClick:onShareClick
    },
    {
      text: '复制链接',
      onClick: onCopyLikClick
    },
  ]

  if(isOwn) {
    options.splice(0,0,{
      text: '删除',
      warning: true,
      onClick:onDeletePostClick
    })
  } else {
    options.splice(0,0,{
      text: '举报',
      warning: true,
      onClick:onReportClick
    })
  }

  if(isFollowing && !isOwn) {
    options.splice(1,0,{
      text: '停止关注',
      warning: true,
      onClick:onUnfollowClick
    })
  }

  if(hasOpenPostDetail) {
    options.splice(isFollowing ? 2 : 1,0,{
      text: '打开帖子',
      onClick:onOpenPostClick
    })    
  }

  if(hasTaggedUser) {
    options.splice(3,0,{
      text: '标记的账户',
      onClick:onTaggedUserClick
    })     
  }

  return (
    <ListModal onCancel={onCancel}  {...props} >
      {options.map(({warning,onClick,text}) => {
        return <ListModal.Item type={warning ? 'WARNING' : 'DEFAULT'} onClick={() => {onCancel();onClick()}}>{text}</ListModal.Item>
      })}
    </ListModal>
  )
}


export default MoreOptions;