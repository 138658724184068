import { SearchActionType } from 'constants/searchActionType'
import {getSearchResult} from 'services/commons/search'

export const changFetchStatus = (isFetching) => {
  return {
    type: SearchActionType.SEARCH_CHANGE_FETCH_STATUS,
    isFetching
  }
}

export const setSearchResult = (result) => {
  return {
    type: SearchActionType.SEARCH_SET,
    result
  }  
}

export const search = (type) => {
  return async (dispatch) => {
    dispatch(changFetchStatus(true));
    const result:any = await getSearchResult();
    dispatch(setSearchResult(result.filter(item => item.type == type)));
    dispatch(changFetchStatus(false));
  }
}