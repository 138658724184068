export default {
  "IMAGE": 1,
  "VIDEO": 2,
  "ALBUM": 3,
  "WEBVIEW": 4,
  "BUNDLE": 5,
  "MAP": 6,
  "BROADCAST": 7,
  "CAROUSEL_V2": 8,
  "COLLECTION": 10,
  "AUDIO": 11,
  "ANIMATED_MEDIA": 12,
  "STATIC_STICKER": 13
}