import React, { useState, useEffect, useRef,useImperativeHandle,useCallback } from 'react'
import styles from './styles.module.scss'
import {Image,ImageProps,Spin,SpriteIcon} from 'components'
import { useInView } from 'react-intersection-observer';

interface VideoProps extends ImageProps {
  poster?: string;
  loop?: boolean;
  hasLoading?: boolean;
  onEnded?: () => void;
  onTimeUpdate?: (per?: number) => void;
  hasPlayBtn?: boolean;
  style?: React.CSSProperties;
  onPlayStatusChange?: (paused:boolean) => void;
}

const Video = ({hasPlayBtn=true ,style,poster, onPlayStatusChange=() => {},src,loop = false, onEnded = () => { },hasLoading=false, onTimeUpdate = () => { },...props}:VideoProps,_ref: React.RefObject<any>) => {
  const [played, setPlayed] = useState<boolean>(false);
  const [paused, setPaused] = useState<boolean>(true);
  const [duration, setDuration] = useState<number>(0);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const videoEl = useRef<HTMLVideoElement>(null);

  const { ref, inView } = useInView({
    threshold: 0.2,
  })

  useImperativeHandle(_ref, () => ({
    pause() {
      videoEl?.current?.pause();
    },
    play() {
      videoEl?.current?.play();
    },
    togglePlay
  }))

  useEffect(() => {
    !inView && videoEl?.current?.pause();
  }, [inView])

  useEffect(() => {
    setPaused(true);
  },[src])

  useEffect(() => {
    onPlayStatusChange(paused);
  },[paused])

  const togglePlay = useCallback(() => {
    setPlayed(true); 
    paused ? videoEl.current.play() : videoEl.current.pause();
  },[paused])

  return (
    <div ref={ref} className={styles['video-player']} style={style}>
      <video 
        onProgress={() => { setLoading(true) }} 
        onCanPlayThrough={() => { setLoading(false) }} 
        onTimeUpdate={(e) => { played && onTimeUpdate(e.currentTarget.currentTime / duration) }} 
        onCanPlay={() => { setError(false) }} 
        onError={(e) => { setError(true) }} 
        onLoadedData={(e) => { setDuration(e.currentTarget.duration) }} 
        ref={videoEl} 
        onEnded={() => { setPaused(true);onEnded(); }} 
        onPause={() => { setPaused(true) }} 
        onPlay={() => { setPaused(false);setPlayed(true);  }} 
        className={styles['video']} 
        src={src} 
        loop={loop} 
        playsInline={true} 
      />
      {!played && <Image src={poster} style={{ position: 'absolute', top: 0, left: 0, objectFit: 'cover' }} {...props}/>}
      <div className={styles['video-player-status']}>
        {!error && hasPlayBtn && <SpriteIcon type="542" size={135} backgroundPosition="0 0"  backgroundSize="271px 149px" className={styles['player-status-play']} style={{ opacity: paused ? 1 : 0 }} />}
        <Spin visible={loading && hasLoading} size={32} />
        {error && <span>发生了一些错误，视频无法播放</span>}
      </div>
      <div className={styles['video-player-mask']} onClick={togglePlay}/>
    </div>
  )
}

export default React.forwardRef(Video);
