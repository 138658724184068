import React from 'react'
import UserSkeleton from '../UserSkeletonList/UserSkeleton'
import styles from './styles.module.scss'


interface PostSkeletonProps  {
  visible: boolean
}

const PostSkeleton: React.FC<PostSkeletonProps> = ({visible}) => {
  return (
    <>
      {visible && <div className={styles['post-skeleton']}>

        <UserSkeleton className={styles['post-skeleton-user']} avatarSize={30} gap={12}  descriptionTopWidth={140} describeHeight={10} descriptionBottomWidth={100} descriptionStyle={{padding:0,height:24}}  />
        <div className={styles['post-skeleton-content']} />
      </div>}
    </>
  )
}

export default PostSkeleton;
