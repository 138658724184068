import React, { useEffect } from 'react'
import SuggestedUser from './SuggestedUser'
import {FollowUser,FollowEvent} from '../Follow'
import {Carousel,CarouselItem} from 'components'
import { connect } from 'react-redux';
import * as suggestedAction from 'store/actions/suggestedAction'
import { Link } from 'react-router-dom';
import styles from './styles.module.scss'



interface SuggestedInTrayProps extends FollowEvent {
  title?:React.ReactNode;
  isFetching?: boolean;
  className?:string;
  fetchSuggested?: (isAppend:boolean,effectFetchStatus:boolean) => void;
  suggested?:FollowUser[];
  onDeleteSuggestedUser?: (id:string) => void;  
  hasMore?: boolean;
  style?: React.CSSProperties
}

const SuggestedInTray:React.FC<SuggestedInTrayProps> = ({title,hasMore=true,className,fetchSuggested,suggested,isFetching,onChangeFollowStatus,onDeleteSuggestedUser,style}) => {

  useEffect(() => {
    !suggested.length && fetchSuggested(false,true);
  },[])

  

  return (
    <>
      {!isFetching && <div className={`${styles['suggested-in-tray']} ${className}`} style={style} >
        <header className={styles['in-tray-header']}>
          <h1 className={styles['tray-header-title']}>{title}</h1>
          {hasMore && <Link to="/Suggested" className={styles['tray-header-more']} >查看全部</Link>}
        </header>
        <Carousel navigationStep={2} style={{padding:'0 15px'}} duration={450} hasPagination={false} carouselType="ITEM" navigationType="GRAY" >
          {suggested.map(item => {
            return (
              <CarouselItem  key={item.user.id}>
                <SuggestedUser user={item.user} onDeleteClick={() => { onDeleteSuggestedUser(item.user.id) }} viewerFollowStatus={item.viewerFollowStatus} onChangeFollowStatus={onChangeFollowStatus}/>
              </CarouselItem>
            )
          })}
        </Carousel>
      </div>}
    </>
  )
}

export default connect((state:any) => {
  return {
    suggested:state.suggested.suggested,
    isFetching:state.suggested.isFetching,
  }
},{
  onChangeFollowStatus:suggestedAction.changeFollowStatus,
  onDeleteSuggestedUser: suggestedAction.deleteSuggestedUser,
  fetchSuggested: suggestedAction.fetchSuggested
})(SuggestedInTray);
