class l {
  constructor(t) {
    this.$EventListenerHelper1 = null,
      this.$EventListenerHelper1 = t
  }
  static add(t, o, c, u = {}) {
    let v = u;
    return (v = 'boolean' != typeof u && !!u.capture),
      t.addEventListener(o, c, v),
      new l(() => {
        t.removeEventListener(o, c, v)
      })
  }
  remove() {
    this.$EventListenerHelper1 && (this.$EventListenerHelper1(),
      this.$EventListenerHelper1 = null)
  }
}

export default l;