import React from 'react'
import SVG,{SVGProps} from './index'
import styles from './SVGInCircle.module.scss'


interface SVGInCircleProps extends SVGProps {
  containerClassName?: string;
  containerSize?: number;
}

const SVGInCircle:React.FC<SVGInCircleProps> = ({containerClassName,containerSize,...props}) => {
  return (
    <div className={`${styles['SVG-in-circle']} ${containerClassName}`} style={{width:containerSize,height:containerSize}}>
      <SVG {...props}/>
    </div>
  )
}

export default SVGInCircle;