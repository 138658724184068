import React from 'react'

export default function({sourceImg: t, offsetLeft: o, offsetTop: n, imageWidth: s, imageHeight: c, scaleFactor: l, rotationAngle: u, resolution: f=1080}):any {
  
  if(u % 90 == 0) {
    console.log('Rotation angle should be multiple of 90 degrees')
  }
  const h = l * c / s
    , M = Math.min(s / l, s - o)
    , _ = Math.min(c / h, c - n);
  let p, I;
  
  if(M >= _ ) {
    p = f;
    I = f * _ / M;
  } else {
    I = f;
    p = f * M / _;
  }
  
  const b = u % 180 == 90
    , v = b ? p : I
    , w = b ? I : p
    , P = document.createElement('canvas');
  P.width = w;
  P.height = v;
  
  const j = P.getContext('2d');
  return j.translate(w / 2, v / 2),
  j.rotate(u * Math.PI / 180),
  j.drawImage(t, o, n, M, _, -p / 2, -I / 2, p, I),
  P
}