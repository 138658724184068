import {useState,useEffect,useMemo,useRef} from 'react'
import {User} from 'containers/Commons'

function useMutilUsername (users:User[]=[]) {

  const [usernames,setUsernames] = useState<string>('');
  const usernamesRef = useRef<string>('');

  const usernameSplit = useMemo(() => {
    const userLength = users.length;
    return [
      [''],
      [' 和 ',''],
      ['、',' 和 ',''],
      ['、','、',' 和 ',''],
    ][userLength - 1] || ['、','、',` 和 其他${userLength - 3} 位用户`]
  },[users.length]);

  useEffect(() => {
    usernamesRef.current = usernames;
  },[usernames])

  useEffect(() => {
    setUsernames(users.slice(0,3).map((user,index) => `${user.username}${usernameSplit[index]}`).join(''))
  },[users])

  return {usernameSplit,usernames,preUsernames : usernamesRef.current}

}


export default useMutilUsername;