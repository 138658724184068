export enum DirectActionType {
  DIRECT_CHANGE_FETCH_INBOX_STATUS = 'DIRECT_CHANGE_FETCH_INBOX_STATUS',
  DIRECT_SET_INBOX = 'DIRECT_SET_INBOX',
  DIRECT_APPEND_INBOX = 'DIRECT_APPEND_INBOX',
  DIRECT_PREPEND_INBOX = 'DIRECT_PREPEND_INBOX',
  DIRECT_SET_CHAT = 'DIRECT_SET_CHAT',
  DIRECT_CHANGE_CHAT_MUTE_STATUS = 'DIRECT_CHANGE_CHAT_MUTE_STATUS',
  DIRECT_ADD_CHAT_USERS = 'DIRECT_ADD_CHAT_USERS',
  DIRECT_CHANGE_CHAT_NAME = 'DIRECT_CHANGE_CHAT_NAME',
  DIRECT_DELETE_CHAT_USER = 'DIRECT_DELETE_CHAT_USER',
  DIRECT_CHANGE_CHAT_ADMIN_STATUS = 'DIRECT_CHANGE_CHAT_ADMIN_STATUS',
  DIRECT_FECTH_MESSAGES = 'DIRECT_FECTH_MESSAGES',
  DIRECT_PREPEND_MESSAGES = 'DIRECT_PREPEND_MESSAGES',
  DIRECT_DELETE_CHAT = 'DIRECT_DELETE_CHAT',
  DIRECT_CHANGE_CHAT_USER_BLOCK_STATUS = 'DIRECT_CHANGE_CHAT_USER_BLOCK_STATUS'
}