import React from 'react'
import {ModalContainer} from 'components'
import styles from './styles.module.scss'

export interface AdaptiveContainerProps  {
  visible:boolean;
  onClose?: () => void;
  children?: React.ReactNode
}

const AdaptiveContainer:React.FC<AdaptiveContainerProps> = ({visible,onClose=() => {},children}) => {
  
  return (
    <ModalContainer visible={visible} onModalMaskClick={onClose}>
      <div className={styles['adaptive-container']}>
        <div className={styles['adaptive-container-content']}>
          {children}
        </div>
      </div>
    </ModalContainer>
  )
}

export default AdaptiveContainer;