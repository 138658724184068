import React,{useRef,useImperativeHandle} from 'react'
import canUse from 'utils/canUse'
import {ObserverLoad} from 'components'
import styles from './styles.module.scss'


export interface ScrollerContainerProps  {
  children?: React.ReactNode;
  onScroll?: (event:React.UIEvent<HTMLDivElement, UIEvent>) => void;
  topSpinVisible?: boolean;
  onTopLoadTrigger?: () => void;
  className?: string;
}

const ScrollerContainer = ({children,className,onScroll,topSpinVisible,onTopLoadTrigger}:ScrollerContainerProps,ref:React.RefObject<any>) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const scrollTo = ({y,animated=true}:{y:number;animated:boolean}) => {
    const scroller = containerRef.current;

    if (canUse('smoothScroll') && animated) {
      scroller!.scrollTo({
        top: y,
        behavior: 'smooth',
      });
    } else {
      scroller!.scrollTop = y;
    }    
  }


  const scrollToEnd = ({ animated = false } = {}) => {
    const scroller = containerRef.current;
    const y = scroller!.scrollHeight - scroller!.offsetHeight;

    scrollTo({ y, animated });
  };

  useImperativeHandle(ref,() => ({
    scrollToEnd,
    scrollTo,
    containerRef
  }))

  return (
    <div ref={containerRef} className={`${styles['scroller-container']} ${className}`} onScroll={onScroll}>
      <ObserverLoad visible={topSpinVisible} onLoad={onTopLoadTrigger}/>
      {children}
    </div>
  )
}


export default React.forwardRef(ScrollerContainer);