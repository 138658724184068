import { useState, useMemo, useCallback,useEffect } from 'react';
import { uid } from 'uid';
import { MessageProps, MessageId } from './Message';

type Messages = MessageProps[];

const TIME_GAP = 5 * 60 * 1000;
let lastTs = 0;

const makeMsg = (msg: MessageProps, id?: MessageId) => {
  const ts = msg.createdAt || Date.now();
  const hasTime = ts - lastTs > TIME_GAP;

  if (hasTime) {
    lastTs = ts;
  }

  return {
    hasAvatar: true,
    ...msg,
    id: msg.id || id || uid(),
    createdAt: ts,
    position: msg.position || 'LEFT',
    hasTime,
  };
};

const mergeMessage = (messages: MessageProps[] = []) => {
  return messages.map((msg, index) => {
    if (index) {
      const last = messages[index - 1];
      if (last.owner && msg.owner && last.owner.id == msg.owner.id && msg.position == 'LEFT') {
        last.hasAvatar = false;
      }
    }
    return msg
  })
}


export default function useMessages(initialState: MessageProps[] = []) {
  const initialMsgs: Messages = useMemo(() => initialState.map(makeMsg), [initialState]);
  const [messages, setMessages] = useState(initialMsgs);

  const prependMsgs = useCallback((msgs: Messages) => {
    setMessages((prev: Messages) => [...msgs, ...prev]);
  }, []);

  const updateMsg = useCallback((id: MessageId, msg: MessageProps) => {
    setMessages((prev) => prev.map((t) => (t.id === id ? makeMsg(msg, id) : t)));
  }, []);

  const appendMsg = useCallback(
    (msg: MessageProps) => {
      const newMsg = makeMsg(msg);
      deleteTyping(msg.owner)
      setMessages((prev) => {
        return [...prev, newMsg];
      });
    },
    [updateMsg],
  );

  useEffect(() => {
    setMessages(mergeMessage(messages))
  },[messages.length])

  const deleteMsg = useCallback((id: MessageId) => {
    setMessages((prev) => prev.filter((t) => t.id !== id));
  }, []);

  const resetList = useCallback((list = []) => {
    setMessages(list);
  }, []);

  const deleteTyping = useCallback((owner = {}) => {
    setMessages((prev) => prev.filter((t) => !(t.type == 'TYPING' && t.owner && t.owner.id == owner.id)));
  },[])

  const setTyping = useCallback(
    (typing: boolean, owner) => {
      if (typing) {
        appendMsg({
          type: 'TYPING',
          owner
        });
      } else {
        deleteTyping(owner);
      }
    },
    [appendMsg, deleteTyping],
  );

  return {
    messages,
    prependMsgs,
    appendMsg,
    updateMsg,
    deleteMsg,
    resetList,
    setTyping,
    deleteTyping
  };
}