import * as React from 'react'
import styles from './styles.module.scss'
import {SVG} from 'components'

interface SpinProps {
  visible?: boolean;
  size: number;
  type?: 'SPIN' | 'THINSPIN';
  fill?: string;
  animationDuration?: string;
  boxHeight?: number|string;
  className?: string;
  style?:React.CSSProperties
}

const Spin:React.FC<SpinProps> = ({className,visible=true, type = 'SPIN',animationDuration,boxHeight,style, size,fill}) => {
  const spin = <SVG viewBox={100} className={`${type == 'SPIN' ? styles['spin'] : styles['thin-spin']}`} style={{animationDuration,...style}} ariaLable="正在加载..."  tag="span"  fill={fill} type={type} size={size} />;
  const box = (boxHeight || className) ? <div className={`${styles['spin-box']} ${className}`} style={{height:boxHeight}}>{spin}</div> : spin;

  return (
    <>
      {visible && box}
    </>
  )
}

export default Spin;
