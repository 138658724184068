
export function isIOS() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}


export const MESSAGE_MAX_WIDTH = 234;

export const getExtrinsicHeight = (url='',width = MESSAGE_MAX_WIDTH) => {
  if(url.includes(';base64,')) return 'auto';
  const [configWidth,configHeight] = url.match(/\d+/g);
  const h = Number(configHeight) / Number(configWidth);
  return h * width;
}