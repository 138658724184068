import React, { useEffect,useMemo } from 'react'
import {AdaptiveContainer,User} from '../index'
import {NavBar} from 'components'
import ReportAction,{ReportActionProps} from './ReportAction'
import {ReportTag} from './ReportTags'
import ReportFollowUp,{FollowUp} from './ReportFollowUp'
import ReportFeedback,{Feeback} from './ReportFeedback'
import { connect } from 'react-redux';
import * as reportAction from 'store/actions/reportAction'


type Step = 'REPORT' | 'FOLLOWUP' | 'FEEDBACK'

interface ReportProps extends ReportActionProps{
  visible?: boolean;
  onClose?: () => void;
  fetchReport?:() => void;
  step?: Step;
  onChangeStep?: (step:Step) => void;
  fetchFeedback?: (type:string) => Promise<void>;
  onChangeReportTageFetchStatus?: (index:number,isFetching:boolean) => void;
  feedback?:Feeback;
  followUp?: FollowUp;
  fetchFollowUp?: (type:string,index:number) => void;
  isFetchFeedback?: boolean;
  user?: User;
  onUnfollowClick?:() => void;
}

const Report: React.FC<ReportProps> = ({onUnfollowClick,isFetchFeedback,feedback,step,isFetchReport,report,visible, onClose = () => { },fetchReport,onChangeStep,fetchFeedback,onChangeReportTageFetchStatus,followUp,fetchFollowUp,user}) => {

  useEffect(() => {
    visible ? fetchReport() : onChangeStep('REPORT');
  },[visible])


  const onReportTagClick = async ({type,canSubmit}:ReportTag,index:number) => {
    if(canSubmit) {
      onChangeReportTageFetchStatus(index,true)
      await fetchFeedback(type);
      onChangeReportTageFetchStatus(index,false);
      onChangeStep('FEEDBACK');
    } else {
      await fetchFollowUp(type,index);
      onChangeStep('FOLLOWUP');
    }
  }

  const onFollowUpBtnClick = async (type) => {
    await fetchFeedback(type);
    onChangeStep('FEEDBACK')
  }

  const content = useMemo(() => {
    switch(step) {
      case 'REPORT' :
        return <ReportAction onReportTagClick={onReportTagClick} report={report} isFetchReport={isFetchReport} />
      case 'FEEDBACK' :
        return <ReportFeedback user={user} onUnfollowClick={onUnfollowClick} feedback={feedback} onClose={onClose} />
      case 'FOLLOWUP' :
        return <ReportFollowUp onFollowUpBtnClick={onFollowUpBtnClick} reportFollowUp={followUp} isFetchFeedback={isFetchFeedback}/>
    }
  },[report,feedback,followUp,isFetchFeedback,isFetchReport,step])

  return (
    <AdaptiveContainer visible={visible} onClose={onClose}>
      {step != 'FEEDBACK' && <NavBar>
        {step == 'FOLLOWUP' && <NavBar.Navigation type="BACK" onClick={() => { onChangeStep('REPORT') }} />}
        <NavBar.Title>举报</NavBar.Title>
        <NavBar.Navigation type="THINCLOSE" onClick={onClose} />
      </NavBar>}
      {content}
    </AdaptiveContainer>
  )
}

export default connect((state:any) => {
  return {
    isFetchReport:state.report.isFetchReport,
    report:state.report.report,
    step:state.report.step,
    feedback: state.report.feedback,
    followUp: state.report.followUp,
    isFetchFeedback: state.report.isFetchFeedback
  }
},{
  fetchReport:reportAction.fetchReport,
  fetchFollowUp: reportAction.fetchFollowUp,
  onChangeStep:reportAction.changeStep,
  onChangeReportTageFetchStatus: reportAction.changeReportTageFetchStatus,
  fetchFeedback: reportAction.fetchFeedback
})(Report);