import React,{useState,useCallback,useEffect} from 'react';
import Comment from './Comment'
import ViewReply from './ViewReply'
import {SpriteIcon,Button,Spin} from 'components'
import CommentAction from './CommentAction'
import {User,Report,CommentItem} from '../index'
import { connect } from 'react-redux';
import * as commentsAction from 'store/actions/commentsAction'
import * as storyAction from 'store/actions/storyAction'
import useActiveStoryUserId ,{UseActiveStoryUserIdParams} from 'hooks/useActiveStoryUserId'
import styles from './styles.module.scss'


interface CommentsMoreProps {
  loading?: boolean;
  onLoadMoreClick?: () => void;
}

const CommentsMore: React.FC<CommentsMoreProps> = ({ loading = false,onLoadMoreClick}) => {

  return (
    <>
      {!loading ?
        <div className={styles['comments-more']}>
          <Button type="TEXT" style={{ padding: 0 }} onClick={onLoadMoreClick}>
            <SpriteIcon type="1088" size={24} backgroundSize="544px 520px" backgroundPosition="-369px -400px" />
          </Button>
        </div> :
        <Spin visible={true} size={32} boxHeight={40} />
      }
    </>
  )
}

interface CommentsProps extends UseActiveStoryUserIdParams  {
  caption?: CommentItem;
  count?: number;
  comments?: CommentItem[],
  hasNextPage?: boolean;
  isFetching?: boolean;
  onReplyClick?:(owner:User,id:string) => void;
  currentUser?: User;
  className?: string;
  hasCaption?: boolean;
  fetchComments?: (isAppend:boolean,effectFetchStatus:boolean) => void;
  onchangeExpandedStatus?: (id:string,expanded:boolean) => void;
  onChangeChildLikeStatus?:(id:string,childId:string,liked) => void;
  onDeleteComment?: (id:string) => void;
  onDeleteChildComment?:(id:string,childId) => void;
  onChangeCommentLiked?:(id:string,liked:boolean) => void;
  fetchChildComments?:(id:string) => void;
}

const Comments: React.FC<CommentsProps> = ({currentUser,isFetching,fetchComments,caption={},comments=[],onChangeCommentLiked,fetchChildComments,onchangeExpandedStatus,onChangeChildLikeStatus,onReplyClick,onDeleteComment,onDeleteChildComment,className,hasCaption=true,...props}) => {
  
  const [isOwnComment,setIsOwnComment] = useState<boolean>(false);
  const [commentActionVisible,setCommentActionVisible] = useState<boolean>(false);
  const [currentId,setCurrentId] = useState<string>('');
  const [currentChildId,setCurrenChildtId] = useState<string>('');
  const [reportVisible,setReportVisibile] = useState<boolean>(false);
  const setActiveStoryUserId = useActiveStoryUserId(props);

  const onExpandClick = (comment) => {
    if(comment.count-comment.comments.length > 0) {
      fetchChildComments(comment.id);
    } else {
      onchangeExpandedStatus(comment.id,!comment.expanded);
    }
  }

  const onCaptionAvatarClick = (id) => {
    setActiveStoryUserId(id);
  }

  const _onDeleteComment = useCallback(() => {
    currentChildId ? onDeleteChildComment(currentId,currentChildId) : onDeleteComment(currentId);
  },[currentId,currentChildId])

  const onLongPress = (isOwnComment,id,childId?) => {
    setIsOwnComment(isOwnComment);
    setCurrentId(id);
    setCurrenChildtId(childId);
    setCommentActionVisible(true);
  }



  useEffect(() => {
    fetchComments(false,false);
  },[])

  return (
    <>
      <div className={`${styles['comments']} ${className}`}>
        {caption.id && hasCaption && <div className={styles['comments-caption']}>
          <Comment comment={caption} hasLike={false} hasReply={false} hasStoryLink={true} onAvatarClick={() => {onCaptionAvatarClick(caption.id) }} />
        </div>}
        {comments.map(item => {
          return (
            <div className={styles['comments-item']}>
              <Comment onLongPress={() => {onLongPress(item.owner.id == currentUser.id,item.id)}} comment={item} onLikeClick={onChangeCommentLiked} onReplyClick={() => { onReplyClick(item.owner,item.id) }}/>
              <div className={styles['comments-item-children']}>
                {item.count !=0 && <ViewReply onExpandClick={() => { onExpandClick(item) }} unWatchCommentsNum={item.expanded ? item.count : item.count-item.comments.length} loading={item.isFetchChildComments}/>}
                {item.comments && !item.expanded && item.comments.map(it => <Comment comment={it} onLikeClick={(id,liked) =>{onChangeChildLikeStatus(item.id,id,liked)} } onLongPress={() => {onLongPress(item.owner.id == currentUser.id,item.id,it.id)}} onReplyClick={() => { onReplyClick(it.owner,item.id) }}/>)}
              </div>
            </div>
          )
        })}
        {comments.length !=0 && <CommentsMore loading={isFetching} onLoadMoreClick={() => { fetchComments(true,true) }} />}
      </div>
      <CommentAction 
        visible={commentActionVisible} 
        onDeleteClick={_onDeleteComment}
        onReportClick={() => { setReportVisibile(true) }} 
        isOwn={isOwnComment} 
        onCancel={() => {setCommentActionVisible(false)}} 
      />
      <Report visible={reportVisible} onClose={() => { setReportVisibile(false) }}/>
    </>
  )
}


export default connect((state:any) => {
  return {
    currentUser: state.user.user,
    ...state.comments
  }
},{
  fetchComments:commentsAction.fetchComments,
  onChangeCommentLiked:commentsAction.changeLikeStatus,
  onDeleteComment:commentsAction.deleteComment,
  onDeleteChildComment:commentsAction.deleteChildComment,
  fetchChildComments:commentsAction.fetchChildComments,
  onchangeExpandedStatus:commentsAction.changeExpandedStatus,
  onChangeChildLikeStatus:commentsAction.changeChildLikeStatus,
  fetchStory: storyAction.fetchStory,
  onChangeAvatarLoadingStatus: commentsAction.changeAvatarLoadingStatus,
  onChangeViewerSeenStory: commentsAction.changeViewerSeenStory
})(Comments);
