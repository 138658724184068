import React from 'react'

interface LineBreakMeasurerProps {
  style?: React.CSSProperties,
  className?: string;
}

const s: React.CSSProperties = {
  wordWrap: 'break-word',
  zIndex: -999
}


function t(t, n) {
  function s(t, n = null) {
    if (t.nodeType === Node.TEXT_NODE) {
      const n = [];
      for (const s of (t.textContent || '').split('')) {
        const t = document.createElement('span');
        t.textContent = s;
        n.push(t);
        o.push(t);
      }
      return n
    }
    const c = null == n ? t.cloneNode(!1) : n;
    for (const n of t.childNodes)
      for (const t of s(n))
        c.appendChild(t);
    return [c]
  }
  const o = [];
  s(t, n)
  return o;
}

function n(t) {
  const n = [];
  if (0 === t.length)
    return n;
  let s = t[0].getBoundingClientRect().top
    , o = '';
  for (const c of t) {
    const t = c.getBoundingClientRect().top;
    if (t > s) {
      n.push(o);
      const u = c.getBoundingClientRect().height
        , l = Math.ceil((t - s) / u) - 1;
      n.push(...Array(l).fill(''));
        s = t;
        o = c.innerText || '';
    } else
      o += c.innerText || ''
  }
  o.length > 0 && n.push(o)
  return n;
}


class LineBreakMeasurer extends React.Component<LineBreakMeasurerProps> {
  $LineBreakMeasurer2: HTMLSpanElement;
  getStrings: any;
  constructor(props) {
    super(props);
    this.getStrings = (s) => {
      this.$LineBreakMeasurer2.innerHTML = '';
      return n(t(s, this.$LineBreakMeasurer2));
    }
  }

  getStringsFromText(t) {
    const n = document.createElement('span');
    return n.textContent = t,
      this.getStrings(n)
  }

  render() {
    return (
      <>
        <span className={this.props.className} ref={(t) => this.$LineBreakMeasurer2 = t} style={{ ...s, ...this.props.style }} />
      </>
    )
  }

}


export default LineBreakMeasurer;