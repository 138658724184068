import { useState,useEffect } from "react";
import {useHistory} from 'react-router-dom'
import sleep from 'sleep-promise';

export interface UseActiveStoryUserIdParams {
  onChangeAvatarLoadingStatus?: (id:string,isAvatarLoading:boolean) => void;
  onChangeViewerSeenStory?: (id:string,viewerSeenStory) => void;
  fetchStory?: () => Promise<void>
}

function useActiveStoryUserId({onChangeAvatarLoadingStatus=() =>{},fetchStory,onChangeViewerSeenStory=() => {}}:UseActiveStoryUserIdParams) {
  const [activeStoryUserId,setActiveStoryUserId] = useState<string>();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      if(!activeStoryUserId) return ;
      onChangeAvatarLoadingStatus(activeStoryUserId,true);
      await fetchStory();
      await sleep(1500);
      onChangeAvatarLoadingStatus(activeStoryUserId,false);
      history.push(`/stories/${activeStoryUserId}`);
      onChangeViewerSeenStory(activeStoryUserId,true);
    })()
  },[activeStoryUserId])

  return setActiveStoryUserId;

}

export default useActiveStoryUserId;