import React from 'react'
import CreateStyle from './CreateStyle'

export const getDefaultCrop = function (width: number, height: number): CroppedImage {
  const o = width / height;
  const f = width >= height ? [(width - height) / 2, 0] : [0, (height - width) / 2];

  return {
    offsetLeft: f[0],
    offsetTop: f[1],
    scaleFactor: o < 1 ? 1 : o,
  }
}

const  dataURLtoBlob = (dataurl) => {
  let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], {type:mime});
}


interface CreationCroppingProps {
  hideControls?: boolean;
  imageHeight?: number;
  imageWidth?: number;
  imageDataURL?: string;
  croppedImage?: CroppedImage
}

export interface CroppedImage {
  mirrored?: boolean;
  offsetTop: number;
  offsetLeft: number;
  scaleFactor: number;
  rotationAngle?: number;
}

interface CreationCroppingState {
  disableAnimation?: boolean;
  croppedImage?: CroppedImage;
  expanded?: boolean;
  blobDataUrl?: string;
}

const T = 19.1;

class CreationCropping extends React.Component<CreationCroppingProps, CreationCroppingState> {

  $CreationCroppingUnit6: any;
  $CreationCroppingUnit7: any;
  $CreationCroppingUnit8: any;
  $CreationCroppingUnit9: any;

  constructor(props) {
    super(props);
    const { imageWidth, imageHeight,croppedImage } = props;
    this.state = {
      disableAnimation: false,
      expanded: false,
      croppedImage: croppedImage ? croppedImage :{
        ...getDefaultCrop(imageWidth, imageHeight),
        mirrored: false,
        rotationAngle: 0
      },
    }
  }
  isSquareCrop() {
    const { croppedImage } = this.state;
    const { imageWidth, imageHeight } = this.props;
    const s = croppedImage.scaleFactor * imageHeight / imageWidth;
    const p = Math.min.bind(Math, 1)
    const h = p(croppedImage.scaleFactor) / p(s);
    return Math.abs(h - 1) < 1e-4;
  }

  componentDidMount(){
    const blobDataUrl = URL.createObjectURL(dataURLtoBlob(this.props.imageDataURL));
    this.setState({
      ...this.state,
      blobDataUrl
    })
  }

  getPosition(n, o, s, p) {
    let h = n
      , C = o;
    const { imageHeight: c, imageWidth: l } = this.props
      , u = l > c && p % 180 == 0 || c > l && p % 180 != 0 ? T : 1.25;
    let f;
    if (f = l > c ? l / (u * c) : l / (u * l), f = Math.max(s, f), s !== f) {
      const t = s * c / l
        , n = l / Math.max(1, s)
        , o = C + c / Math.max(1, t) / 2
        , p = f * c / l;
      h = h + n / 2 - l / Math.max(1, f) / 2;
      C = o - c / Math.max(1, p) / 2
    }
    return {
      offsetLeft: h,
      offsetTop: C,
      scaleFactor: f,
    }
  }

  onExpandTap() {
    const { croppedImage } = this.state;
    const { imageWidth, imageHeight } = this.props;
    const isSquareCrop = this.isSquareCrop();

    if (isSquareCrop) {
      this.setState({
        expanded: true,
        croppedImage: {
          ...croppedImage,
          ...this.getPosition(0, 0, this.getImageSizeRatio(), croppedImage.rotationAngle)
        }
      })
    } else {
      this.setState({
        expanded: false,
        croppedImage: {
          ...croppedImage,
          ...getDefaultCrop(imageWidth, imageHeight)
        }
      })
    }
  }


  getImageSizeRatio() {
    const { imageWidth, imageHeight } = this.props;
    return imageWidth > imageHeight ? 1 : imageWidth / imageHeight
  }

  onRotateTap() {
    const { croppedImage } = this.state;
    const { rotationAngle } = croppedImage;
    const rotate = rotationAngle - 90;

    this.setState({
      croppedImage: {
        ...croppedImage,
        rotationAngle: rotate,
        ...(this.isSquareCrop() ? {} : this.getPosition(0, 0, this.getImageSizeRatio(), rotate))
      }
    })
  }

  onTouchEnd(t: React.TouchEvent) {
    this.setState({
      disableAnimation: false
    })
    t.preventDefault();
    if (this.$CreationCroppingUnit6 != null || 0 !== this.$CreationCroppingUnit6 || null != this.$CreationCroppingUnit7 || 0 !== this.$CreationCroppingUnit7) {
      this.$CreationCroppingUnit6 = this.$CreationCroppingUnit7 = null;
      this.$CreationCroppingUnit8 = this.$CreationCroppingUnit9 = null;
      const { imageHeight, imageWidth } = this.props;
      const { croppedImage } = this.state;
      const { offsetLeft, offsetTop } = croppedImage

        , p = Math.min(imageHeight, imageWidth)
        , h = imageWidth - p
        , C = imageHeight - p;
      
      const t = {
        ...croppedImage,
        offsetLeft: Math.min(h, Math.max(0, offsetLeft)),
        offsetTop: Math.min(C, Math.max(0, offsetTop))
      }
      this.setState({
        croppedImage: t
      })
      
    }
  }

  onTouchMove(t: React.TouchEvent) {
    t.preventDefault();
    if (this.$CreationCroppingUnit6 === null || null == this.$CreationCroppingUnit7) {
      return;
    }
    this.setState({
      disableAnimation: true
    })
    const { croppedImage } = this.state;
    const { rotationAngle } = croppedImage;
    const { imageHeight, imageWidth } = this.props;
    const viewportWidth = window.innerWidth;

    const C = t.touches[0].clientY - this.$CreationCroppingUnit7,
      c = t.touches[0].clientX - this.$CreationCroppingUnit6,
      l = rotationAngle % 180 == 0 ? c : C
      , u = rotationAngle % 180 == 0 ? C : c
      , f = 180 === rotationAngle || 270 === rotationAngle ? -1 : 1
      , U = 180 === rotationAngle || 90 === rotationAngle ? -1 : 1
      , $ = Math.min(imageWidth, imageHeight) / viewportWidth
      , M = l * $ * f
      , v = u * $ * U
      , A = this.$CreationCroppingUnit8 - M;

    this.setState({
      croppedImage: {
        ...croppedImage,
        offsetTop: this.$CreationCroppingUnit9 - v,
        offsetLeft: A
      }
    })
  }

  onTouchStart(t: React.TouchEvent) {
    this.setState({
      disableAnimation: false
    })
    t.preventDefault();
    if (!this.isSquareCrop() || t.touches.length > 1 || this.props.imageHeight === this.props.imageWidth) {
      this.$CreationCroppingUnit6 = this.$CreationCroppingUnit7 = null;
      this.$CreationCroppingUnit8 = this.$CreationCroppingUnit9 = null;
      return;
    }

    this.$CreationCroppingUnit6 = t.touches[0].clientX;
    this.$CreationCroppingUnit7 = t.touches[0].clientY;

    const { offsetLeft, offsetTop } = this.state.croppedImage;
    this.$CreationCroppingUnit8 = offsetLeft;
    this.$CreationCroppingUnit9 = offsetTop;


  }

  render() {
    const { hideControls, imageHeight, imageWidth } = this.props;
    const { disableAnimation, expanded, croppedImage,blobDataUrl } = this.state;
    const { mirrored, offsetTop, offsetLeft, scaleFactor, rotationAngle } = croppedImage;

    return (
      <CreateStyle
        width={imageWidth}
        height={imageHeight}
        src={blobDataUrl}
        disableAnimation={disableAnimation}
        hideGridLines={(!hideControls && expanded)}
        mirrored={mirrored}
        offsetTop={offsetTop}
        offsetLeft={offsetLeft}
        scaleFactor={scaleFactor}
        onExpandTap={hideControls ? null : this.onExpandTap.bind(this)}
        onRotateTap={hideControls ? null : this.onRotateTap.bind(this)}
        onTouchEnd={hideControls ? null : this.onTouchEnd.bind(this)}
        onTouchMove={hideControls ? null : this.onTouchMove.bind(this)}
        onTouchStart={hideControls ? null : this.onTouchStart.bind(this)}
        rotationAngle={rotationAngle}

      />
    )
  }
}

export default CreationCropping;