import { CreatePostActionType } from 'constants/createPostActionType'


const initialState = {
  style: {},
  caption: '',
  isFetchLocations: false,
  locations: [],
  locationTag: null,
  userTags: [],
  users: [],
  isFetchUsers: false,
  altText: '',
  position:[],
  temporaryUserTags: []
}

const createPostReducer = (state = initialState, action) => {
  switch (action.type) {
    case CreatePostActionType.CREATE_POST_SET_STYLE:
      return {
        ...state,
        style: action.style
      }
    case CreatePostActionType.CREATE_POST_CLEAR:
      return initialState;
    case CreatePostActionType.CREATE_POST_SET_CAPTION:
      return {
        ...state,
        caption: action.caption
      }
    case CreatePostActionType.CREATE_POST_SET_LOCATION_TAG:
      return {
        ...state,
        locationTag: action.location
      }
    case CreatePostActionType.CREATE_POST_SET_LOCATIONS:
      return {
        ...state,
        locations: action.locations
      }
    case CreatePostActionType.CREATE_POST_CHANGE_FETEACH_LOCATIONS_STATUS:
      return {
        ...state,
        isFetchLocations: action.isFetchLocations
      }
    case CreatePostActionType.CREATE_POST_ADD_TEMPORARY_USER_TAG:
      const {id,username} = action.user;
      return {
        ...state,
        temporaryUserTags: [...state.temporaryUserTags,{username,userId:id,position:state.position}]
      }
    case CreatePostActionType.CREATE_POST_DELETE_TEMPORARY_USER_TAG:
      return {
        ...state,
        temporaryUserTags: state.temporaryUserTags.filter(tag => tag.userId != action.id)
      }
    case CreatePostActionType.CREATE_POST_SET_TEMPORARY_USER_TAG_POSITION:
      state.temporaryUserTags.forEach(tag => {
        if(tag.userId == action.id) {
          tag.position = action.position;
        }
      })
      return {
        ...state
      }
    case CreatePostActionType.CREATE_POST_SET_TEMPORARY_USER_TAGS:
      return {
        ...state,
        temporaryUserTags: [...state.userTags]
      }
    case CreatePostActionType.CREATE_POST_SET_USER_TAGS:
      return {
        ...state,
        userTags:[...state.temporaryUserTags]
      }
    case CreatePostActionType.CREATE_POST_SET_USERS:
      return {
        ...state,
        users: action.users
      }
    case CreatePostActionType.CREATE_POST_CHANGE_FETEACH_USERS_STATUS:
      return {
        ...state,
        isFetchUsers: action.isFetchUsers
      }
    case CreatePostActionType.CREATE_POST_SET_ALT_TEXT:
      return {
        ...state,
        altText:action.altText
      }
    case CreatePostActionType.CREATE_POST_SET_CURRENT_USER_TAG_POSITION:
      return {
        ...state,
        position: action.position
      }
    default:
      return state;
  }
}



export default createPostReducer;