import React from 'react'
import styles from './styles.module.scss'


interface BadgeProps  {
  color?:string;
  count?:number;
  className?: string;
}

const Badge:React.FC<BadgeProps> = ({color="#ed4956",count,className}) => {
  return (
    <div className={`${styles['badge']} ${className}`} style={{backgroundColor:color}}>{count}</div>
  )
}


export default Badge;