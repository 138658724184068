import { ReportActionType } from 'constants/reportActionType'
import {getReport,getReportFeedback,getReportFollowUp} from 'services/commons/report'

export const changReportFetchStatus = (isFetchReport) => {
  return {
    type: ReportActionType.REPORT_CHANGE_FETCH_STATUS,
    isFetchReport
  }
}

export const changeStep = (step) => {
  return {
    type: ReportActionType.REPORT_CHANGE_STEP,
    step
  }  
}

export const changFeedbackFetchStatus = (isFetchFeedback) => {
  return {
    type: ReportActionType.REPORT_CHANGE_FEEDBACK_FETCH_STATUS,
    isFetchFeedback
  }
}

export const changeReportTageFetchStatus = (index,isFetching) => {
  return {
    type: ReportActionType.REPORT_TAG_CHANGE_FETCH_STATUS,
    index,
    isFetching
  }
}


export const setFeedback = (feedback) => {
  return {
    type: ReportActionType.REPORT_SET_FEEDBACK,
    feedback
  }    
}


export const setFollowUp = (followUp) => {
  return {
    type: ReportActionType.REPORT_SET_FOLLOWUP,
    followUp
  }    
}


export const fetchFollowUp = (type,index) => {
  return (dispatch) => {
    return new Promise<void>(async resolve => {
      dispatch(changeReportTageFetchStatus(index,true))
      const followUp = await getReportFollowUp(type);
      dispatch(setFollowUp(followUp[0]));
      dispatch(changeReportTageFetchStatus(index,false))
      resolve();
    })
  }
}


export const fetchReport = () => {
  return async (dispatch) => {
    dispatch(changReportFetchStatus(true))
    const report = await getReport();
    dispatch(setReport(report));
    dispatch(changReportFetchStatus(false))
  }
}

export const fetchFeedback = (type) => {
  return (dispatch) => {
    return new Promise<void>(async resolve => {
      dispatch(changFeedbackFetchStatus(true))
      const feedback = await getReportFeedback(type);
      dispatch(setFeedback(feedback[0]));
      dispatch(changFeedbackFetchStatus(false))
      resolve();
    })
  }
}



export const setReport = (report) => {
  return {
    type: ReportActionType.REPORT_SET,
    report
  }
}
