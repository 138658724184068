import React,{useMemo} from 'react'
import {ImageProps,Spin,Image} from 'components'
import {getExtrinsicHeight,MESSAGE_MAX_WIDTH} from 'utils'
import styles from './BubbleImage.module.scss'

interface BubbleImageProps extends ImageProps {
  loading?: boolean;
}

const BubbleImage:React.FC<BubbleImageProps> = ({loading,src,...props}) => {

  const height = useMemo(() => {
    return getExtrinsicHeight(src);
  },[src])

  return (
    <div className={styles['bubble-image']}>
      <Image src={src} {...props} style={{minWidth:MESSAGE_MAX_WIDTH,height}}/>
      <div className={styles['bubble-image-spin']}>
        <Spin size={18} visible={loading} type="THINSPIN"/>
      </div>
    </div>
  )
}

export default BubbleImage;