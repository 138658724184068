import React from 'react'

import styles from './styles.module.scss'


interface SettingProps {
  title?: string;
  hint?: string;
}


const Setting:React.FC<SettingProps> = ({title,hint,children}) => {
  return (
    <div className={styles['setting']}>
      <h2 className={styles['setting-title']}>{title}</h2>
      {children}
      <div className={styles['setting-hint']}>{hint}</div>
    </div>
  )
}


export default Setting;