import React, { useRef,useImperativeHandle } from 'react'
import { useHistory } from 'react-router-dom'
import {ImageUpload} from 'components'
import * as createStoryAction from 'store/actions/createStoryAction'
import { connect } from 'react-redux';

interface UploadFileForStory {
  setImageData?: (imageData) => void;
}

const UploadFileForStory = React.forwardRef(({ setImageData }:UploadFileForStory, ref: React.RefObject<any>) => {
  const history = useHistory();
  const uploadRef = useRef(null);

  
  const onChange = (file,imageData) => {
    setImageData(imageData)
    history.push({
      pathname : '/createStory',
    })
  }

  useImperativeHandle(ref, () => {
    return {
      click() {
        uploadRef.current.click();
      }
    }
  })

  return (
    <>
      <ImageUpload onChange={onChange} ref={uploadRef} />    
    </>
  );
})

export default connect(null,{
  setImageData:createStoryAction.setImageData,
},null,{forwardRef:true})(UploadFileForStory);


