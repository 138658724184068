import { ExploreTagsActionType } from 'constants/exploreTagsActionType'


const initialState = {
  tag: {},
  postHot:[],
  postRecent:[],
  isFetching:true,
  viewerFollowStatus: ''
}

const exploreTagsReducer = (state=initialState,action) => {
  switch(action.type) {
    case ExploreTagsActionType.EXPLORE_TAGS_SET:  
      return {
        ...state,
        ...action.exploreTags
      }
    case ExploreTagsActionType.EXPLORE_TAGS_SET_RECENT_POST:
      return {
        ...state,
        postRecent: [...state.postRecent,...action.postRecent]
      }
    case ExploreTagsActionType.EXPLORE_TAGS_CHANGE_FOLLOW_STATUS:
      return {
        ...state,
        viewerFollowStatus: action.viewerFollowStatus
      }
    case ExploreTagsActionType.EXPLORE_TAGS_CHANGE_FETCH_STATUS:
      return {
        ...state,
        isFetching: action.isFetching
      }
    default:
      return state;
  }
}



export default exploreTagsReducer;