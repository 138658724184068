import React from 'react'
import {Button,ButtonProps,SVG} from 'components'
import styles from './tagButton.module.scss'

interface TagButtonProps extends ButtonProps {
  closable?:boolean;
  onDeleteClick?:() => void;
}

const TagButton:React.FC<TagButtonProps> = ({children,onDeleteClick=() => {},className,closable,...props}) => {
  return (
    <Button type="TEXT" className={`${styles['tag-button']} ${closable ? styles[`tag-buttn-closable`] : ''} ${className}`} {...props}>
      {children}
      {closable && <SVG onClick={(e) => {e.stopPropagation() ;onDeleteClick(); }} size={12} type="CLOSE" fill="#ffffff" style={{marginLeft:8}} color="#fff" />}
    </Button>
  )
}


export default TagButton;