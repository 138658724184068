import React, { ChangeEvent,createContext,useContext } from 'react'
import styles from './styles.module.scss'

const RadioGroupContext = createContext<any>({});


interface Option {
  label?: string;
  value?: any;
}

export interface RadioGroupProps extends RadioProps {
  options?: Option[];
  style?: React.CSSProperties;
  labelClassName?: string;
  defaultValue?: any;
}

export const RadioGroup:React.FC<RadioGroupProps> = ({className,labelClassName,style,options=[],children,onChange,value,name,defaultValue,...props}) => {

  return (
    <RadioGroupContext.Provider value={{_onChange:onChange,_name:name}} >
      <div className={className} style={style}>
        {options.map((item) => <Radio value={item.value} name={name} className={labelClassName} checked={value == item.value || defaultValue == item.value} onChange={onChange} {...props}>{item.label}</Radio>
          )}
        {options.length == 0 && children}
      </div>
    </RadioGroupContext.Provider>
  )
}


export interface RadioProps {
  size?: 'LARGE' | 'SMALL' | 'MINI';
  onChange?: (e:ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  checked?: boolean;
  value?: any;
  name?: string;
  disabled?: boolean;
}

const Radio:React.FC<RadioProps> = ({className,size="LARGE",children,name,onChange,...props}) => {
  const { _name,_onChange } = useContext(RadioGroupContext);

  return(
    <label className={`${styles['radio']} ${className}`}>
      <input type="radio" name={name ? name : _name} onChange={onChange ? onChange : _onChange} className={`${styles[`radio-input-${size}`]} ${styles['radio-input']} `} {...props}/>
      {children}
    </label>
  )
}

export default Radio;
