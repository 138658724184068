import React from 'react'
import {Button,Spin,SVG} from 'components'
import styles from './optionsButton.module.scss'


export interface OptionButtonProps {
  children?: React.ReactNode;
  type?: 'DEFAULT' | 'WARNING';
  onClick?: (e:React.MouseEvent<HTMLElement>) => void;
  className?: string;
  style?: React.CSSProperties;
  loading?: boolean;
}

const OptionButton:React.FC<OptionButtonProps> = ({className,children,type="DEFAULT",loading,...props}) => {
  return (
    <Button type="TEXT" className={`${styles['option-button']} ${className}`} {...props}>
      <div className={`${styles['options-button-text']} ${styles[`button-text-${type}`]}`}>{children}</div>
      {!loading ? <SVG tag="span" type="BACK" size={17} fill="#c7c7c7" ariaLable="箭头" style={{transform:'rotate(90deg)'}}/> : <Spin visible={true} fill="#555555"  size={18} type="THINSPIN" animationDuration="0.8s"/>}
    </Button>
  )
}

export default OptionButton;