import React,{useState} from 'react'
import styles from './releaseCommnet.module.scss'
import {CommentInput,CommentInputProps} from 'components'


const ReleaseCommnet: React.FC<CommentInputProps> = ({onSend,...props}) => {
  const [onFocus,setOnFocus] = useState<boolean>(false);
  const [value,setValue] = useState<string>('');
  
  const _onSend = (text) => {
    setValue('');
    onSend(text);
  }
  return (
    <div className={styles['release-comment']}>
      <CommentInput value={value} onChange={(e) => { setValue(e.target.value) }} onSend={_onSend} hasSendBtn={true} onBlur={() => { setOnFocus(false) }} onFocus={() => { setOnFocus(true) }} placeholder="发布评论..." aria-label="发布评论..." {...props}/>
      {onFocus && <div className={styles['release-comment-focus']} />}
    </div>
  );
}

export default ReleaseCommnet;
