import React,{forwardRef} from 'react'
import styles from './autosizeTextarea.module.scss'

import TextareaAutosize,{TextareaAutosizeProps} from 'react-textarea-autosize';

export interface AutosizeTextareaProps extends TextareaAutosizeProps {
  className?: string;
  children?: React.ReactNode
}

const AutosizeTextarea = ({maxRows,className,children,...props}:AutosizeTextareaProps,ref: React.RefObject<any>) => {
  return (
    <div className={`${styles['autosize-textarea']} ${className}`}>
      <TextareaAutosize  minRows={1} maxRows={maxRows} {...props} ref={ref}/>
      {children}
    </div>
  );
}

export default forwardRef(AutosizeTextarea);
