import React, { useRef, useEffect, useState } from 'react'
import ModalContainer, { ModalProps } from './ModalContainer'
import { useSwipeable } from "react-swipeable";
import styles from './stickyModal.module.scss'


export interface StickyModalProps extends ModalProps {
  title?: React.ReactNode;
  children?: React.ReactNode;
  canStickTop?: boolean;
  contentHeightType?: 'AUTO' | 'MID' | 'FULL';
  className?: string;
  onSwiping?: () => void;
}


const StickyModal: React.FC<StickyModalProps> = ({ className,onSwiping=() =>{},contentHeightType = 'AUTO',visible, onCancel, title, children, canStickTop = false }) => {

  const [contentHeight,setConcainterHeight] = useState<string>('');

  const modalEl = useRef<HTMLDivElement>(null);
  const contentEl = useRef<HTMLDivElement>(null);
  const swipeEl = useRef<HTMLDivElement>(null);
  const offsetTopRef = useRef<number>(0);
  const oriOffsetTopRef = useRef<number>(0);

  let placeholderHeight = title ? 71 : 28;

  const threshold = 0.4;
  const velocityThreshold = 1.5;
  const maxThreshold = 0.6;

  const handlers = useSwipeable({
    onSwipeStart() {
      modalEl.current.style.transition = '';
    },
    onSwiping({ deltaY }) {
      const ty = offsetTopRef.current + deltaY;
      modalEl.current.style.transform = `translateY(${ty}px)`;
      contentEl.current.style.height = `calc(100vh - ${ty + placeholderHeight}px)`;
      onSwiping();
    },
    onSwiped({ absY, dir, velocity }) {
      const height = contentEl.current.getBoundingClientRect().height;
      const innerHeight = window.innerHeight;
      if (dir == 'Up') {
        if (velocity > velocityThreshold) {
          if (canStickTop) {
            return setTransform(0);
          } else {
            return setTransform(oriOffsetTopRef.current);
          }
        }
        if (canStickTop) {
          if (absY / height > threshold) {
            setTransform(0);
          } else {
            setTransform(oriOffsetTopRef.current);
          }
        } else {
          setTransform(oriOffsetTopRef.current);
        }
      }
      if (dir == 'Down') {
        if (absY / innerHeight > maxThreshold) {
          return setTransform(innerHeight);
        }
        if (velocity > velocityThreshold) {
          return setTransform(offsetTopRef.current === 0 ? oriOffsetTopRef.current : innerHeight);
        }

        if (absY / height > threshold) {
          setTransform(offsetTopRef.current === 0 ? oriOffsetTopRef.current : innerHeight);
        } else {
          setTransform(offsetTopRef.current === 0 ? 0 : oriOffsetTopRef.current);
        }
      }
    }
  })

  const onModalMaskClick = () => {
    const innerHeight = window.innerHeight;
    offsetTopRef.current = innerHeight;
    setTransform(innerHeight);
  }

  const onTransitionEnd = (e) => {
    if(e.propertyName != "transform") return;
    e.stopPropagation();
    const innerHeight = window.innerHeight;
    if (offsetTopRef.current >= innerHeight) {
      setTimeout(onCancel,100);
    }
    onSwiping();
  }

  const setTransform = (ty) => {
    modalEl.current.style.transform = `translateY(${ty}px)`;
    modalEl.current.style.transition = 'transform 0.3s ease 0s';
    offsetTopRef.current = ty;
    onSwiping();
    contentEl.current.style.height = `calc(100vh - ${ty + placeholderHeight}px)`
  }

  useEffect(() => {
    const halfInnerHeight = window.innerHeight/2;
    switch(contentHeightType) {
      case 'AUTO' :
        setConcainterHeight('auto');
      break;
      case 'MID' :
        setConcainterHeight(`calc(100vh - ${halfInnerHeight + placeholderHeight}px)`);
      break;
      case 'FULL' : 
        setConcainterHeight(`calc(100vh - ${placeholderHeight}px)`);
      break;
    }
  },[]);

  useEffect(() => {
    if (!visible) return;
    requestAnimationFrame(() => {
      const modalContentHeight = contentEl.current.getBoundingClientRect().height + swipeEl.current.getBoundingClientRect().height;
      const innerHeight = window.innerHeight;
      if (modalContentHeight >= innerHeight) {
        offsetTopRef.current = 0;
      } else {
        offsetTopRef.current = innerHeight - modalContentHeight;
      }
      oriOffsetTopRef.current = offsetTopRef.current;
      setTransform(offsetTopRef.current);
    })
  }, [visible])

  return (
    <ModalContainer visible={visible} onModalMaskClick={onModalMaskClick}>
      <div ref={modalEl} className={`${styles['sticky-modal']} ${className}`} onTransitionEnd={onTransitionEnd} >
        <div {...handlers}>
          <div ref={swipeEl}>
            <div className={styles['sticky-modal-bar']}>
              <div className={styles['modal-bar-inner']} />
            </div>
            {title && <h1 className={styles['sticky-modal-title']}>{title}</h1>}
          </div>
        </div>
        <div className={styles['sticky-modal-content']} ref={contentEl} style={{height:contentHeight}}>
          {children}
        </div>
      </div>
    </ModalContainer>
  )

}

export default StickyModal;
