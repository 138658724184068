export enum PostDetailActionType {
  POST_DETAIL_SET = 'POST_DETAIL_SET',
  POST_DETAIL_CHANGE_LIKED = 'POST_DETAIL_CHANGE_LIKED',
  POST_DETAIL_CHANGE_SAVE_STATUS = 'POST_DETAIL_CHANGE_SAVE_STATUS',
  POST_DETAIL_CHANGE_COMMENT_LIKED = 'POST_DETAIL_CHANGE_COMMENT_LIKED',
  POST_DETAIL_CHANGE_FOLLOW_STATUS = 'POST_DETAIL_CHANGE_FOLLOW_STATUS',
  POST_DETAIL_ADD_COMMENT = 'POST_DETAIL_ADD_COMMENT',
  POST_DETAIL_CHANGE_AVATAR_LOADING_STATUS = 'POST_DETAIL_CHANGE_AVATAR_LOADING_STATUS',
  POST_DETAIL_CHANGE_VIEWER_SEEN_STORY = 'POST_DETAIL_CHANGE_VIEWER_SEEN_STORY'
}
