import React,{useState,useCallback} from 'react'
import {Button,ButtonProps} from 'components'
import {User,UnfollowConfirm} from '../index'
import RetirerFollowConfirm from './RetirerFollowConfirm'

export type FollowType = 'FOLLOWING' | 'UNFOLLOW' | 'PEDING';

export  interface ViewerFollowStatus {
  viewerFollowStatus?: FollowType
}

export interface FollowUser extends ViewerFollowStatus{
  user?: User;
  viewerSeenStory?: boolean;
}


export interface FollowEvent {
  onChangeFollowStatus?: (id?:string,status?: FollowType) => void;
}


export type FollowProps = FollowEvent & ButtonProps & FollowUser & {
  hasConfirm?: boolean;
  followsViewer?: boolean;
};

const Follow:React.FC<FollowProps> = ({user={},onChangeFollowStatus=() =>{},followsViewer,viewerFollowStatus,type="PRIMARY",hasConfirm=true,...props}) => {
  const [unfollowConfirmVisible,setUnfollowConfirmVisible] = useState<boolean>(false);
  const [retirerFollowVisible,setRetirerFollowVisible] = useState<boolean>(false);

  const isFollowing = viewerFollowStatus == 'FOLLOWING';
  const isPeding = viewerFollowStatus == 'PEDING';

  const onClick = useCallback(() => {
    switch(viewerFollowStatus) {
      case 'FOLLOWING':
        hasConfirm ? setUnfollowConfirmVisible(true) : onChangeFollowStatus(user.id,'UNFOLLOW');
      break;
      case 'UNFOLLOW':
        user.isPrivite ? onChangeFollowStatus(user.id,'PEDING') : onChangeFollowStatus(user.id,'FOLLOWING');
      break;
      case 'PEDING':
        hasConfirm ? setRetirerFollowVisible(true) : onChangeFollowStatus(user.id,'UNFOLLOW');
      break;
    }
  },[viewerFollowStatus])

  const getFollowText = useCallback((followStatus:FollowType) => {
    switch(followStatus) {
      case 'FOLLOWING':
        return '已关注';
      case 'PEDING':
        return '已发送';
      case 'UNFOLLOW':
        return followsViewer ? '回粉': '关注';
    }
  },[followsViewer])


  return (
    <>
      <Button {...props as ButtonProps} type={type == 'PRIMARY' ? ((isFollowing || isPeding) ? 'DEFAULT' : 'PRIMARY') : type} onClick={onClick}>{getFollowText(viewerFollowStatus)}</Button>
      <UnfollowConfirm onUnfollowClick={() => { onChangeFollowStatus(user.id,'UNFOLLOW') }} visible={unfollowConfirmVisible} onCancel={() => { setUnfollowConfirmVisible(false) }} user={user}/>
      <RetirerFollowConfirm  user={user} onRetirerFollowClick={() => { onChangeFollowStatus(user.id,'UNFOLLOW') }} visible={retirerFollowVisible} onCancel={() => { setRetirerFollowVisible(false) }} />
    </>
  )
}

export default Follow;