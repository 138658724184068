import React from 'react'
import { Image, SVG, SpriteIcon } from 'components'
import ShortenNumber from '../ShortenNumber'
import { useMin736 } from 'hooks/useMediaQuery'
import { Link } from 'react-router-dom'
import styles from './postRow.module.scss'

export interface PostRowLayout {
  hasLarge?: boolean;
  displayType?: 'POST' | 'CHANNEL';
  linkTo?: string;
}

export interface PostRowItem {
  medias: Post[];
  id?: string;
}


interface Post {
  mediaType?: 'VIDEO' | 'CLIP' | 'CAROUSEL';
  id?: string;
  displayUrl?: string;
  accessibilityCaption?: string;
  previewNum?: number;
  size?: 'LARGE' | 'DEFAULT';
  direction?: 'RIGHT' | 'LEFT';
}


export interface PostRowProps extends PostRowLayout, PostRowItem { }

const PostRow: React.FC<PostRowProps> = ({ medias, hasLarge, displayType="POST", linkTo }) => {
  const min736 = useMin736();

  return (
    <div className={styles[`post-row${hasLarge ? '-LARGE' : ''}`]}>
      {medias.map(({ size, direction, id, mediaType,previewNum,displayUrl,accessibilityCaption }) => {
        return(
          <div className={`${styles[`post-row-item`]} ${styles[`row-item-${hasLarge ? size : ''}`]} ${styles[`row-item-${hasLarge ? direction : ''}`]}`}>
          <Link to={`${linkTo}/${id}`}>
            <div className={styles['row-item-container']}>
              <div className={styles['item-container-content']}>
                <Image src={displayUrl} alt={accessibilityCaption} className={styles['container-content-img']} />
                {displayType == "POST" ? typeof mediaType != 'undefined' && <div className={styles['container-content-media']}>
                  <SVG type={mediaType} viewBox={mediaType != 'CAROUSEL' ? 24 : 48} fill="#fff" tag="span" size={min736 ? 24 : 20} className={styles['content-media-icon']} style={{ margin: min736 ? 15 : 8 }} />
                </div> :
                  <div className={styles['container-content-channel']}>
                    <div className={styles['content-channel-preview']}>
                      <SpriteIcon className={styles['channel-preview-icon']} type="1138" size={12} backgroundPosition="-413px -304px" backgroundSize="569px 521px" />
                      <ShortenNumber value={previewNum} />
                    </div>
                  </div>}
              </div>
            </div>
          </Link>
        </div>
        )
      })}
    </div>
  )
}


export default PostRow;