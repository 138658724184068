import React, { useState, useRef } from 'react'
import { SVG, SVGType, Avatar, NavBar, ImageUpload } from 'components'
import Notification from './Notification'
import { NavLink, useHistory } from 'react-router-dom';
import { User } from '../index'
import { connect } from 'react-redux';
import * as createPostAction from 'store/actions/createPostAction'
import styles from './styles.module.scss'


interface NavigationMenuProps {
  activeRoute?: string;
  activeSVGType?: SVGType;
  ariaLable?: string;
  notifications?: any;
  linkTo?: string;
  type?: SVGType;
  user?: User;
  onClick?: (e: React.UIEvent) => void;
  activeSVGViewBoxSize?: number;
}

const NavigationMenu: React.FC<NavigationMenuProps> = ({ activeRoute, onClick = () => { }, linkTo, activeSVGType, type, ariaLable, user, notifications = [], activeSVGViewBoxSize }) => {
  const isActive = linkTo == activeRoute;
  return (
    <>
      {user ? <>{isActive && <div className={styles['menu-avatar-active']} />} <Avatar src={user.avatar} alt={`${user.username}的头像`} size={24} /></> :
        <><SVG type={isActive ? activeSVGType : type} viewBox={isActive ? activeSVGViewBoxSize : null} ariaLable={ariaLable} onClick={onClick} />
          {notifications.length != 0 && <Notification NotificationStack={notifications} />}</>}
    </>
  )
}

interface AppNavigationProps {
  currentUser?: User,
  setStyle?: (style) => void;
}


const AppNavigation: React.FC<AppNavigationProps> = ({ currentUser, setStyle }) => {
  const [activeRoute, setActiveRoute] = useState<string>('');
  const uploadRef = useRef(null);
  const history = useHistory();

  const navigations: any[] = [
    {
      type: 'HOME',
      activeSVGType: 'HOMEACTIVE',
      ariaLable: '首页',
      notifications: [{ type: 'POST', num: 1 }],
      linkTo: '/'
    },
    {
      type: 'SEARCH',
      activeSVGType: 'SEARCHACTIVE',
      ariaLable: '搜索与发现',
      notifications: [],
      linkTo: '/explore/search',
      activeSVGViewBoxSize: 48
    },
    {
      type: 'NEWPOST',
      ariaLable: '新的帖子',
      notifications: [],
      onClick() {
        uploadRef.current.click();
      }
    },
    {
      type: 'LIKE',
      ariaLable: '动态',
      activeSVGType: 'LIKEACTIVE',
      notifications: [{ type: 'HEART', num: 1 }, { type: 'USER', num: 1 }],
      linkTo: '/activity',
      activeSVGViewBoxSize: 48
    },
    {
      user: currentUser,
      linkTo: `/${currentUser.fullname}`,
      notifications: [],
    }
  ]

  const isActive = (match, location) => {
    setActiveRoute(location.pathname)
    return false;
  }

  const onChange = (file, imageData) => {
    setStyle({ imageData })
    history.push({
      pathname: '/createPost/style',
    })
  }

  return (
    <NavBar fixedType="BOTTOM" borderType="TOP">
      <div className={styles['app-navigation']}>
        {navigations.map(({ linkTo, notifications, ...props }) => {
          const child = <NavigationMenu activeRoute={activeRoute} linkTo={linkTo} notifications={notifications} {...props} />
          return (
            <div className={`${styles['app-navigation-menu']} ${notifications.length ? styles['navigation-menu-notification'] : ''}`}>
              {linkTo ? <NavLink to={linkTo} isActive={isActive}>{child}</NavLink> : child}
            </div>
          )
        })}
      </div>
      <ImageUpload onChange={onChange} ref={uploadRef} />
    </NavBar>
  )
}

export default connect((state: any) => {
  return {
    currentUser: state.user.user
  }
}, {
  setStyle: createPostAction.setStyle
})(AppNavigation);
