import React, { useState } from 'react'
import { Navigations,Button,Message } from 'components'
import AdaptiveContainer, { AdaptiveContainerProps } from '../AdaptiveContainer'
import {DirectSearchUser,User} from '../index'
import styles from './styles.module.scss'


interface DirectShareMessageProps {
  selectedUsers?: User[];
  onSend?: (users:User[]) => void;
}

const DirectShareMessage: React.FC<DirectShareMessageProps> = ({selectedUsers,onSend}) => {
  return (
    <div className={styles['direct-share-message']}>
      <div className={`${styles['share-message-input']} ${selectedUsers.length ? '' : styles['share-message-hide']}`}>
        {selectedUsers.length != 0 && <input placeholder="输入消息" />}
      </div>
      <div className={styles['share-message-btn']}>
        <Button size="LARGE" disabled={selectedUsers.length == 0} onClick={() => { onSend(selectedUsers) }}>{selectedUsers.length > 1 ? '单独发送' : '发送'}</Button>
      </div>
    </div>
  )
}


const DirectShare: React.FC<AdaptiveContainerProps> = ({ onClose, ...props }) => {
  const [selectedUsers,setSeletedUsers] = useState<User[]>([]);
  return (
    <AdaptiveContainer {...props}>
      <Navigations title="分享" right={{ type: 'THINCLOSE', onClick: onClose, }} />
      <DirectSearchUser onSelectedUsersChange={(users) => {setSeletedUsers(users)}}/>
      <DirectShareMessage selectedUsers={selectedUsers} onSend={() => { onClose();Message.show('已发送') }}/>
    </AdaptiveContainer>
  )
}

export default DirectShare;