import React, { useEffect,useState,useRef,useCallback} from 'react'
import Message, { MessageProps } from './Message'
import { ScrollerContainer, ScrollerContainerProps,ObserverLoad } from 'components'
import {Time} from '../index'
import styles from './messageContainer.module.scss'


export interface MessageContainerProps {
  messages?: MessageProps[];
  renderMessageContent?: (message: MessageProps) => React.ReactNode;
  hasOlder?: boolean;
  onLoad?: () => void;
  hasSeen?: boolean;
  seenTime?: number;
}

const MessageContainer: React.FC<MessageContainerProps & ScrollerContainerProps> = ({ messages = [], renderMessageContent,hasOlder,onLoad,hasSeen,seenTime, ...props }) => {
  const scrollerRef = useRef<any>(null);
  const preMessagesRef  = useRef<any>([]);
  const [skip,setSkip] = useState<boolean>(true);
  const inEndRef  = useRef<boolean>(true);

  const onScroll = useCallback(() => {
    const scroller = scrollerRef.current.containerRef.current;
    inEndRef.current = scroller.scrollTop + scroller.clientHeight >= scroller.scrollHeight;

    skip && setSkip(false);
  },[skip])

  useEffect(() => {
    if(messages.length > preMessagesRef.current.length && (!preMessagesRef.current.length || messages[0].id == preMessagesRef.current[0].id)) {
      scrollerRef.current?.scrollToEnd();
    }
    preMessagesRef.current = messages;
  }, [messages.length]);

  useEffect(() => {
    inEndRef.current && Promise.resolve().then(() => { scrollerRef.current?.scrollToEnd() });
  }, [messages]);

  return (
    <div className={styles['message-container']}>
      <ScrollerContainer ref={scrollerRef} className={styles['message-container-content']} onScroll={onScroll} {...props}>
        <ObserverLoad visible={hasOlder} height={72} size={32} onLoad={onLoad} skip={skip} />
        {messages.map((msg) => (
          <Message {...msg} renderMessageContent={renderMessageContent} />
        ))}
        {hasSeen && <div className={styles['container-content-readed']}><Time date={seenTime} />读了</div>}
      </ScrollerContainer>
    </div>
  )
}

export default MessageContainer;