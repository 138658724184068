
import React from 'react'
import EventListenerHelper from 'utils/EventListenerHelper'

export default class extends React.PureComponent {
  constructor(...t) {
    super(...t),
      this.$DOMListener1 = null
  }
  componentDidMount() {
    this.$DOMListener2()
  }
  componentDidUpdate() {
    this.$DOMListener3(),
      this.$DOMListener2()
  }
  componentWillUnmount() {
    this.$DOMListener3()
  }
  $DOMListener2() {
    const {
      event: t,
      handler: n,
      target: s,
      ...o
    } = this.props;
    s && (this.$DOMListener1 = EventListenerHelper.add(s, t, n, o))
  }
  $DOMListener3() {
    this.$DOMListener1 && (this.$DOMListener1.remove(),
      this.$DOMListener1 = null)
  }
  render() {
    return null
  }
}