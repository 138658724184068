import { GuideActionType } from 'constants/guideActionType'


const initialState = {
  isFetching: true,
  guideItems: [],
  summary:{}
}

const followerReducer = (state=initialState,action) => {
  switch(action.type) {
    case GuideActionType.GUIDE_SET:
      return {
        ...state,
        ...action.guide
      }
    case GuideActionType.GUIDE_CHANGE_FETCH_STATUS :
      return {
        ...state,
        isFetching: action.isFetching
      }
    case GuideActionType.GUIDE_APPEND : 
      return {
        ...state,
        guideItems : [...state.guideItems,...action.guideItems]
      }
    case GuideActionType.GUIDE_CHANGE_LIKED:
      return {
        ...state,
        viewerHasLiked: action.liked
      }
    case GuideActionType.GUIDE_CHANGE_SAVED:
      return {
        ...state,
        viewerHasSaved: action.saved
      }
    default:
      return state;
  }
}



export default followerReducer;