import { ReportActionType } from 'constants/reportActionType'

const initialState = {
  isFetchReport: true,
  isFetchFeedback: false,
  report:{
    reportTags:[],
    title:''
  },
  followUp: {
    report : {},
    policyEducation: {},
    buttonText: ''
  },
  feedback:{},
  visible: false,
  step: 'REPORT'
}

const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case ReportActionType.REPORT_CHANGE_FETCH_STATUS:
      return {
        ...state,
        isFetchReport: action.isFetchReport
      }   
    case ReportActionType.REPORT_CHANGE_STEP:
      return {
        ...state,
        step : action.step
      }
    case ReportActionType.REPORT_SET:
      return {
        ...state,
        report: action.report
      }
    case ReportActionType.REPORT_SET_FOLLOWUP:
      return {
        ...state,
        followUp: action.followUp
      }
    case ReportActionType.REPORT_SET_FEEDBACK:
      return {
        ...state,
        feedback: action.feedback
      }
    case ReportActionType.REPORT_CHANGE_FEEDBACK_FETCH_STATUS:
      return {
        ...state,
        isFetchFeedback: action.isFetchFeedback
      }
    case ReportActionType.REPORT_TAG_CHANGE_FETCH_STATUS :
      state.report.reportTags.forEach((item,index) => {
        if(index == action.index) {
          item.isFetching = action.isFetching;
        } else {
          item.isFetching = false;
        }
      })
      return {
        ...state,
        report : {...state.report}
      }
    default :
      return state;
  }
}

export default reportReducer;