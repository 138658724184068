let o = null
  , t = null;


const dataURItoBlob = (dataURI) =>  {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1]);
  } else byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI
    .split(',')[0]
    .split(':')[1]
    .split(';')[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: mimeString });
}


export default function (n: any) {
  if (!n)
    return Promise.reject(new Error('no canvas'));
  if (n === t && o)
    return Promise.resolve(o);
  if ('function' == typeof n.toBlob)
    return new Promise(u => {
      n.toBlob(l => {
        t = n;
        o = l;
        u(l);
      }
        , 'image/jpeg')
    }
    );
  const u = n.toDataURL('image/jpeg');
  return t = n,
    o = dataURItoBlob(u),
    Promise.resolve(o)
}