import React,{useEffect} from 'react'
import {useHistory,useLocation} from 'react-router-dom'


interface EmptyLayoutProps  {
  pathname?: string;
  redirect?: string;
  unmounted?: () => void;
}

const EmptyLayout:React.FC<EmptyLayoutProps> = ({pathname,redirect,children,unmounted=() =>{}}) => {
  const history = useHistory();
  const location = useLocation();
  
  useEffect(() => {
    if(location.pathname == pathname) {
      history.replace({
        pathname:redirect,
      })
    }
    return () => {
      !history.location.pathname.startsWith(pathname) && unmounted();
    }
  },[location])

  return(
    <>
      {children}
    </>
  )
}

export default EmptyLayout;