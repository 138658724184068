import React from 'react'
import styles from './gridLines.module.scss'


type GridLinesProps = {
  visible?: boolean;
}


const GridLines:React.FC<GridLinesProps> = ({visible}) => {
  return (
    <>
      {visible && 
        <div className={styles['grid-lines']}>
          {[...new Array(4)].map(() => <div className={styles['grid-lines-thread']} />)}
        </div>}
    </>
  )
}

export default GridLines;