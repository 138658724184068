function t(t) {
  const o = n(t);
  return 3 * Math.floor((o - 1) / 3)
}

function n(t) {
  return t < 1 ? 0 : Math.floor(Math.log(Math.abs(t)) / Math.LN10) + 1
}

function o(o, E) {
  const u = t(o),
    s = n(o),
    M = Math.pow(10, u - ((s - u) % 3 ? E : E - 1));
  return (0,
    Math[o < 0 ? 'ceil' : 'floor'])(o / M) * M
}

const e = {}

e.truncateNumberPrecisionConsumer = function (t) {
    return o(t, 1)
  },
  e.CURRENCY = '$0,0.00',
  e.DATE = 'M/D/YY',
  e.DATE_LONG = 'ddd M/D/YY',
  e.DATE_TIME_LONG = 'l [at] h:mma',
  e.FLOAT = '0,0.00',
  e.FLOAT_INT = '0,.[00]',
  e.INTEGER = {
    thousandSeparated: !0
  },
  e.INTEGER_SHORTENED = {
    average: !0,
    mantissa: 1,
    optionalMantissa: !0,
    trimMantissa: !0
  },
  e.PERCENTAGE = '0%',
  e.PERCENTAGE_DELTA = '+0.0%',
  e.TIME = 'h:mma',
  e.TIME_PADDED = 'hh:mma'

e.formatLargeNumberForCJKLocale = function (t) {
  const o = Math.floor(Math.log10(t));
  let f = t / Math.pow(Math.pow(10, 4), Math.floor(o / 4));
  return f = f >= Math.pow(10, 3) ? Math.floor(f) : Math.floor(10 * f) / 10,
    String(f)
}

e.getSuffixForCJKLocale = function (t) {
  const o = 'zh_CN';
  if (t > 99999999) {
    if (o.startsWith('zh_'))
      return '亿';
  } else if (t > 9999) {
    if ('zh_CN' === o)
      return '万';
  }
  return ''
}


export default e;