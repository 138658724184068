import React from 'react'
import getBlob from './getBolob'
import {mutate} from 'components'
import FilterGLContext from './FilterGLContext'
import styles from './filteredImage.module.scss'


interface FilteredImageState {
  cssHeight?: number;
  cssWidth?: number;
  imageHeight?: number;
  imageWidth?: number;
}

interface FilterImageProps extends React.HTMLAttributes<HTMLDivElement> {
  onDraw?: () => void;
  filter?: string;
  src?: any;
  filterStrength?: number;

}

const t = 1080;

export default class FilteredImage extends React.Component<FilterImageProps, FilteredImageState> {

  $FilteredImage3: any;
  $FilteredImage7: () => void;
  $FilteredImage2: any;
  getBlob: () => any;
  $FilteredImage4: any;
  $FilteredImage1: any;

  constructor(props) {
    super(props);
    this.state = {
      cssHeight: 0,
      cssWidth: 0,
      imageWidth: t,
      imageHeight: t,
    }

    this.getBlob = () => this.$FilteredImage5().then(t => t.draw()).then(() => getBlob(this.$FilteredImage3));

    this.$FilteredImage7 = () => {
      const { onDraw } = this.props;
      onDraw();
    }
  }

  componentDidMount() {
    mutate.measure(() => {
      if (this.$FilteredImage2) {
        this.$FilteredImage3.addEventListener('webglcontextlost', t => {
          if(this.$FilteredImage4) {
            this.$FilteredImage4.stopDraw();
            this.$FilteredImage4 = null;
          }
          
        });
        this.$FilteredImage3.addEventListener('webglcontextrestored', () => {
          this.$FilteredImage5()
        });
        this.$FilteredImage5();
      }
    })
  }

  componentDidUpdate(props) {
    const { filter, filterStrength, src } = props;
    this.$FilteredImage5().then(t => {
      filter !== this.props.filter && t.setFilter(this.props.filter || 'normal');
      filterStrength !== this.props.filterStrength && t.setParam('filterStrength', this.props.filterStrength);
      (0 === this.state.cssHeight && 0 === this.state.cssWidth || filter !== this.props.filter || src !== this.props.src) && this.$FilteredImage6(t, this.props.src);
    }).catch(e => {
      console.log(e);
    })
  }

  componentWillUnmount() {
    this.$FilteredImage4 && this.$FilteredImage4.destroy()
  }

  $FilteredImage5() {
    if (!this.$FilteredImage4)
      try {
        const t = new FilterGLContext(this.$FilteredImage3);
        t.init();
        t.setOnDraw(this.$FilteredImage7);
        t.setFilter(this.props.filter);
        t.setParam('filterStrength', this.props.filterStrength);
        t.startDraw();
        this.$FilteredImage6(t, this.props.src);
        this.$FilteredImage4 = t;
      } catch (t) {
        return Promise.reject(t)
      }
    return Promise.resolve(this.$FilteredImage4)
  }
  $FilteredImage6(t, s) {
    return t.setImage(s).then(([t, s]) => {
      
      const i = window.innerWidth;
      let { width: h, height: l } = this.$FilteredImage2.getBoundingClientRect();
      if(s.width > s.height) {
        l = s.height / s.width * h
      } else {
        s.width < s.height && (h = s.width / s.height * l);
      }
      (h == 0 && l ==0) && (h = l = i);
      this.setState({
        cssHeight: l,
        cssWidth: h,
        imageHeight: s.height,
        imageWidth: s.width
      })
    })
  }
  render() { 
    const {cssHeight,cssWidth,imageHeight,imageWidth} = this.state;
    let o = t;
    let c = t;
    imageWidth > imageHeight ? c = imageHeight / imageWidth * t : o = imageWidth / imageHeight * t;

    const {onTouchStart,onTouchEnd} = this.props;

    return (
      <div className={styles['filtered-image']} onTouchStart={onTouchStart} onTouchEnd={onTouchEnd} ref={(t) => this.$FilteredImage2 = t}>
        <div className={styles['filtered-image-container']}>
          <div className={styles['image-container-content']}>
            <canvas width={o} height={c} style={{width:cssWidth,height:cssHeight}} ref={(t) => this.$FilteredImage3 = t} className={styles['container-content-canvas']}/>
          </div>
        </div>
      </div>
    )

  }
}