import React,{useState} from 'react'
import {SVG,Button} from 'components'
import ReportTags,{ ReportTag } from './ReportTags'
import {UnfollowConfirm,User,BlockActionConfirm} from '../index'
import styles from './reportFeedback.module.scss'

export interface Feeback {
  title?: string;
  text?: string;
  actions?: ReportTag[]
}

interface ReportFeedbackProps {
  feedback?: Feeback;
  onClose?: () => void;
  onUnfollowClick?: () => void;
  user?:User
}

const ReportFeedback: React.FC<ReportFeedbackProps> = ({user={},onUnfollowClick,onClose = () => { }, feedback}) => {
  const [unfollowVisible,setUnfollowVisible] = useState<boolean>(false);
  const [blockActionConfirmVisible,setBlockActionConfirmVisible] = useState<boolean>(false);
  const { text, title,actions } = feedback;
  const {username} = user;

  const onReportTagClick = (tag:ReportTag) => {
    switch(tag.tagAction) {
      case 'BLOCK' :
        setBlockActionConfirmVisible(true);
      break;
      case 'UNFOLLOW' :
        setUnfollowVisible(true);
      break;
    }
  }

  return (
    <div className={styles['report-feedback']}>
      <div className={styles['report-feedback-icon']}>
        <SVG type="CHECKMARK" fill="#58c322" size={48} ariaLable="勾选标记" />
      </div>
      <h4 className={styles['report-feedback-title']}>{title}</h4>
      <div className={styles['report-feedback-text']}>{text}</div>
      {actions.length != 0 && <ReportTags 
        className={styles['report-feedback-tags']}
        report={{reportTags:actions.map(tag => {
          let text;
          if(tag.tagAction == 'BLOCK' || tag.tagAction == 'UNFOLLOW') {
            text = `${tag.text} ${username}`;
          } else {
            text = tag.text;
          }
          return {...tag,text};
        })}} 
        onReportTagClick={onReportTagClick}
      />}
      <Button 
        style={{ margin: 16 }} 
        onClick={onClose}>
        关闭
      </Button>
      <UnfollowConfirm 
        visible={unfollowVisible} 
        user={user} 
        onUnfollowClick={onUnfollowClick} 
        onCancel={() => { setUnfollowVisible(false)}}
      />
      <BlockActionConfirm 
        visible={blockActionConfirmVisible} 
        username={user.username} 
        onCancel={() => { setBlockActionConfirmVisible(false)}} 
      />
    </div>
  )
}

export default ReportFeedback;