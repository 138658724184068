import * as React from 'react'
import styles from './styles.module.scss'
import {SpriteIcon,StickyModal,StickyModalProps} from 'components'

interface ShareProps extends StickyModalProps {
  onDirectClick?:() => void;
}

const Share: React.FC<ShareProps> = ({onDirectClick=() => {},onCancel,...props}) => {
  const shareItems = [
    {
      ariaLabel: '分享到 Direct',
      backgroundPosition: '-50px -428px',
      onClick:onDirectClick
    },
    {
      ariaLabel: '分享到 Facebook',
      backgroundPosition: '-275px -428px',
    },
    {
      ariaLabel: '分享到 Messager',
      backgroundPosition: '-322px -391px',
    },
    {
      ariaLabel: '分享到 WhatsApp',
      backgroundPosition: '-372px -391px',
      
    },
    {
      ariaLabel: '分享到 Twitter',
      backgroundPosition: '-347px -391px',
    },
    {
      ariaLabel: '通过邮件分享',
      backgroundPosition: '-461px -175px',
    },
    {
      ariaLabel: '复制链接',
      backgroundPosition: '-461px -100px',
    },
    {
      ariaLabel: '查看全部',
      backgroundPosition: '-325px -428px',
      
    }    
  ]
  return (
    <StickyModal onCancel={onCancel} title="分享到..." {...props}>
      <div className={styles['share']}>
        {shareItems.map(item => (
            <div className={styles['share-item']} onClick={item.onClick}>
              <SpriteIcon style={{marginRight :12}} size={24} backgroundSize="510px 477px" {...item} />
              <span>{item.ariaLabel}</span>
            </div>
          ))}
        <div className={styles['share-item']} onClick={onCancel}>取消</div>
      </div>
    </StickyModal>
  );
}

export default Share;
