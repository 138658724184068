import React from 'react'
import styles from './styles.module.scss'
import {ConfirmModal, ConfirmModalProps,ListModal,Image } from 'components'



const AddToScreen: React.FC<ConfirmModalProps> = ({ ...props }) => {

  const titleChild = (
    <div className={styles['to-screen-icon']}>
      <Image src={require('./images/logo.png')} width={76} height={76} alt="" />
    </div>
  )
  return (
    <ConfirmModal  {...props} titleChild={titleChild} title="把 Instagram 添加到主屏幕？" text="在主屏幕添加 Instagram 即可快速访问。">
      <ListModal.Item type="PRIMARY">添加到主屏幕</ListModal.Item>
    </ConfirmModal>
  )
}

export default AddToScreen;
