import { CommentsActionType } from 'constants/commentsActionType'
import {getComments} from 'services/containers/comments'


export const setComments = (comments) => {
  return {
    type: CommentsActionType.COMMENTS_SET,
    comments
  }
}

export const appendComments = (comments) => {
  return {
    type: CommentsActionType.COMMENTS_APPEND,
    comments
  }
}

export const changeLikeStatus = (id,liked) => {
  return {
    type: CommentsActionType.COMMENTS_CHANGE_LIKE_STATUS,
    id,liked
  }
}

export const changeChildLikeStatus = (id,childId,liked) => {
  return {
    type: CommentsActionType.COMMENTS_CHANGE_CHILD_LIKE_STATUS,
    id,liked,childId
  } 
}

export const addComment = (comment) => {
  return {
    type : CommentsActionType.COMMENTS_ADD,
    comment
  }
}

export const changeChildFetchStatus = (id,isFetchChildComments) => {
  return {
    type: CommentsActionType.COMMENTS_CHANGE_CHILD_FETCH_STATUS,
    id,isFetchChildComments
  }
}

export const changeFetchStatus = (isFetching) => {
  return {
    type : CommentsActionType.COMMENTS_CHANGE_FETCH_STATUS,
    isFetching
  }
}



export const deleteComment = (id) => {
  return {
    type : CommentsActionType.COMMENTS_DELETE_COMMENT,
    id
  }
}

export const changeAvatarLoadingStatus = (id,isAvatarLoading) => {
  return {
    type : CommentsActionType.COMMENTS_CHANGE_AVATAR_LOADING_STATUS,
    id,
    isAvatarLoading
  }
}

export const changeViewerSeenStory = (id,viewerSeenStory) => {
  return {
    type : CommentsActionType.COMMENTS_CHANGE_VIEWER_SEEN_STORY,
    id,
    viewerSeenStory
  }
}


export const deleteChildComment = (id,childId) => {
  return {
    type : CommentsActionType.COMMENTS_DELETE_CHILD_COMMENT,
    id,childId
  }  
}

export const addChildComment = (id,comment) => {
  return {
    type : CommentsActionType.COMMENTS_ADD_CHILD_COMMENT,
    id,comment,
  }
}


export const appendChildComments = (id,comments) => {
  return {
    type : CommentsActionType.COMMENTS_APPEND_CHILD_COMMENT,
    id,
    comments
  }  
}

export const changeExpandedStatus = (id,expanded) => {
  return {
    type : CommentsActionType.COMMENTS_CHANGE_EXPANDED_STATUS,
    expanded,
    id
  }
}

export const fetchChildComments = (id) => {
  return async (dispatch) => {
    dispatch(changeChildFetchStatus(id,true))
    const {comments}:any = await getComments();
    dispatch(appendChildComments(id,comments));
    dispatch(changeChildFetchStatus(id,false))
  }
}

export const fetchComments = (isAppend,effectFetchStatus=false) => {
  return async (dispatch) => {
    effectFetchStatus && dispatch(changeFetchStatus(true));
    const comments = await getComments();
    dispatch((isAppend ? appendComments : setComments)(comments));
    effectFetchStatus && dispatch(changeFetchStatus(false));
  }
}


