import React from 'react'
import {ModalProps,ConfirmModal} from 'components'


interface BlockFeedbackProps extends ModalProps {
  username?: string; 
  blocked?: boolean;
}

const BlockActionFeedback:React.FC<BlockFeedbackProps> = ({blocked,username,...props}) => {

  const title = `${blocked ? '已' : '取消'}屏蔽 ${username}`
  const text = blocked ? '你可以随时在用户的个人主页取消屏蔽。' : '你可以随时前往用户的主页屏蔽他们。';

  return (
    <ConfirmModal cancleText="关闭" title={title} text={text} {...props} />
  )
}


export default BlockActionFeedback;