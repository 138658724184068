import React,{createContext,useContext,useState,useCallback,useEffect} from 'react';
import styles from './styles.module.scss'


const CheckboxGroupContext = createContext<any>({});

interface Option {
  label?: string;
  value?: any;
}

interface CheckboxGroupProps {
  value?: string[];
  onChange?: (v:string[]) => void;
  options?:Option[];
  style?: React.CSSProperties;
  className?: string;
  defaultValue?: string[];
  disabled?: boolean;
}

export const ChekcboxGroup:React.FC<CheckboxGroupProps> = ({onChange,disabled,style,className,children,options=[],defaultValue=[]}) => {
  const [checkedMap,setCheckedkMap] = useState(() => {
    const checkedMap = {};
    defaultValue.forEach(key => {checkedMap[key] = true});
    return checkedMap;
  });

  const _onChange = useCallback((e:React.ChangeEvent<HTMLInputElement>,value) => {
    if(value) {
      checkedMap[value] = e.target.checked;
      setCheckedkMap({...checkedMap});
    }
  },[checkedMap])


  useEffect(() => {
    onChange(Object.keys(checkedMap).filter(key => checkedMap[key]))
  },[checkedMap])

  return (
    <CheckboxGroupContext.Provider value={{_onChange}} >
      <div className={className}  style={style}>
        {options.map(item => <Checkbox disabled={disabled} defaultChecked={defaultValue.includes(item.value)} onChange={(e) => { _onChange(e,item.value) }}>{item.label}</Checkbox>)}
        {options.length == 0 && children}
      </div>
    </CheckboxGroupContext.Provider>
  )
}


interface CheckboxProps  {
  defaultChecked?: boolean;
  disabled?: boolean;
  onChange?: (e:React.ChangeEvent<HTMLInputElement>) => void;
  children?: React.ReactNode;
  checked?:boolean;
  className?: string;
  value?: any;
}


const Checkbox:React.FC<CheckboxProps> = ({defaultChecked,disabled,onChange,children,checked,value}) => {
  const { _onChange } = useContext(CheckboxGroupContext);

  return (
    <label className={`${styles['checkbox']} ${disabled ? styles['checkbox-disabled'] : ''}`}>
      <input type="checkbox" value={value}  onChange={onChange ? onChange : (e) => { _onChange && _onChange(e,value) }} defaultChecked={defaultChecked} checked={checked} />
      <div className={styles['checkbox-box']} />
      {children}
    </label>
  )
}


export default Checkbox;