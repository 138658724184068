import { FollowingActionType } from 'constants/followingActionType'


const initialState = {
  following: [],
  isFetching: true
}

const followerReducer = (state=initialState,action) => {
  switch(action.type) {
    case FollowingActionType.FOLLOWING_SET:
      return {
        ...state,
        following: action.following
      }
    case FollowingActionType.FOLLOWING_CHANGE_AVATAR_LOADING_STATUS:
      return {
        ...state,
        following: state.following.map(item => {
          if(item.user.id == action.id) {
            item.user.isAvatarLoading = action.isAvatarLoading;
          }
          return item;
        })
      }
    case FollowingActionType.FOLLOWING_CHANGE_VIEWER_SEEN_STORY:
      return{
        ...state,
        following: state.following.map(item => {
          if(item.user.id == action.id) {
            item.viewerSeenStory = action.viewerSeenStory;
          }
          return item;
        })
      }
    case FollowingActionType.FOLLOWING_CHANGE_FETCH_STATUS :
      return {
        ...state,
        isFetching: action.isFetching
      }
    case FollowingActionType.FOLLOWING_APPEND : 
      return {
        ...state,
        following : [...state.following,...action.following]
      }
    case FollowingActionType.FOLLOWING_CHANGE_FOLLOW_STATUS :
      return {
        ...state,
        following : state.following.map(item => {
          if(item.user.id == action.id) {
            item.viewerFollowStatus = action.viewerFollowStatus;
          }
          return item;
        })
      }
    default:
      return state;
  }
}



export default followerReducer;