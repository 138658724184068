import { DirectSearchUserActionType } from 'constants/directSearchUserActionType'
import {getDirectSearchUser} from 'services/commons/directSearchUser'

export const changeSearchUserFetchStatus = (isFetching) => {
  return {
    type: DirectSearchUserActionType.DIRECT_SEARCH_USER_CHANGE_FETCH_STATUS,
    isFetching
  }
}


export const setSearchUsers = (searchUsers) => {
  return {
    type: DirectSearchUserActionType.DIRECT_SEARCH_USER_SET,
    searchUsers
  }
}


export const setSuggestedUser = (suggested) => {
  return {
    type: DirectSearchUserActionType.DIRECT_SEARCH_USER_SET_SUGGESTED,
    suggested
  }
}

export const fetchSuggested = () => {
  return function(dispatch) {
    return new Promise<void>(async (resolve) => {
      const suggested = await getDirectSearchUser();
      dispatch(setSuggestedUser(suggested));
      resolve();
    })
  }  
}

export const fetchSearchUser = () => {
  return function(dispatch) {
    return new Promise<void>(async (resolve) => {
      dispatch(changeSearchUserFetchStatus(true))
      const searchUsers = await getDirectSearchUser();
      dispatch(setSearchUsers(searchUsers));
      dispatch(changeSearchUserFetchStatus(false))
      resolve();
    })
  }   
}