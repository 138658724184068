import React, {  useState,useEffect } from 'react'
import { routes } from './routes'
import { HashRouter,useLocation } from "react-router-dom";
import { loading } from 'components'
import * as userAction from 'store/actions/userAction'
import { connect } from 'react-redux';
import {RenderRoutes,AppNavigation} from 'containers/Commons'
import { RouterHooks } from 'hocs/withAsyncRouter'
import ReactGA from 'react-ga';

ReactGA.initialize('UA-171535365-2');

const { beforeRouterComponentLoad, afterRouterComponentDidLoaded } = RouterHooks;


interface LayoutProps {
  fetchCurrentUser?: () => void;
  authed?: boolean;
}

const Layout:React.FC<LayoutProps> = ({fetchCurrentUser,authed}) => {
  const [navigationVisible, setNavigationVisible] = useState<boolean>(false);
  const location = useLocation();

  beforeRouterComponentLoad(({navigationVisible}) => {
    loading.start({ height: 3 });
    setNavigationVisible(navigationVisible);
  })

  afterRouterComponentDidLoaded(history => {
    loading.done();
  })

  useEffect(() => {
    authed && fetchCurrentUser();
  },[authed])

  useEffect(() => {
    ReactGA.pageview(location.pathname)
  },[location])

  console.log(authed);

  return (
    <>
      <RenderRoutes routes={routes} authed={authed} authPath="/signin"/>
      {navigationVisible && <AppNavigation />}
    </>
  );
}

const _Layout = connect((state:any) => {
  return {
    authed: state.auth.authed
  }
},{
  fetchCurrentUser:userAction.fetchUser,
})(React.memo(Layout));


const App = () => {
  return (
    <HashRouter>
      <_Layout />
    </HashRouter>
  )
}

export default App;
