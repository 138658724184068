export enum CommentsActionType {
  COMMENTS_SET = 'COMMENTS_SET',
  COMMENTS_APPEND_CHILD_COMMENT = 'COMMENTS_APPEND_CHILD_COMMENT',
  COMMENTS_APPEND ='COMMENTS_APPEND',
  COMMENTS_ADD = 'COMMENTS_ADD',
  COMMENTS_ADD_CHILD_COMMENT = 'COMMENTS_ADD_CHILD_COMMENT',
  COMMENTS_CHANGE_LIKE = 'COMMENTS_CHANGE_LIKE',
  COMMENTS_CHANGE_FETCH_STATUS = 'COMMENTS_CHANGE_FETCH_STATUS',
  COMMENTS_CHANGE_CHILD_FETCH_STATUS = 'COMMENTS_CHANGE_CHILD_FETCH_STATUS',
  COMMENTS_CHANGE_LIKE_STATUS = 'COMMENTS_CHANGE_LIKE_STATUS',
  COMMENTS_CHANGE_CHILD_LIKE_STATUS = 'COMMENTS_CHANGE_CHILD_LIKE_STATUS',
  COMMENTS_CHANGE_EXPANDED_STATUS = 'COMMENTS_CHANGE_EXPANDED_STATUS',
  COMMENTS_DELETE_COMMENT = 'COMMENTS_DELETE_COMMENT',
  COMMENTS_DELETE_CHILD_COMMENT = 'COMMENTS_DELETE_CHILD_COMMENT',
  COMMENTS_CHANGE_AVATAR_LOADING_STATUS = 'COMMENTS_CHANGE_AVATAR_LOADING_STATUS',
  COMMENTS_CHANGE_VIEWER_SEEN_STORY = 'COMMENTS_CHANGE_VIEWER_SEEN_STORY',
}