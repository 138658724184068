export default class {
  constructor({
    estimatedSize: t
  }) {
    this.$SizeCache1 = new Map,
      this.$SizeCache2 = t
  }
  setSize(t, s) {
    this.$SizeCache1.set(t, s)
  }
  getSize(t) {
    const s = this.$SizeCache1.get(t);
    return null != s ? s : this.$SizeCache2
  }
  getOffset(t) {
    let s = 0;
    for (let n = 0; n < t; n++)
      s += this.getSize(n);
    return s
  }
  getDistance(t, s) {
    let n = 0;
    for (let c = t; c < s; c++)
      n += this.getSize(c);
    return n
  }
  getIndex(t, s) {
    let n = 0;
    for (let c = 0; c < s; c++)
      if ((n += this.getSize(c)) > t)
        return c;
    return s
  }
}