import { ProfileActionType } from 'constants/profileActionType'


const initialState = {
  profile: {
    owner: {}
  },
  isFetchProfile: true,
  isOwn: false,
  highlightsTray: [],
  isFetchHiglights: true,
  isFetchPosts: true,
  posts: [],
  guides: [],
  guidesNums: 0,
  isFetchGuides: true,
}

const ProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case ProfileActionType.PROFILE_CHANGE_AVATAR_LOADING_STATUS:
      return {
        ...state,
        profile: {
          ...state.profile,
          owner: {
            ...state.profile.owner,
            isAvatarLoading: action.isAvatarLoading
          }
        }
      }
    case ProfileActionType.PROFILE_SET:
      return {
        ...state,
        profile: action.profile
      }
    case ProfileActionType.PROFILE_CHANGE_FETCH_STATUS:
      return {
        ...state,
        isFetchProfile: action.isFetchProfile
      }
    case ProfileActionType.PROFILE_CHANGE_BLOCK_STATUS:
      return {
        ...state,
        profile: {
          ...state.profile,
          blockedByViewer: action.blocked
        }
      }
    case ProfileActionType.PROFILE_CHANGE_HIGHLIGHTS_AVATAR_LOADING_STATUS:
      return {
        ...state,
        highlightsTray: state.highlightsTray.map(user => {
          user.id == action.id && (user.isAvatarLoading = action.isAvatarLoading);
          return user;
        })
      }
    case ProfileActionType.PROFILE_CHANGE_RESTRIT_STATUS:
      return {
        ...state,
        profile: {
          ...state.profile,
          restrictedByViewer: action.restricted
        }
      }
    case ProfileActionType.PROFILE_CHANGE_FOLLOW_STATUS:
      return {
        ...state,
        profile: {
          ...state.profile,
          viewerFollowStatus: action.viewerFollowStatus
        }
      }
    case ProfileActionType.PROFILE_SET_IS_OWN:
      return {
        ...state,
        isOwn: action.isOwn
      }
    case ProfileActionType.PROFILE_SET_HIGHLIGHTS_TRAY:
      return {
        ...state,
        highlightsTray: action.highlightsTray
      }
    case ProfileActionType.PROFILE_CHANGE_FETCH_HIGHLIGHTS_STATUS:
      return {
        ...state,
        isFetchHiglights: action.isFetchHiglights
      }
    case ProfileActionType.PROFILE_SET_POSTS:
      return {
        ...state,
        posts: action.posts
      }
    case ProfileActionType.PROFILE_CHANGE_FETCH_POSTS_STATUS:
      return {
        ...state,
        isFetchPosts: action.isFetchPosts
      }
    case ProfileActionType.PROFILE_APPEND_POSTS:
      return {
        ...state,
        posts: [...state.posts, ...action.posts]
      }
    case ProfileActionType.PROFILE_SET_GUIDES:
      console.log(action.guides);
      return {
        ...state,
        guides: action.guides.guides,
        guidesNums: action.guides.nums
      }
    case ProfileActionType.PROFILE_CHANGE_FETCH_GUIDES_STATUS:
      return {
        ...state,
        isFetchGuides: action.isFetchGuides
      }
    case ProfileActionType.PROFILE_APPEND_GUIDES:
      return {
        ...state,
        guides: [...state.guides, ...action.guides.guides]
      }
    default:
      return state;
  }
}



export default ProfileReducer;