import React,{useState,useRef,useCallback} from 'react'
import {SpriteIcon,Spin,Button} from 'components'
import { debounce } from 'throttle-debounce';
import styles from './searchInput.module.scss'


interface  SearchInputProps {
  loading?: boolean;
  placeholder?: string;
  onChange?: (e) => void;
  onFocus?: () => void;
  defaultValue?: string;
  className?: string;
  hasPlaceholderAnimate?: boolean;
  searchIcon?: React.ReactNode;
}


const SearchInput:React.FC<SearchInputProps> = ({loading=false,defaultValue,onFocus=() => {},placeholder="搜索",onChange=()=>{},className,hasPlaceholderAnimate = true,searchIcon= <SpriteIcon size={10} type="1088" backgroundPosition="-291px -143px" backgroundSize="544px 520px" ariaLabel="搜索" />}) => {
  const [active,setActive] = useState<boolean>(false);
  const [value,setValue] = useState<string>('');
  const inputEl = useRef<any>();

  const _onChange = useCallback(debounce(300,true,onChange),[])

  const onClearClick = () => {

    const input = inputEl.current;
    const lastValue = input.value;
    input.value = '';
    const event = new Event("input", { bubbles: true });
    const tracker = input._valueTracker;
    if (tracker) {
      tracker.setValue(lastValue);
    }
    input.dispatchEvent(event);
  }

  return (
    <div className={`${styles['search-input']} ${className}`}>
      <label className={styles['search-input-label']}>
        <input ref={inputEl} onBlur={() => { !value.length && setActive(false) }} defaultValue={defaultValue} onFocus={() => { setActive(true);onFocus() }} onChange={(e) => {_onChange(e);setValue(e.target.value) }} />
        <div className={`${styles['input-label-placeholder']} ${(active || !hasPlaceholderAnimate) ? styles['label-placeholder-active'] : ''}`} >
          <div className={styles['label-placeholder-icon']}>{searchIcon}</div>
          <div className={styles['label-placeholder-text']}>{value.length ? '' : placeholder}</div>
        </div>
        <div className={styles['input-label-actions']} >
          {value.length != 0 && !loading && <Button type="TEXT" style={{padding:0}} onClick={onClearClick}>
            <SpriteIcon size={20} type="1088" backgroundSize="544px 520px" backgroundPosition="-520px -475px" ariaLabel="关闭"/>
          </Button>}
          <Spin visible={loading} type="THINSPIN" size={18}/>
        </div>
      </label>
    </div>
  )
}


export default SearchInput;