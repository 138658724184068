import { ExploreLocationsActionType } from 'constants/exploreLocationsActionType'


const initialState = {
  name:'',
  postHot:[],
  postRecent:[],
  isFetching:true
}

const exploreLocationsReducer = (state=initialState,action) => {
  switch(action.type) {
    case ExploreLocationsActionType.EXPLORE_LOCATIONS_SET:  
      return {
        ...state,
        ...action.exploreLocations
      }
    case ExploreLocationsActionType.EXPLORE_LOCATIONS_SET_RECENT_POST:
      return {
        ...state,
        postRecent: [...state.postRecent,...action.postRecent]
      }
    case ExploreLocationsActionType.EXPLORE_LOCATIONS_CHANGE_FETCH_STATUS:
      return {
        ...state,
        isFetching: action.isFetching
      }
    default:
      return state;
  }
}



export default exploreLocationsReducer;