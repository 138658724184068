import { SignupActionType } from 'constants/signupActionType'

const initialState = {
  signupBy: {
    phoneNumber: '',
    email: ''
  }
}


const signupReducer = (state = initialState, action) => {
  switch (action.type) {
    case SignupActionType.SIGNUP_SET_SIGNUP_BY:
      return {
        ...state,
        signupBy: action.signupBy
      }
    case SignupActionType.SIGNUP_SET_COUNTRY_CODE:
      return {
        ...state,
        countryCode: action.countryCode
      }
    case SignupActionType.SIGNUP_SET_ACTIVE_SIGNUP_BY:
      return {
        ...state,
        activeSignupBy: action.activeSignupBy
      }
    case SignupActionType.SIGNUP_RESET:
      return initialState
    default:
      return state;
  }
}



export default signupReducer;