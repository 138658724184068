export default {
  '3' : `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQAAAAABCAIAAAC+O+cgAAAAmElEQVR4AYWSAQrFIAxDk/b+Z/brAg+n8NfBqPHVtG6WVC5Z9YS3mEtJXlu9AGfp8HmHJP+Mrt5JHMlRYtHdiJJgkuCLPvlgKLWovRb9rGXkQ4QEeOeTUVy4jaFxjyYNm3IDSEL8o7OFyLeL+zSliQOjdlcAmDGdJzFxN4P4PPBIUeAPr7hwS18uZ7f4ZiiOume/G8Cd3+kHOmsT/6fpN88AAAAASUVORK5CYII=`,
  '4' : `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQAAAAABCAIAAAC+O+cgAAAAuklEQVR4AXWN0aodMAzDJPVc9v9f3D2EwMruCSXEcrH9/PzpfIA6logzxw5cNA86UrKjaoCG6hKHpKr/Hen+Gdm6YzpHKFyrc1h8xb0tQ7tgWMM78bRZKoBVqg03uHs3ASWw0lMv9NjlZmcS4BRyrGyzkVRtuToQGJlaQwD1G3egpSoiTsvoMQA3YaW6jTtLvJDqLhy4+W4nIsPfAdy9aHkPf/4Y3H/hegLwe8uFh3x3F4K477L3VOz+C3hvEPMkZMRDAAAAAElFTkSuQmCC`,
  '6' : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQAAAAABCAIAAAC+O+cgAAAAdUlEQVR4AY2R0QqEQAzEOrn//2dB2TD0Qa9im0lFWM2cSuQyL/FWO/qkY3VnQ9KwC1wBLe00CyFEILgFjqccequi9UOpsULk9XZbYiPMjIfAK06bt6fdH46tKjlUJmmmdk5lx/F/PqF8FktfvKTYW23Dv1KQL5LvAvyLWybsAAAAAElFTkSuQmCC",
  '7' : `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQAAAAABCAIAAAC+O+cgAAAAOklEQVR4AWM0NzdnZGRkYGBgBAM4m2JBwrJkqKc/gxZa0LhE6qVUGWEuYTUUChKWwiShFJSmFglnAADiPAJ+JpEjRQAAAABJRU5ErkJggg==`,
  '9': `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQAAAAABCAAAAAAUMi+rAAAALklEQVR4AWNkwASMIAyBMMAEwUzYCNwCGHwoghJgBtgSuE1wHgOYYoAR5ENCCgB1bgDTOD3fEQAAAABJRU5ErkJggg==`,
  '10': `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQAAAAABCAIAAAC+O+cgAAAAPUlEQVR4AWM0NjZmZGRkgAE4m2JBBAM/lzwSk4vJQHBJAUxMTLjYcAYeEpOLyYBzcbkQoh+vLxDilHBHuQAVYAL8PgEV/gAAAABJRU5ErkJggg==`,
  '12' : require('./images/texture/texture-12.png'),
  '14' : require('./images/texture/texture-14.png'),
  '15' : require('./images/texture/texture-15.png'),
  '17' : require('./images/texture/texture-17.png'),
  '18' : require('./images/texture/texture-18.png'),
  '20' : require('./images/texture/texture-20.png'),
  '21' : require('./images/texture/texture-21.png'),
  '23' : require('./images/texture/texture-23.png'),
  '24' : `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAEACAIAAAD9XIvPAAAAiklEQVR4AaWSUQpCMQwEt72XJ/UuHq0ruB9DIBGsH49MZpNSHt2P52tLaj5PDjZurHZ1cJfN3uS4X/sz9AcuLnXB1MLeq9//7HIXmDPjV5i8MBlzirfjJTg+jj47nJm+7CRP/yWzUmFmuvl2l3c0Ozx3VVhw6w3fed542Hiy0sPi/9/l/Uxzjz+/N3BmyfY6YQEeAAAAAElFTkSuQmCC`
}