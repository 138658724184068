import ReactDom from 'react-dom'

function t() {
  return c.length || f.length
}

function n() {
  s || (requestAnimationFrame(() => u()),
    s = !0)
}

function u(u = !1) {
  let l = null;
  try {
    for (; t();)
      ReactDom.unstable_batchedUpdates(() => {
        o(f)
      }),
      o(c)
  } catch (t) {
    l = t
  }
  if (s = !1,
    l)
    throw t() && !u && n(),
      l
}

function o(t) {
  for (; 0 !== t.length;)
    t.shift()()
}

const c = [],
  f = [];
let s = !1;
const l = u;
const e = {};
e.measure = function (t, u = !1) {
    c.push(t),
      u || n()
  },
  e.mutate = function (t, u = !1) {
    f.push(t),
      u || n()
  },
  e._flush = l


export default e;