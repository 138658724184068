import React,{useMemo,useState,useRef} from 'react'
import {ListModal,ListModalProps,ImageUpload,ImageData,Message} from 'components'
import useBodyOverflowHidden from 'hooks/useBodyOverflowHidden'
import PhotoCreate from '../PhotoCreate'

import styles from './styles.module.scss'

interface ChangeAvatar extends ListModalProps {
  onRemoveAvatarClick?: () => void;
  onChangeAvatar?: (blob:string) => void;
}

const ChangeAvatar:React.FC<ChangeAvatar> = ({onRemoveAvatarClick=() => {},onChangeAvatar=() =>{},onCancel,...props}) => {
  
  const [photoCreateVisible,setPhotoCreateVisible] = useState<boolean>(false);
  const [imageData,setImageData] = useState<ImageData>();
  const uploadRef = useRef(null);

  useBodyOverflowHidden(photoCreateVisible)

  const onChange = (file,imageData) => {
    setImageData(imageData);
    setPhotoCreateVisible(true);
  }

  const changeAvatarOptions:any[] = useMemo(() => {
    return [
      {
        type :'PRIMARY',
        text :'上传头像',
        onClick() {
          uploadRef.current.click();
        }
      },
      {
        type :'WARNING',
        text :'移除当前头像',
        onClick: onRemoveAvatarClick
      }
    ]

  },[onRemoveAvatarClick,onChangeAvatar])

  return (
    <>
      <ListModal onCancel={onCancel} {...props}>
        <ListModal.Item >
          <h3 className={styles['change-avatar-title']}>更换头像</h3>
        </ListModal.Item>
        {changeAvatarOptions.map(({text,onClick,...props}) => {
          return <ListModal.Item onClick={() => { onClick();onCancel(); }} {...props}>{text}</ListModal.Item>
        })}
      </ListModal>
      <ImageUpload onChange={onChange} ref={uploadRef}  />
      {photoCreateVisible && <div className={styles['change-avatar']}>
        <PhotoCreate isForAvatar={true} title="头像" nextText="保存" imageData={imageData} onCloseClick={() => { setPhotoCreateVisible(false) }} onNextClick={(blob) => {
          setPhotoCreateVisible(false);
          onChangeAvatar(URL.createObjectURL(blob));
          Message.show('头像添加成功');
        }} />
      </div>}
    </>
  )
}


export default ChangeAvatar;