import React from 'react'
import getBolob from './getBolob'
import FilteredImage from './FilteredImage'
import CreationFilterThumbnail from './CreationFilterThumbnail'
import {FILTERS,FILTERS_NAMES} from '../ImageFilter/Filter'
import {PhotoContext} from './PhotoContext'
import {VirtualList} from 'components'
import { InView } from 'react-intersection-observer'
import styles from './creationFiltering.module.scss'



interface CreationFilteringProps {
  filterName?: string;
  filterParams?: any;
  imageHeight?: number;
  imageWidth?: number;
  viewportWidth?: number;
  isLoading?: boolean;
  onCancle?: () => void;
  onFilterChange?: (t: any) => void;
  pixelRatio?: number;
  onDraw?: () => void;
}


interface CreationFilteringStateState {
  shouldHideFilter?: boolean;
  currentFilter?: string;
  isActive?: boolean;
}

class CreationFiltering extends React.Component<CreationFilteringProps, CreationFilteringStateState> {
  $CreationFilteringUnit1: any;
  $CreationFilteringUnit2: (t: any) => void;
  $CreationFilteringUnit4: () => void;
  $CreationFilteringUnit5: () => void;
  $CreationFilteringUnit6: () => void;
  getBlob: () => any

  static contextType = PhotoContext;
  $CreationFilteringUnit8: () => void
  $CreationFilteringUnit3: any
  $CreationFilteringUnit9: ({ index: t }: { index: any }) => void
  

  constructor(props) {
    super(props);
    this.state = {
      shouldHideFilter: false,
      currentFilter: props.filterName || 'normal',
      isActive:false
    }

    this.$CreationFilteringUnit2 = (t) => {
      this.setState({
        currentFilter: t
      })
      this.props.onFilterChange && this.props.onFilterChange(t)
    }

    this.$CreationFilteringUnit4 = () => {
      this.props.onDraw && this.props.onDraw()
    }

    this.$CreationFilteringUnit5 = () => {
      this.setState({
        shouldHideFilter: !0
      })
    }


    this.$CreationFilteringUnit6 = () => {
      this.setState({
        shouldHideFilter: !1
      })
    }

    this.getBlob = () => {
      const { currentFilter } = this.state;
      if (currentFilter == 'normal') {
        return getBolob(this.context.canvasImage);
      } else {
        return this.$CreationFilteringUnit1.getBlob()
      }
    }

    this.$CreationFilteringUnit8 = ()=>{
      const t = this.$CreationFilteringUnit3;
      if (null == t)
          return;
      const n = FILTERS_NAMES.indexOf(this.state.currentFilter);
      t.scrollToItem(n)
    }


    this.$CreationFilteringUnit9 = ({index:t}) => {
      const {pixelRatio} = this.props;
      const {currentFilter} = this.state;
      const o = FILTERS_NAMES[t];
      return <CreationFilterThumbnail key={t} filterBuilder={FILTERS[o]} isSelected={currentFilter === o} name={o} pixelRatio={pixelRatio} onClick={this.$CreationFilteringUnit2.bind(this)} /> 

    }
  }

  shouldComponentUpdate(t, n) {
    return this.state.isActive !== n.isActive || this.props.filterParams !== t.filterParams || this.props.isLoading !== t.isLoading || this.state.shouldHideFilter !== n.shouldHideFilter || this.state.currentFilter !== n.currentFilter
  }

  render() {
    const t = 1
    , n = 8
    , s = 106;

    const {shouldHideFilter: o,currentFilter,isActive} = this.state
    , {filterParams={},viewportWidth: u,} = this.props
    , {filterStrength: F} = filterParams;
    
    const U = Math.max(n, Math.ceil(u / s));

    return (
      <div className={styles['creation-filtering']}>
        {this.context.canvasImage && <FilteredImage src={this.context.canvasImage} filterStrength={o ?  0 : F} ref={(t) => { this.$CreationFilteringUnit1 = t }} onDraw={this.$CreationFilteringUnit4.bind(this)}  filter={currentFilter} onTouchEnd={this.$CreationFilteringUnit6.bind(this)} onTouchStart={this.$CreationFilteringUnit5.bind(this)} />}
        <InView triggerOnce={true} onChange={(isActive) => { this.setState({isActive}) }}>
          {isActive && <VirtualList 
            className={styles['creation-filtering-thumbnail']} 
            containerSize="auto"
            direction="horizontal"
            estimatedItemSize={100}  
            initialRenderCount= {U}
            itemCount={FILTERS_NAMES.length}
            onInitialize={this.$CreationFilteringUnit8.bind(this)}
            overscanCount={t}
            ref={(t) =>{ this.$CreationFilteringUnit3 = t }}
            renderer={this.$CreationFilteringUnit9.bind(this)}
          />}
        </InView>
      </div>
    )


  }

}


export default CreationFiltering;