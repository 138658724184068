import { ExploreActionType } from 'constants/exploreActionType'


const initialState = {
  suggestedMedias : [],
  isFetching: false,
  searchRecent: [],
  searchResult: []
}

const addToScreenReducer = (state=initialState,action) => {
  switch(action.type) { 
    case ExploreActionType.EXPLORE_SET_SUGGESTED_MEDIAS:
      return {
        ...state,
        suggestedMedias:[...state.suggestedMedias,...action.suggestedMedias]
      }
    case ExploreActionType.EXPLORE_CHANGE_FETCH_STATUS:
      return {
        ...state,
        isFetching: action.isFetching
      }
    case ExploreActionType.EXPLORE_SET_SEARCH_RECENT:
      return {
        ...state,
        searchRecent : action.searchRecent
      }
    case ExploreActionType.EXPLORE_SET_SEARCH_RESULTS:
      return {
        ...state,
        searchResult:action.searchResult
      }
    case ExploreActionType.EXPLORE_DELETE_SEARCH_RECENT:
      return {
        ...state,
        searchRecent : state.searchRecent.filter(item => item.id != action.id)
      }
    case ExploreActionType.EXPLORE_DELETE_ALL_SEARCH_RECENT:
      return {
        ...state,
        searchRecent : []
      }
    default:
      return state;
  }
}



export default addToScreenReducer;