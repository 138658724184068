import { AuthActionType } from 'constants/authActionType'
import store from 'localstorage-slim'

const initialState:any = store.get('AUTH') || {
  authed: true,
  username: null,
  password: null,
  isViewed: true,
  saveAuthed: true
}


const  getAuth = (state,action)  => {
  switch (action.type) {
    case AuthActionType.AUTH_CHANGE_PASSWORD:
      return {
        ...state,
        password: action.password
      }
    case AuthActionType.AUTH_SET_IS_VIEWED:
      return {
        ...state,
        isViewed: action.isViewed
      }
    case AuthActionType.AUTH_SET_AUTHED:
      return {
        ...state,
        authed: action.authed
      }
    case AuthActionType.AUTH_SET_LOGIN_INFO:
      return {
        ...state,
        password: action.password,
        username: action.username
      }
    case AuthActionType.AUTH_SET_SAVE_AUTHED:
      return {
        ...state,
        saveAuthed: action.saveAuthed
      }      
    default:
      return state;
  }
}

const authReducer = (state = initialState, action) => {
  const auth = getAuth(state,action);
  const {authed,saveAuthed} = auth;
  store.set('AUTH',{...auth,authed:saveAuthed ? authed : false},{ ttl: 60 * 60 * 6 });
  return auth;
}



export default authReducer;