import React,{useMemo} from 'react'
import PostRow,{PostRowItem,PostRowLayout} from './PostRow'
import {VirtualList,ObserverLoad} from 'components'


export interface PostGridProps extends PostRowLayout {
  postGrids?: PostRowItem[]
  onLoad?: () => void;
}

const PostGrid: React.FC<PostGridProps> = ({postGrids=[],onLoad,...props}) => {

const _postGrids = useMemo(() => {
  return postGrids.map((item,index) => <PostRow {...item} key={index} {...props} />)
},[postGrids])

  return (
    <>
      <VirtualList 
        renderer={({ index }) => {
          return _postGrids[index];
        }} 
        initialRenderCount={8} 
        skipChildMeasurement={false} 
        direction="vertical" 
        itemCount={postGrids.length} 
        estimatedItemSize={800} 
        overscanCount={6} 
      />
      <ObserverLoad 
        onLoad={onLoad} 
        height={48} 
        style={{marginTop:40}} 
        visible={postGrids.length != 0} 
      />
    </>
  )
}

export default PostGrid;