import React from 'react'
import {SpriteIcon} from 'components'
import styles from './styles.module.scss'


interface OptionsProps  {
  childre?: React.ReactNode;
  value?: any;
}

const Option:React.FC<OptionsProps & React.OptionHTMLAttributes<HTMLOptionElement>> = ({children,value}) => {
  return (
    <option value={value}>{children}</option>
  )
}


interface SelectProps  {
  onChange?: (e:string) => void;
  children?: React.ReactNode;
  defaultValue?: any;
  className?: string;
}

class Select extends React.Component<SelectProps & React.SelectHTMLAttributes<HTMLSelectElement>> {
  static Option = Option;
  render() {
    const {children,onChange=() => {},defaultValue,className} = this.props;
    return (
      <div className={`${styles['select']} ${className}`}>
        <select className={styles['select-content']} defaultValue={defaultValue} onChange={(e) => { onChange(e.target.value) }}>{children}</select>
        <SpriteIcon className={styles['select-icon']} type="880" size={12} backgroundPosition="-428px -77px" backgroundSize="440px 411px" />
      </div>
    )
  }
}

export default Select;