import React from 'react';
import {Button,Spin} from 'components'
import styles from './viewReply.module.scss'

type ViewReplyProps = {
  loading?: boolean;
  unWatchCommentsNum?: number;
  onExpandClick?: () => void;
}

const ViewReply:React.FC<ViewReplyProps> = ({loading=false,unWatchCommentsNum,onExpandClick}) => {

  return (
    <div className={styles['view-reply']}>
      <Button type="TEXT" className={styles['view-reply-expand']} onClick={() => { onExpandClick() }}>
        <div className={styles['reply-expand-line']} />
        <span className={styles['reply-expand-text']}>{unWatchCommentsNum > 0 ? `查看回复（${unWatchCommentsNum}）` : '隐藏回复'}</span>
      </Button>
      <Spin visible={loading} type="THINSPIN" size={18} style={{marginLeft:6}}/>
    </div>
  )
}


export default ViewReply;