import { StoryActionType } from 'constants/storyActionType'
import {getStory} from 'services/containers/story'

export const changFetchStatus = (isFetching) => {
  return {
    type: StoryActionType.STORY_CHANGE_FETCH_STATUS,
    isFetching
  }
}

export const setStory = (story) => {
  return {
    type: StoryActionType.STORY_SET,
    story
  }  
}

export const changeActiveCarousel = (id,activeCarousel) => { 
  return {
    type: StoryActionType.STORY_CHANGE_ACTIVE_CAROUSEL,
    id,activeCarousel
  }
}

export const changeFollowStatus = (id,viewerFollowStatus) => {
  return {
    type: StoryActionType.STORY_CHANGE_FOLLOW_STATUS,
    id,viewerFollowStatus
  }
}

export const deleteMedia = (id,mediaId) => {
  return {
    type: StoryActionType.STORY_DELETE_MEDIA,
    id,mediaId
  } 
}

export const fetchStory = () => {
  return (dispatch) => {
    return new Promise<void>(async (resolve) => {
      dispatch(changFetchStatus(true));
      const story = await getStory();
      dispatch(setStory(story));
      dispatch(changFetchStatus(false));
      resolve();
    })
  }
}