import React, { useEffect, useState } from 'react'
import Spinner from './Spinner'
import {isIOS} from 'utils'
import EventListenerHelper from 'utils/EventListenerHelper'

import styles from './styles.module.scss'

function t(t) {
  var n, o, l;
  const s = null === (n = t.changedTouches) || void 0 === n ? void 0 : n[0];
  return {
    x: null !== (o = null === s || void 0 === s ? void 0 : s.screenX) && void 0 !== o ? o : 0,
    y: null !== (l = null === s || void 0 === s ? void 0 : s.screenY) && void 0 !== l ? l : 0
  }
}




interface PullToRefreshProps {
  container?: React.RefObject<any>;
  onTrigger?: () => void;
}

const PullToRefresh:React.FC<PullToRefreshProps> = ({ container: n, onTrigger: o,children }) => {
  const [l, s] = useState(false);
  const [u, c] = useState(0);;

  return useEffect(() => {
    const t = n.current
      , o = document.body;
    return !isIOS() && o && (o.style.overflowY = 'hidden'),
      () => {
        if(t) {
          t.style.removeProperty('transform');
          t.style.removeProperty('-webkit-transform');
        }
        !isIOS() && o && o.style.removeProperty('overflow-y')
      }
  }, [n]),
    useEffect(() => {
      const l = n.current;
      if (null == l)
        return () => { }
          ;
      let u = !1
        , v = !1
        , f = 0
        , y = null
        , p = null
        , h = 0;
      const b = () => {
        u = !1;
        v = !1;
        f = 0;
        y = null;
        p = null;
      }
        , M = t => {
          l.style.transform = `translateY(${t}px)`;
          l.style.webkitTransform = `translateY(${t}px)`
        }
        , x = () => {
          l.style.removeProperty('transform');
          l.style.removeProperty('-webkit-transform');
        }
        , T = t => {
          const n = Math.max(Math.floor(t - t / 4), 0);
          M(n);
          if(n > 0) {
            requestAnimationFrame(T.bind(null, n))
          } else {
            c(0);
            s(!1);
            x();
          }
        }
        , E = t => {
          const n = Math.max(Math.floor(t - t / 20), 56);
          M(n);
            n > 56 ? requestAnimationFrame(E.bind(null, n)) : setTimeout(() => {
              requestAnimationFrame(T.bind(null, n))
            }
              , 300)
        }
        , P = () => {
          if(u) {
            l.scrollTop < 0 && (l.scrollTop = 0);
            M(h);
          } else {
            if(null != p && null != y && l.scrollTop <= 0 && p < 0) {
              c(0);
              s(!1);
              f = y;
              u = !0;
            }
          }
          v = !1
        }
        , k = () => {
          v = !0;
          requestAnimationFrame(P)
        }
        , w = EventListenerHelper.add(l, 'touchmove', n => {
          if (u || v) {
            if (u) {
              if (!v) {
                const { y: o } = t(n)
                  , l = f - o
                  , s = Math.max(Math.min((o - f) / 64, 1), 0);
                (h = Math.max(-l, 0)) > 64 && (h = 64 - l / 4);
                c(s);
                k();
              }
              n.preventDefault()
            }
          } else {
            const { y: o } = t(n);
            if(null != y) {
              p = y - o;
              k();
            }
            y = o;
          }
        }
          , {
            passive: !1
          })
        , S = EventListenerHelper.add(l, 'touchend', n => {
          if (!u)
            return;
          const { y: l } = t(n);
          if(l - f > 56) {
            s(!0);
            o();
            E(h);
          } else {
            T(h)
          }
          b();
        }
          , {
            passive: !0
          });
      return () => {
        w.remove();
          S.remove()
      }
    }
      , [n, o]),
      <>
        <div className={styles['spinner']}>
          <Spinner animated={l} progress={u} />
        </div>
        {children}
      </>
}


export default PullToRefresh;