import { DirectActionType } from 'constants/directActionType'

const initialState = {
  inbox: [],  
  isFetchInbox: true,
  chat: {
    messages:null,
    users: []
  }
}

const DirectReducer = (state=initialState,action) => {
  switch(action.type) {
    case DirectActionType.DIRECT_CHANGE_FETCH_INBOX_STATUS:
      return {
        ...state,
        isFetchInbox: action.isFetchInbox
      }
    case DirectActionType.DIRECT_SET_INBOX:
      return {
        ...state,
        inbox: action.inbox
      }
    case DirectActionType.DIRECT_APPEND_INBOX:
      return {
        ...state,
        inbox:[...state.inbox,...action.inbox]
      }
    case DirectActionType.DIRECT_SET_CHAT:
      const length = action.chat.users.length;
      return {
        ...state,
        chat: {
          ...action.chat,
        }
      }
    case DirectActionType.DIRECT_CHANGE_CHAT_NAME:
      return {
        ...state,
        chat: {
          ...state.chat,
          chatName:action.chatName
        }
      }
    case DirectActionType.DIRECT_CHANGE_CHAT_MUTE_STATUS:
      return {
        ...state,
        chat:{
          ...state.chat,
          muted:action.muted
        }
      }
    case DirectActionType.DIRECT_PREPEND_INBOX:
      return {
        ...state,
        inbox : [action.inbox,...state.inbox]
      }
    case DirectActionType.DIRECT_PREPEND_MESSAGES:
      return {
        ...state,
        chat : {
          ...state.chat,
          messages : [...action.messages,...state.chat.messages]
        }
      }
    case DirectActionType.DIRECT_ADD_CHAT_USERS:
      return {
        ...state,
        chat: {
          ...state.chat,
          users : [
            ...state.chat.users,
            ...action.users
          ]
        }
      }
    case DirectActionType.DIRECT_DELETE_CHAT_USER:
      return {
        ...state,
        chat:{
          ...state.chat,
          users: state.chat.users.filter(user => user.id != action.id)
        }
      }
    case DirectActionType.DIRECT_CHANGE_CHAT_ADMIN_STATUS:
      return {
        ...state,
        chat:{
          ...state.chat,
          users:state.chat.users.map(user => {
            if(user.id == action.id) {
              user.isAdmin = action.isAdmin;
            }
            return {...user}
          })
        }
      }
    case DirectActionType.DIRECT_CHANGE_CHAT_USER_BLOCK_STATUS:
      return {
        ...state,
        chat:{
          ...state.chat,
          users:state.chat.users.map(user => {
            if(user.id == action.id) {
              user.blockedByViewer = action.blockedByViewer;
            }
            return {...user}
          })
        }
      }
    case DirectActionType.DIRECT_DELETE_CHAT:
      return {
        ...state,
        chat  : {
          messages:null,
          users: []          
        },
        inbox: state.inbox.filter(({id}) => id!= action.id)
      }
    default:
      return state;
  }
}



export default DirectReducer;