export enum FeedActionType {
  FEED_CHANGE_FETCH_STATUS = 'FEED_CHANGE_FETCH_STATUS',
  FEED_SET = 'FEED_SET',
  FEED_ADD = 'FEED_ADD',
  FEED_APPEND = 'FEED_APPEND',
  FEED_DELETE = 'FEED_DELETE',
  FEED_CHANGE_LIKED = 'FEED_CHANGE_LIKED',
  FEED_CHANGE_SAVE_STATUS = 'FEED_CHANGE_SAVE_STATUS',
  FEED_CHANGE_COMMENT_LIKED = 'FEED_CHANGE_COMMENT_LIKED',
  FEED_CHANGE_FOLLOW_STATUS = 'FEED_CHANGE_FOLLOW_STATUS',
  FEED_ADD_COMMENT = 'FEED_ADD_COMMENT',
  FEED_CHANGE_AVATAR_LOADING_STATUS = 'FEED_CHANGE_AVATAR_LOADING_STATUS',
  FEED_CHANGE_HAS_VIDEWED = 'FEED_CHANGE_HAS_VIDEWED'
}
