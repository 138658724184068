import React, { useEffect } from 'react'
import {Spin} from 'components'
import styles from './styles.module.scss'
import { useInView } from 'react-intersection-observer';

interface ObserverLoadProps  {
  height?: number;
  onLoad?: () => void;
  threshold?: number;
  style?: React.CSSProperties;
  size?: number;
  visible?: boolean;
  delay?:number;
  skip?: boolean;
}

const ObserverLoad: React.FC<ObserverLoadProps> = ({skip = false,delay=100,visible,height,style,size=32,threshold = 1, onLoad = () => { } }) => {
  const { ref, inView } = useInView({
    threshold,
    delay,
    skip
  })

  useEffect(() => {
    console.log(inView,'inView');
    inView && onLoad();
  }, [inView])

  return (
    <>
      {visible && <div ref={ref} className={styles['observer-load']} style={{height,...style}}>
        <Spin size={size} />
      </div>}
    </>
  )
}

export default ObserverLoad;
