import React, { useState, useEffect,useCallback } from 'react'
import {Spin} from 'components'
import { useInView } from 'react-intersection-observer';
import styles from './styles.module.scss';

export interface ImageProps {
  src?: string;
  alt?: string;
  width?: number | string;
  height?: number | string;
  style?: React.CSSProperties;
  className?: string;
  hasSpin?: boolean;
}



const Image = ({ src, width, height, style,className,hasSpin=false,...props}:ImageProps & React.HTMLAttributes<HTMLImageElement>,ref) => {
  const [url, setUrl] = useState<string>('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7');
  const [loaded,setLoaded] = useState<boolean>(false)
  const [inViewRef, inView ] = useInView({
    threshold: 0,
  })
  
  useEffect(() => {
    inView && setUrl(src);
  },[inView,src])

  const setRefs = useCallback((node) => {
    inViewRef(node);
    if(ref) {
      ref.current = node
    }
  },[inViewRef])

  return (
    <>
      <img src={url} ref={setRefs} onLoad={() => { inView && setLoaded(true) }} className={`${styles['img']} ${className}`}  style={{ width, height, ...style}} {...props} />
      <Spin size={32} visible={ !loaded && hasSpin} className={styles['img-spin']} fill="" />
    </>
  )
}

export default React.forwardRef(Image);
