import { TagFollowingActionType } from 'constants/tagFollowingActionType'


const initialState = {
  tagFollowing: [],
  isFetching: true
}

const followerReducer = (state=initialState,action) => {
  switch(action.type) {
    case TagFollowingActionType.TAG_FOLLOWING_SET:
      return {
        ...state,
        tagFollowing: action.tagFollowing
      }
    case TagFollowingActionType.TAG_FOLLOWING_CHANGE_FETCH_STATUS :
      return {
        ...state,
        isFetching: action.isFetching
      }
    case TagFollowingActionType.TAG_FOLLOWING_APPEND : 
      return {
        ...state,
        tagFollowing : [...state.tagFollowing,...action.tagFollowing]
      }
    case TagFollowingActionType.TAG_FOLLOWING_CHANGE_FOLLOW_STATUS :
      return {
        ...state,
        tagFollowing : state.tagFollowing.map(item => {
          if(item.tag.id == action.id) {
            item.viewerFollowStatus = action.viewerFollowStatus;
          }
          return item;
        })
      }
    default:
      return state;
  }
}



export default followerReducer;