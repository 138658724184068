import React from 'react'
import styles from './policyEducation.module.scss'


export interface PolicyEducationProps {
  title?: React.ReactNode;
  firstGaragraph?: string;
  secondGaragraph?: string;
  policies?: string[];
}

const PolicyEducation:React.FC<PolicyEducationProps> = ({title,firstGaragraph,secondGaragraph,policies=[]}) => {
  
  return (
    <div className={styles['policy-education']}>
      <h2 className={styles['policy-education-title']}>{title}</h2>
      <div className={styles['policy-education-content']}>
        {secondGaragraph && <h5 className={styles['content-second-subtitle']}>{secondGaragraph}</h5>}
        <h4 className={styles['content-first-subtitle']}>{firstGaragraph}</h4>
        {policies.map(policy => {
          return (
            <div className={styles['education-content-item']}>
              <div className={styles['content-item-dot']}>•</div>
              <div className={styles['content-item-text']}>{policy}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default PolicyEducation;