import React from 'react'
import {StickyModal,StickyModalProps,SVG,SVGType,Button } from 'components'
import styles from './styles.module.scss'


interface RestrictConfirmProps extends StickyModalProps {
  username?: string;
  onRestrictConfirmClick?: () => void;
}

interface Restrict {
  icon?: SVGType
  text?: string;
}


const RestrictConfirm: React.FC<RestrictConfirmProps> = ({username,onRestrictConfirmClick=() =>{},onCancel=() => {},...props}) => {

  const descriptions:Restrict[] = [
    {
      icon : 'SHIELD',
      text: '限制你不想要的互动，而无需拉黑或停止关注你认识的用户。'
    },
    {
      icon: 'COMMENT',
      text : '你可以控制是否对其他用户显示他们在你帖子上发布的新评论。'
    },
    {
      icon: 'DIRECT',
      text : '对方的聊天消息将移到你的陌生消息，因此对方看不到你何时阅读了消息。'
    },
  ]

  return (
    <StickyModal contentHeightType="AUTO" onCancel={onCancel} {...props}>
      <div  className={styles['restrict-confirm']}>
        <h2 className={styles['restrict-confirm-title']}>{username} 给你带来了什么问题？</h2>
        {descriptions.map(({icon,text}) => {
          return (
            <div className={styles['restrict-confirm-description']}>
              <SVG type={icon} tag="span" size={24} className={styles['confirm-description-icon']} />
              <div className={styles['confirm-description-text']}>{text}</div>
            </div>
          )
        })}
        <Button type="TEXT" onClick={() =>{onRestrictConfirmClick();onCancel()}} className={styles['restrict-confirm-btn']}>限制账户</Button>
      </div>
    </StickyModal>
  )
}


export default RestrictConfirm;