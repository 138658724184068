import React,{useMemo} from 'react'
import {Image,SVG} from 'components'
import {Username,UserCell,Media,User,SeeMore} from 'containers/Commons'
import {Link} from 'react-router-dom'
import {getExtrinsicHeight} from 'utils'
import styles from './feed.module.scss'

export interface FeedProps {
  type: 'VIDEO' | 'CLIP' | 'CAROUSEL';
  media?: Media;
  owner?: User;
  id?: string;
}

const Feed:React.FC<FeedProps> = ({type,media={},owner,id}) => {
  const {displayUrl,caption} = media;

  const height = useMemo(() => {
    return getExtrinsicHeight(displayUrl);
  },[displayUrl])

  return (
    <div className={styles['feed']}>
      <UserCell usernameStyle={{color:'#00376b'}} gap={8} size={32} user={owner}  className={styles['feed-user']} />
      <Link className={styles['feed-poster']} to={`/postDetail/${id}`}>
        <Image src={displayUrl} style={{height}} />
        <div className={styles['feed-poster-icon']}>
          <SVG type={type} size={22} viewBox={type != 'CAROUSEL' ? 24 : 48} fill="#fff" tag="span" style={{ margin: 8 }} />
        </div>
      </Link>
      <div className={styles['feed-comment']}>
        <Username username="Devon" style={{display:'inline',marginRight:2}}/>
        <span className={styles['feed-comment-text']}><SeeMore limit={25} text={caption} more="[...]" /></span>
      </div>
    </div>
  )
}

export default Feed;