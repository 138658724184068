import React from 'react'
import { useHistory } from 'react-router-dom';


 const withRequireProp = <P extends object>(WrappedComponent: React.ComponentType<P>,requirePropName:string,redirect?:string):React.ComponentType<P> => (props) => {
  const history = useHistory();

  if(!props[requirePropName]) {
    history.replace({
      pathname : redirect || '/'
    })
    return null;
  }

  return (<WrappedComponent {...props} />)
}

export default withRequireProp;