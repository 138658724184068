import React,{useState} from 'react'
import {AutosizeTextarea,AutosizeTextareaProps,SVGProps,SVG,Button} from 'components'
import styles from './composer.module.scss'

export interface ComposerProps extends AutosizeTextareaProps{
  toolbar?: SVGProps[],
  onToolbarClick?: (e:SVGProps) => void;
  onSend?: (v:string) => void;
}

const Composer:React.FC<ComposerProps> = ({toolbar=[],onToolbarClick=() => {},onSend = () => {}}) => {
  const [value,setValue] = useState<string>('');

  const onChange = (e) => {
    setValue(e.target.value);
  }
  return (
    <div className={styles['composer']}>
      <AutosizeTextarea onChange={onChange} value={value} placeholder="发消息..." className={styles['composer-content']}>
        {value.length !=0 ? 
          <Button onClick={() => { onSend(value);setValue('') }} type="TEXT" className={styles['composer-btn']}>发送</Button> :
          <>{toolbar.map(tool => <SVG onClick={() => { onToolbarClick(tool) }} {...tool} className={styles['composer-tool']}/>)}</>}
      </AutosizeTextarea>
    </div>
  )
}

export default Composer;