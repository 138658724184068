import React from 'react'
import { NavBar } from 'components'
import { ShortenNumber, UserCell,User } from '../index'

import styles from './styles.module.scss'


interface TvProps {
  onBackClick?: () => void;
  videoUrl?: string;
  user?: User;
  videoViewCount?: number;
}

const TV: React.FC<TvProps> = ({ videoUrl, onBackClick,user,videoViewCount }) => {

  return (
    <div className={styles['TV']}>
      <NavBar borderType="NONE" style={{ backgroundColor: '#000' }}>
        <NavBar.Navigation fill="#fff" type="BACK" onClick={onBackClick} />
        <NavBar.Title className={styles['TV-title']}>视频</NavBar.Title>
      </NavBar>
      <div className={styles['TV-video']}>
        <div className={styles['TV-video-content']}>
          <video autoPlay playsInline={true} controls={true} controlsList="nodownload" src={videoUrl} />
        </div>
      </div>
      <div className={styles['TV-sheet']}>
        <UserCell style={{ marginBottom: 8 }} user={user} size={32} gap={8} usernameStyle={{ color: '#fff' }} />
        <div className={styles['TV-sheet-preview']}><ShortenNumber value={videoViewCount} /> 次播放</div>
      </div>
    </div>
  )
}

export default TV;