import { PostDetailActionType } from 'constants/postDetailActionType'

const initialState = {
  post:{
    mediaPreviewComment:{
      comments:[]
    },
    viewerHasLiked:false,
    vierwerHasSaved:false,
    carousel:[],
    owner : {}
  }
}

const postDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case PostDetailActionType.POST_DETAIL_CHANGE_AVATAR_LOADING_STATUS: 
      return {
        post : {
          ...state.post,
          owner:{
            ...state.post.owner,
            isAvatarLoading: action.isAvatarLoading
          }
        }
      }
    case PostDetailActionType.POST_DETAIL_CHANGE_VIEWER_SEEN_STORY:
      return {
        post : {
          ...state.post,
          viewerSeenStory : action.viewerSeenStory
        }
      }
    case PostDetailActionType.POST_DETAIL_SET:
      return {
        post: action.post
      }
    case PostDetailActionType.POST_DETAIL_CHANGE_FOLLOW_STATUS:
      return {
        post : {
          ...state.post,
          viewerFollowStatus: action.viewerFollowStatus
        }
      }
    case PostDetailActionType.POST_DETAIL_CHANGE_COMMENT_LIKED: 
      return {
        post:{
          ...state.post,
          mediaPreviewComment : {
            ...state.post.mediaPreviewComment,
            comments : state.post.mediaPreviewComment.comments.map(item => {
              if(item.id == action.commentId) {
                item.viewerHasLiked = action.liked;
              }
              return item;
            })
          }
        }
      }
    case PostDetailActionType.POST_DETAIL_ADD_COMMENT: 
      state.post.mediaPreviewComment.comments.push(action.comment);
      state.post.mediaPreviewComment = {...state.post.mediaPreviewComment};
      return {
        post:{...state.post}
      }      
    case PostDetailActionType.POST_DETAIL_CHANGE_LIKED:
      state.post.viewerHasLiked = action.liked;
      return {
        post:{...state.post}
      }
    case PostDetailActionType.POST_DETAIL_CHANGE_SAVE_STATUS:
      state.post.vierwerHasSaved = action.saved;
      return {
        post:{...state.post}
      }
    default:
      return state;
  }
}


export default postDetailReducer;